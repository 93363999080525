import React from 'react'
import PropTypes from 'prop-types'

import "components/article-header/article-header"

const CLASS_NS = "article-header"

const ArticleHeader = ({
  classNs = CLASS_NS,
  title = "",
  supertitle = "",
  subtitle = "",
  message = "",
  back,
  children
}) => {

  // NB! The supertitle node is always present for keeping a certain margin height.
  return (
    <header className={`${CLASS_NS} ${classNs}`}>
      {back ? <div className={`${CLASS_NS}__content__back ${classNs}__content__back`}>{back}</div> : null}
      <div className={`${classNs}__content ${CLASS_NS}__content`}>
        <h6 className={`${classNs}__content__supertitle`}>{supertitle}</h6>
        {title ? <h1 className={`${classNs}__content__title`}>{title}</h1> : null}
        {subtitle ? <h3 className={`${classNs}__content__subtitle`}>{subtitle}</h3> : null}
        {message ? <h3 className={`${classNs}__content__message`}>{message}</h3> : null}
        {children}
      </div>
    </header>
  )
}

ArticleHeader.propTypes = {
  classNs: PropTypes.string,
  supertitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  children: PropTypes.object,
}
export default ArticleHeader
