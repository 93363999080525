import React from 'react'
import PropTypes from 'prop-types'
import BlockContent from '@sanity/block-content-to-react'
import Article from 'components/article/article';
import ArticleHeader from "components/article-header/article-header"
import NotFound from 'components/notfound/notfound'
import env from 'environments'

import "components/sanity-page/_sanity-page.scss"

const CLASS_NS = "sanity-page"

const client = {
  projectId: env.SANITY_PROJECT_ID,
  dataset: env.SANITY_DATASET,
}
export const SanityBlocContent = ({ blocks }) => {
  return <BlockContent blocks={blocks} {...client} />
}

const SanityPage = ({
  children,
  aside,
  page,
  classNs = CLASS_NS
}) => {

  if (!page) return <NotFound />
  return (
    <>
      <div className={`${CLASS_NS} ${classNs}`}>
        <article className={`${classNs}__wrapper`}>

          <ArticleHeader
            classNs={`${CLASS_NS}__header`}
            title={page.title}
            subtitle={page.subtitle}
          ></ArticleHeader>
          <section className="page-article">
            <Article
              classNs={`${classNs}`}
              title={page.title}
              imageSrc={page.image}
              imageBgSrc={page.image}>
              {page.preamble && <SanityBlocContent blocks={page.preamble} />}
              {page.content && <SanityBlocContent blocks={page.content} />}
            </Article>
            {children}
          </section>
        </article>
        {aside}
      </div>
    </>
  )
}

SanityPage.propTypes = {
  page: PropTypes.object,
  classNs: PropTypes.string
}

export default SanityPage
