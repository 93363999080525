import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'

const GET_FORECAST_WEIGHT_RATINGMAP_LOADING = 'GET_FORECAST_WEIGHT_RATINGMAP_LOADING'
const GET_FORECAST_WEIGHT_RATINGMAP_SUCCESS = 'GET_FORECAST_WEIGHT_RATINGMAP_SUCCESS'
const GET_FORECAST_WEIGHT_RATINGMAP_ERROR = 'GET_FORECAST_WEIGHT_RATINGMAP_ERROR'

const DELETE_FORECAST_WEIGHT_RATINGMAP_LOADING = 'DELETE_FORECAST_WEIGHT_RATINGMAP_LOADING'
const DELETE_FORECAST_WEIGHT_RATINGMAP_SUCCESS = 'DELETE_FORECAST_WEIGHT_RATINGMAP_SUCCESS'
const DELETE_FORECAST_WEIGHT_RATINGMAP_ERROR = 'DELETE_FORECAST_WEIGHT_RATINGMAP_ERROR'

const PUT_FORECAST_WEIGHT_RATINGMAP_LOADING = 'PUT_FORECAST_WEIGHT_RATINGMAP_LOADING'
const PUT_FORECAST_WEIGHT_RATINGMAP_SUCCESS = 'PUT_FORECAST_WEIGHT_RATINGMAP_SUCCESS'
const PUT_FORECAST_WEIGHT_RATINGMAP_ERROR = 'PUT_FORECAST_WEIGHT_RATINGMAP_ERROR'


const POST_FORECAST_WEIGHT_RATINGMAP_LOADING = 'POST_FORECAST_WEIGHT_RATINGMAP_LOADING'
const POST_FORECAST_WEIGHT_RATINGMAP_SUCCESS = 'POST_FORECAST_WEIGHT_RATINGMAP_SUCCESS'
const POST_FORECAST_WEIGHT_RATINGMAP_ERROR = 'POST_FORECAST_WEIGHT_RATINGMAP_ERROR'


/* ACTIONS */
const getForecastWeightRatingMap = ({forecastId}) => (dispatch) => {
  dispatch({
    type: GET_FORECAST_WEIGHT_RATINGMAP_LOADING
  });

  httpClient(`forecasts/${forecastId}/weight/ratingmap`)
    .then(result => {
      dispatch({
        type: GET_FORECAST_WEIGHT_RATINGMAP_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: GET_FORECAST_WEIGHT_RATINGMAP_ERROR,
        error,
        payload: error
      });
    })
}

export const deleteForecastWeightRatingMap = ({forecastId, id}) => (dispatch) => {
  dispatch({
    type: DELETE_FORECAST_WEIGHT_RATINGMAP_LOADING
  });

  httpClient(`forecasts/${forecastId}/weight/ratingmap/${id}`, { method: 'DELETE'})
    .then(result => {
      dispatch({
        type: DELETE_FORECAST_WEIGHT_RATINGMAP_SUCCESS,
        payload: result
      });
      dispatch(getForecastWeightRatingMap({forecastId}))
    })
    .catch(error => {
      dispatch({
        type: DELETE_FORECAST_WEIGHT_RATINGMAP_ERROR,
        error,
        payload: error
      });
    })
}


export const putForecastWeightRatingMap = ({forecastId, data}) => (dispatch) => {
  dispatch({
    type: PUT_FORECAST_WEIGHT_RATINGMAP_LOADING
  });

  httpClient(`forecasts/${forecastId}/weight/ratingmap`, { data, method: 'PUT'})
    .then(result => {
      dispatch({
        type: PUT_FORECAST_WEIGHT_RATINGMAP_SUCCESS,
        payload: result
      });
      dispatch(getForecastWeightRatingMap({forecastId}))
    })
    .catch(error => {
      dispatch({
        type: PUT_FORECAST_WEIGHT_RATINGMAP_ERROR,
        error,
        payload: error
      });
    })
}


export const postForecastWeightRatingMap = ({forecastId, id, data}) => (dispatch) => {
  dispatch({
    type: POST_FORECAST_WEIGHT_RATINGMAP_LOADING
  });

  httpClient(`forecasts/${forecastId}/weight/ratingmap/${id}`, { data, method: 'POST'})
    .then(result => {
      dispatch({
        type: POST_FORECAST_WEIGHT_RATINGMAP_SUCCESS,
        payload: result
      });
      dispatch(getForecastWeightRatingMap({forecastId}))
    })
    .catch(error => {
      dispatch({
        type: POST_FORECAST_WEIGHT_RATINGMAP_ERROR,
        error,
        payload: error
      });
    })
}

/* HOOKS */

export const useForecastWeightRatingMap = (forecastId) => {

  const dispatch = useDispatch();
  const { rows, networkState } = useSelector(state => state.forecast_weight_ratingmap);

  useEffect(() => {
    dispatch(getForecastWeightRatingMap({forecastId}));
  }, [forecastId, dispatch]);
  return [rows, networkState];
};
/* REDUCER */
const initialState = {
  rows: [],
  networkState: WAITING,
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {


    case GET_FORECAST_WEIGHT_RATINGMAP_LOADING:
      return {
        ...state,
        networkState: LOADING
      }
    case GET_FORECAST_WEIGHT_RATINGMAP_ERROR:
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    case GET_FORECAST_WEIGHT_RATINGMAP_SUCCESS:
      return {
        ...state,
        rows: action.payload,
        networkState: SUCCESS,
      }

    default:
      return state
  }
}
