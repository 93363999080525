import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Progress from 'components/progress/progress'
import Page from "components/page/page";
import {ForecastProjectContext} from './project'

const ForecastProjectProgressSection = (props) => {
  const {constant, project} = useContext(ForecastProjectContext)
  const { progresses } = constant
  
  const progressStates = progresses.map(p => ({label: p.name, active: p.id <= project.progressId}));
  return (
    <Page.Grid {...props}>
      <Page.Block span={4} >
        <Progress states={progressStates}/>
      </Page.Block>
    </Page.Grid>
  )
}

ForecastProjectProgressSection.propTypes = {
  project: PropTypes.object,
  progresses: PropTypes.object
}
export default ForecastProjectProgressSection
