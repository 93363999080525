import React from 'react'
import { ReactComponent as IconArrowRight } from "assets/icons/arrow-right.svg"
import { ReactComponent as IconCheck } from "assets/icons/check.svg"
import { ReactComponent as IconCross } from "assets/icons/cross-small.svg"
import { ReactComponent as IconEditPen } from "assets/icons/edit.svg"
import { ReactComponent as IconInfo } from "assets/icons/info.svg"
import { ReactComponent as IconError } from "assets/icons/error.svg"
import { ReactComponent as IconWifiOff } from "assets/icons/wifi_off.svg"
import classNames from 'classnames'

import './_icon.scss'

export const ArrowRightIcon = () => <IconArrowRight className="icon__arrow-right" />

export const CheckIcon = ({value}) => <IconCheck className="icon__check" title={value}/>

export const CrossIcon = ({value}) => <IconCross className="icon__cross"  title={value}/>

export const EditPenIcon = () => <IconEditPen className="icon__cross" />

export const InfoIcon = () => <IconInfo className="icon__info" />
export const ErrorIcon = () => <IconError className="icon__error" />
export const WifiOffIcon = () => <IconWifiOff className="icon__wifi" />

export const RatedIcon = ({ratingId, isFaktanet = true, ratedAt, method = 'AUTO'}) => {
    const cls = classNames('rated_icon', {
        [`rated_icon--rated`]: !!ratedAt,
        [`rated_icon--notrated`]: !ratedAt,
        [`rated_icon--${method}`]: true,
        [`rated_icon--isManual`]: !isFaktanet
      })
    return (<div className={cls} >
        {ratingId}
    </div>)
}