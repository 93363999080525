import React from 'react';
import PropTypes from 'prop-types'
import useWindowSize from 'utils/use-window-size'
import { useSanity } from 'store/sanity';
import ArticleHeader from "components/article-header/article-header"
import DefinitionListing from "components/definition-listing/definition-listing"

import "components/county-listing/_county-listing.scss"
const CLASS_NS = "county-listing";

const CountyListing = ({
  classNs=CLASS_NS,
}) => {
  const [{settings, county}] = useSanity()

  const windowWidth = useWindowSize();
  
  return (
    <section className={`${classNs}__wrapper`}>
      <ArticleHeader
        classNs={`${classNs}__wrapper__header`}
        title={<React.Fragment>{settings.titlePrefix} <span className={`${classNs}__wrapper__header-highlight`}>{settings.title}</span></React.Fragment>}
      ></ArticleHeader>
      <div className={`${classNs}__container`}>
        <DefinitionListing
          classNs={classNs}
          items={county}
          windowWidth={windowWidth}
        />
      </div>
    </section>
  )
}

CountyListing.propTypes = {
  classNs: PropTypes.string
}
export default CountyListing
