import * as types from "store/_types";

/* ACTIONS */

export const updateProjectFilter = (data) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PROJECT_FILTER,
    payload: data
  });
}
export const clearProjectFilter = (data) => (dispatch) => {
  dispatch({
    type: types.CLEAR_PROJECT_FILTER,
    payload: data
  });
}

/* REDUCER */
const initialState = {
  error: null,
  rev: 0,
  isOpen: false,
  name: '',
  source: '',
  valueMinDisplay: '',
  valueMaxDisplay: '',
  timeMin: '',
  timeMax: '',
  startDateMin: null,
  startDateMax: null,
  startDateMinIso: '',
  startDateMaxIso: '',
  officeId: '',
  categoryId: '',
  ratingId: '',
}

export default function (state = initialState, action) {
  
  switch (action.type) {
    case types.UPDATE_PROJECT_FILTER:
      return {
        ...state,
        ...action.payload
      }
    
    case types.CLEAR_PROJECT_FILTER:
      return {
        ...initialState,
        ...action.payload
      }
    
    default:
      return state
  }
}
