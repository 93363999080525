import React, { useEffect } from 'react'
import userManager from "utils/userManager";

import "routes/admin/_admin.scss"

const Admin = () => {

  useEffect(() => {
    sessionStorage.setItem('isAdmin', 'true')
    userManager.removeUser();
  }, [])

  const handleLogin = () => {
    userManager.signinRedirect();
  }

  return (
    <article className="admin-page">
      <h4 className="admin-page__subheader">Prognoswebben</h4>
      <h2 className="admin-page__header">Bedömningsverktyget</h2>
      <p className="admin-page__intro">Du har nu loggats ut från Bedömningsverktyget.</p>
      <button className="button" onClick={handleLogin}>Logga in</button>
    </article>
  )
}

export default Admin
