import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { uiCloseSnackbar, uiShowSnackbar } from 'store/ui_snackbar'

export default () => {
  const dispatch = useDispatch()

  const offline = () => {
    dispatch(uiShowSnackbar({
      icon: 'wifi-off',
      message: 'Du verkar tappat din internet uppkoppling.'
    }));
  }

  const online = () => {
    dispatch(uiCloseSnackbar('wifi-off'));
  }

  useEffect(() => {
    window.addEventListener('offline', offline);
    window.addEventListener('online', online);
  }, [])
  return <></>
}