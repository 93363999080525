import React, { useState, useEffect } from 'react'
import PropTable from 'components/prop-table/prop-table';
import { krToMkr } from 'utils/data-helpers';
import { useSanity } from 'store/sanity';

import "routes/report/_region-data.scss"

const Section = ({ variant = 'gray', title, children, gridClass = 'page-grid7' }) => (
  <section className={`region-data__section region-data__section--${variant}`}>
    {title &&
      <header className="region-data__section__header">
        <h2>Regiondata</h2>
      </header>
    }
    <div className="region-data__section__wrapper">
      <div className={`region-data__section__content ${gridClass}`}>
        {children}
      </div>
    </div>
  </section>
)

const RegionData = ({
  locationId,
  categoryId,
  regionData,
}) => {

  const [projectData, setProjectData] = useState([]);
  const [investmentData, setInvestmentData] = useState([]);
  const [topBuilders, setTopBuilders] = useState([])
  const [toggledProjects, setToggledProjects] = useState(false);
  const [toggledBuilders, setToggledBuilders] = useState(false);
  const [toggledInvestments, setToggledInvestments] = useState(false);
  const [{ settings }] = useSanity()

  useEffect(() => {
    if (regionData) {
      setProjectData(regionData.topProjects.data.filter(d => d.categoryId === categoryId))
      setTopBuilders(regionData.topBuilders.data.filter(d => d.categoryId === categoryId))
      setInvestmentData(regionData.investmentTotal.data.filter(d => d.categoryId === categoryId))
    }
  }, [regionData, locationId, categoryId])

  const getProjects = () => {
    const limit = toggledProjects ? 20 : 3
    return projectData.slice(0, limit)
  }

  const getBuilders = () => {
    const limit = toggledBuilders ? 20 : 3
    return topBuilders.slice(0, limit)
  }

  const getInvestments = () => {
    const limit = toggledInvestments ? 20 : 3
    return investmentData.slice(0, limit)
  }

  const toggleProjects = (e) => {
    e.preventDefault();
    setToggledProjects(!toggledProjects)
  }

  const toggleBuilders = (e) => {
    e.preventDefault();
    setToggledBuilders(!toggledBuilders)
  }

  const toggleInvestments = (e) => {
    e.preventDefault();
    setToggledInvestments(!toggledInvestments)
  }

  if (!regionData) {
    return null
  }

  const permitPerYear = regionData.permitPerYear && regionData.permitPerYear.data && regionData.permitPerYear.data.filter(d => d.value) || false
  const housingPerYear = regionData.housingPerYear && regionData.housingPerYear.data && regionData.housingPerYear.data.filter(d => d.value) || false

  const showApartmentFacts = (categoryId === 'alla' || categoryId === 'bostader') &&
    (housingPerYear || permitPerYear);

  const hasDescription = (obj) => {
    return obj.description ? true : false;
  }

  return (
    <>
      <div>
        {
          showApartmentFacts &&
          <Section variant="gray" title gridClass="page-grid">
            {housingPerYear && housingPerYear.length > 1 && <PropTable className="region-data__table page-grid_2" label={settings.region_data_top_housing_per_year}>
              {housingPerYear.map((d, i) => (
                <PropTable.Row key={i}>
                  <div>
                    <p className="text">{`${d.year}: ${d.value}`}</p>
                  </div>
                </PropTable.Row>
              ))}
            </PropTable>
            }
            {permitPerYear && permitPerYear.length > 1 && <PropTable className="region-data__table page-grid_2" label={settings.region_data_top_permit_per_year}>
              {permitPerYear.map((d, i) => (
                <PropTable.Row key={i}>
                  <div>
                    <p className="text">{`${d.year}: ${d.value}`}</p>
                  </div>
                </PropTable.Row>
              ))}
            </PropTable>
            }
          </Section>
        }
        {projectData.length > 0 &&
          <Section variant="gray" title={categoryId === 'lokaler' || categoryId === 'anlaggningar' ? true : false}>
            <PropTable className="region-data__description page-grid_3" label={settings.region_data_top_projects_info} />
            <PropTable className="region-data__table page-grid_4">
              {getProjects().map((project, i) => (
                <PropTable.Row key={i} hasDescription={hasDescription(project)}>
                  <div>
                    <h5>{`${i + 1}. ${project.name}`}</h5>
                    <p className="text">{project.description}</p>
                  </div>
                  {project.value &&
                    <p className="value">{`${krToMkr(project.value)} mkr`}</p>
                  }
                </PropTable.Row>
              ))}
              <button className="region-data__link" onClick={toggleProjects}>{!toggledProjects ? "Visa fler" : "Visa färre"}</button>
            </PropTable>
          </Section>
        }
        {topBuilders.length > 0 &&
          <Section variant="gray">
            <PropTable className="region-data__description page-grid_3" label={settings.region_data_top_builder_info} />
            <PropTable className="region-data__table page-grid_4">
              {getBuilders().map((builder, i) => (
                <PropTable.Row key={i} hasDescription={hasDescription(builder)}>
                  <div>
                    <h5>{builder.name}</h5>
                    {builder.description ? <p className="text">{builder.description}</p> : null}
                  </div>
                  {builder.value && <p className="value">{builder.value}%</p>}
                </PropTable.Row>
              ))}
              <button className="region-data__link" onClick={toggleBuilders}>{!toggledBuilders ? "Visa fler" : "Visa färre"}</button>
            </PropTable>
          </Section>
        }
        {investmentData.length > 0 &&
          <Section variant="gray">
            <PropTable className="region-data__description page-grid_3" label={settings.region_data_top_investment} />
            <PropTable className="region-data__table page-grid_4">
              {getInvestments().map((district, i) => (
                <PropTable.Row key={i} hasDescription={hasDescription(district)}>
                  <div>
                    <h5>{district.name}</h5>
                    <p className="text">{district.description}</p>
                  </div>
                  {district.value && <p className="value">{district.value}</p>}
                </PropTable.Row>
              ))}
              <button className="region-data__link" onClick={toggleInvestments}>{!toggledInvestments ? "Visa fler" : "Visa färre"}</button>
            </PropTable>
          </Section>
        }
      </div>
    </>
  )
}

export default RegionData
