import TabBar from "components/tab-bar/tab-bar";
import React, { Children, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";


export default ({choices}) => {

  const history = useHistory()
  const { pathname } = useLocation()
  const [selected, setSelected] = useState();

  useEffect( ()=>{
    if ( choices.find(item=>item.value === pathname)) {
      setSelected(pathname)
    }
  }, [pathname])

  const onTabBarChange = e=>{
    const {value} = e.target;
    history.push(value);
  }

  return (
    <TabBar
      choices={choices}
      onChange={onTabBarChange}
      selected={selected}
    />
  )
}
