import React, { useState, useContext } from 'react'
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types'

import Page from "components/page/page";

import { addProjectNote } from "store/project_notes"
import { ForecastProjectContext } from './project'

import { formatIsoDate } from 'utils/date-helpers';

const ProjectNotesSection = (props) => {
  const dispatch = useDispatch();
  const { project, notes } = useContext(ForecastProjectContext);

  const [text, setText] = useState();
  const onSave = () => {
    dispatch(addProjectNote(
      {
        text,
        projectId: project.projectId
      }
    ))
    setText('')
  }

  return <>
    <Page.Grid {...props} variant="gray" title="" >

      <Page.Block span={4}>
        <h3>Minnesanteckningar</h3>
        <label htmlFor="name">Anteckningar som hjälper dig att återuppta projektet senare.</label>
      </Page.Block>

      <Page.Block span={4}>
        <textarea wrap="soft" rows={5} name="text" onChange={e => setText(e.target.value)} className={``} placeholder="" type="text" value={text} />
      </Page.Block>
      <Page.Block start={1} span={2}>
        {text &&
          <button onClick={onSave} className="button">Spara anteckning</button>
        }
      </Page.Block>

      <Page.Block span={4}>
        {notes.map(n => {
          return (
            <p key={n.id}>{formatIsoDate(n.createdAt)} - {n.text}</p>
          )
        })}
      </Page.Block>

    </Page.Grid>
  </>

}


ProjectNotesSection.propTypes = {
  project: PropTypes.object,
  progresses: PropTypes.object
}
export default ProjectNotesSection
