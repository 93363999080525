import { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Modal from "components/modal/modal"
import ModalContentContainer from "components/modal/modal-content-container"
import InputNumber from "components/input-number/input-number";
import InputText from "components/input-text/input-text";
import Button from "components/button/button";

import ForecastTabBar from "routes/forecast/tab-bar"
import ProjectsFilter from "./projects-filter"
import DataTable from "components/data-table/data-table";
import { useForecast } from "store/forecast"
import { useForecastProjectList } from "store/forecast_project_list"
import { updateProjectFilter } from "store/project_filter"
import { useUserProfile } from 'store/profile'
import { /*WAITING,*/ LOADING, /*ERROR,*/ /*SUCCESS*/ } from "store/_types";
import { ArrowRightIcon, CheckIcon, RatedIcon } from "components/icon"

import "./projects.scss";
import { formatMonthDate } from 'utils/date-helpers';
import Page from 'components/page/page';

import CurrencyValue from 'components/currency_value/currency_value';
import { useConstant } from 'store/constants';

import { useForecastFaktanetCategories } from 'store/forecast_faktanet_categories';
import { useForecastFaktanetDistricts } from 'store/forecast_faktanet_districts';
import { useForecastOffices } from 'store/forecast_offices';
import { addProject } from 'store/forecast_project';
import SelectInput from 'components/select-input/select-input';
import FormMonthPicker from 'components/month-picker/month-picker';

const ForecastProjects = ({ forecastId }) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const history = useHistory()
  const [offices] = useForecastOffices(forecastId);

  const [user] = useUserProfile()
   const [forecast] = useForecast(forecastId);
  const { rows, total, offset, limit, queryString, networkState } = useForecastProjectList(forecastId);
  // return null;
  const filter = useSelector(state => state.project_filter)
  const [addModalOpen, setAddModalOpen] = useState(false)

  const forecastProjectsList = rows.map(p => ({ id: p.projectId, ...p })) || []

  const onOrderClick = (name) => {

    let dir = name.indexOf('name') >= 0 ? 'asc' : 'desc'
    if (filter.sortBy === name) {
      dir = filter.sortDir === 'asc' ? 'desc' : 'asc'
    }
    dispatch(updateProjectFilter({ 'sortBy': name, 'sortDir': dir, rev: filter.rev + 1 }))
  }

  let data = forecastProjectsList
  let emptyMessage = "Laddar projekt"
  if (!user.isAdmin && filter.rev === 0) {
    data = []
    emptyMessage = "Projekten visas här när du valt din region."
  }
  var maxNumberOfProjects = false
  if (filter.officeId && offices) {
    const office = offices.filter(o => o.id === filter.officeId).pop()
    maxNumberOfProjects = office?.maxNumberOfProjects || false
}
  return (
    <>
    <Page className="projects__page">
      {user.isAdmin &&
        <Page.Header title={forecast.name}>
          <ForecastTabBar forecastId={forecastId} />
        </Page.Header>
      }
      <Page.Grid className="projects__page__search" >
        <Page.Block start={4} span={1}>
          <Button onClick={() => setAddModalOpen(true)}>Lägg till projekt</Button>
        </Page.Block>
        <Page.Block span={4}>
          <ProjectsFilter total={total} offset={offset} limit={limit} queryString={queryString} loading={networkState === LOADING} />
        </Page.Block>
        <Page.Block span={4}>

        </Page.Block>
      </Page.Grid>
      <Page.Section className="projects__page__table">
        <DataTable isAdmin={user.isAdmin} lineAt={maxNumberOfProjects} emptyMessage={emptyMessage} bodyData={data} onOrderClick={onOrderClick} sortBy={filter.sortBy} sortDir={filter.sortDir}>
          <DataTable.Column name="status" sortable label={<CheckIcon />} formatter={(row) => 
            <RatedIcon method={row.method} isFaktanet={row.isFaktanet} ratingId={row.ratingId} ratedAt={row.ratedAt} /> 
          } />
          <DataTable.Column name="name" sortable label="Namn" formatter={(row) => <><span>{row.title}</span><span><b>{row.office && row.office.name}</b> - {row.projectId} - {row.name}</span></>} />
          <DataTable.Column name="category" sortable label="Kategori" formatter={(row) => !!row.category ? row.category.name : ''} />
          <DataTable.Column name="startDate" sortable label="Byggstart" formatter={(row) => <><span>{formatMonthDate(row.startDate)}</span>{!!row.month ? <><span>{row.month} <sub>mån</sub></span></> : ''} </>} />
          <DataTable.Column name="value" sortable label="Byggvärde" formatter={(row) => !!row.value ? <CurrencyValue value={row.value} /> : <sub>-</sub>} />
          <DataTable.Column name="edit" cellContent={<ArrowRightIcon />} cellFunc={id => history.push(`${location.pathname}/${id}`)} />
        </DataTable>
       </Page.Section>
    </Page>
    {
        addModalOpen && 
        <Modal isOpen={!!addModalOpen} onClose={() => setAddModalOpen(false) } closeButton={true}>
          <AddModal forecastId={forecastId} selected={true}></AddModal>
        </Modal>
      }
    </>
  )
}

ForecastProjects.propTypes = {
}
export default ForecastProjects


const AddModal = ({ forecastId, selected }) => {
  const dispatch = useDispatch();
  const [districts] = useForecastFaktanetDistricts(forecastId);
  const [categories] = useForecastFaktanetCategories(forecastId)
  const [constant] = useConstant();

  const [title, setTitle] = useState('')
  const [projectName, setProjectName] = useState('')
  
  const [description, setDescription] = useState('')
  const [value, setValue] = useState('')
  const [districtId, setDistrictId] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [progressId, setProgressId] = useState('')
  const [startDate, setStartDate] = useState('')
  const [month, setMonth] = useState('')
  
  
  const onSaveRating = () => {
    dispatch(addProject({forecastId, title, description, projectName, value, startDate, month, districtId, categoryId, progressId}))
  }

  const categories_options = categories.map(o => ({ id: o.id, value: o.id, label: o.name }))
  const districts_options = districts.map(o => ({ id: o.id, value: o.id, label: o.name }))
  const progress_options = constant.progresses.map(o => ({ id: o.id, value: o.id, label: o.name }))
  

  return (
    <ModalContentContainer title="Lägg till nytt projekt">
      <form
        method="dialog"
      >
        <Page.Grid columns={4}>
        <Page.Block span={4}>
            <label htmlFor="title">Titel</label>
            <InputText type="text" name="title" required value={title} onChange={e => setTitle(e.target.value)}/>
          </Page.Block>
          <Page.Block span={4}>
            <label htmlFor="projectName">Projektnamn</label>
            <InputText type="text" name="projectName" required value={projectName} onChange={e => setProjectName(e.target.value)}/>
          </Page.Block>
          <Page.Block span={4}>
            <label htmlFor="description">Objektsbeskrivning</label>
            <InputText type="text" name="description" required value={description} onChange={e => setDescription(e.target.value)}/>
          </Page.Block>
          <Page.Block span={2}>
            <label htmlFor="value">Byggvärde kr</label>
            <InputNumber
              name="value"
              value={value}
              formater={(new Intl.NumberFormat('sv-SE')).format}
              placeholder={value} 
              onValueUpdated={setValue}
              unit="kr"
            />
            
          </Page.Block>
          <Page.Block span={2}>
          </Page.Block>

          <Page.Block span={2}>
            <label htmlFor="startDate">Byggstart</label>
            <FormMonthPicker
              name="startDate"
              placeholderText="Välj månad"
              onChange={setStartDate}
            />
          </Page.Block>

          <Page.Block span={2}>
            <label htmlFor="month">Byggtid</label>
            <InputNumber placeholder="Ange antal månader" unit="mån" name="month" value={month} onValueChanged={setMonth} />
          </Page.Block>

          <Page.Block span={2}>
            <label htmlFor="districtId">Projektdistrikt (faktanet)</label>
            <SelectInput
                onSelect={setDistrictId}
                name="districtId"
                placeholder="Välj distrikt"
                value={districtId}
                options={districts_options}
              />
          </Page.Block>

          <Page.Block span={2}>
            <label htmlFor="categoryId">Kategori (faktanet)</label>
            <SelectInput
                onSelect={setCategoryId}
                name="categoryId"
                placeholder="Välj Kategori"
                value={categoryId}
                options={categories_options}
              />
          </Page.Block>

          <Page.Block span={2}>
            <label htmlFor="progressId">Stadie</label>
            <SelectInput
                onSelect={setProgressId}
                name="progressId"
                placeholder="Välj Stadie"
                value={progressId}
                options={progress_options}
              />
          </Page.Block>

          <Page.Block span={4}>
          </Page.Block>
          <Page.Block span={1}>
            <button
              onClick={onSaveRating}
              className="button"
            >Lägg till</button>
          </Page.Block>
        </Page.Grid>
      </form>
    </ModalContentContainer>
  )
}
