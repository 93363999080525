import * as types from 'store/_types'
import { WAITING, LOADING, SUCCESS, ERROR } from 'store/_types'

const initialState = {
  token: '',
  details: {
    is_admin: false
  },
  association: '',
  accountCreated: false,
  logoutNetworkState: WAITING,
  logoutError: null
}

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token
      }
    case types.GET_ME_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          ...action.details
        }
      }
    case types.GET_USER_ASSOCIATION_SUCCESS:
      return {
        ...state,
        association: action.association
      }
    case types.ACCOUNT_CREATED_SUCCESS:
      return {
        ...state,
        accountCreated: true
      }
    case types.ACCOUNT_CREATED_REDIRECTED:
      return {
        ...state,
        accountCreated: false
      }

    case types.LOGOUT_USER_LOADING:
      return {
        ...state,
        logoutNetworkState: LOADING,
        logoutError: initialState.logoutError
      }
    case types.LOGOUT_USER_ERROR:
      return {
        ...state,
        logoutNetworkState: ERROR,
        logoutError: action.payload
      }
    case types.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        logoutNetworkState: SUCCESS,
        logoutError: initialState.logoutError
      }

    case types.CLEAR_USER:
      return Object.assign({}, initialState)

    default:
      return state
  }

}

export default userReducer
