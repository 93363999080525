import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import { useHistory, useLocation } from "react-router-dom";

import HeaderMain from 'components/header-main/header-main';
import Footer from 'components/footer/footer';
import Logo from "components/logo/logo"
import Spinner from "components/spinner/spinner"
import CountyListing from "components/county-listing/county-listing";
import {ReactComponent as LogoType} from "assets/icons/logotype.svg"
import BlockContent from '@sanity/block-content-to-react'
import SanityLoader from 'containers/sanity-loader'
import { useSanity } from 'store/sanity'
import "layouts/_main.public.layout.scss"
import { useUserProfile } from 'store/profile'
const MainPublicLayoutContent = (props) => {

  const { children } = props;
  const history = useHistory()
  const [{ settings }] = useSanity();
  const [user] = useUserProfile()
  const location = useLocation()
  const {maintenance} = settings
  const {loggedin, isLoadingUser} = user
  useEffect(() => {
    if (settings.maintenance === true && loggedin !== true && isLoadingUser === false)
    {
      history.push(`/maintenance`)
    } 
  }, [maintenance, loggedin, isLoadingUser])
  
  if (!settings._id) {
    // Return null if we don't have sanity cms data.
    return null
  }

  return (
    <>
      <HeaderMain
        location={location}
        isPublic
        navItems={[
          {
            label: "Se prognoser",
            link: "/prognoser"
          },
          {
            label: "Uppdateringar",
            link: "/uppdateringar"
          },
          {
            label: "Sverigekarta",
            link: "/byggvolymer"
          },
          {
            label: "Om prognoserna",
            link: "/om_prognoserna"
          },
          {
            label: "Prognosmetod",
            link: "/prognosmetod"
          },
          {
            label: "Kontaktpersoner",
            link: "/kontakt"
          }
        ]}
        logo={<Logo classNs="header-main-public_logo" logoType="" />}
        {...props}
      />
      <main className="main-public">
        { children }
      </main>
      <CountyListing {...props}/>
      <Footer>
        <div className="footer__contact">
          <div className="footer__contact__block">
            <h4 className="footer__contact__header">Adress</h4>
            <BlockContent blocks={settings.footer_address} />
          </div>
          <div className="footer__contact__block">
            <h4 className="footer__contact__header">Kontakt</h4>
            <BlockContent blocks={settings.footer_contact} />
          </div>
        </div>
        <Logo classNs={`footer-logo`} logoMark="" logoType={<LogoType/>} />
      </Footer>
    </>
  )
}

const MainPublicLayout = (props) => {

  return (
    <>
      <SanityLoader />
      <MainPublicLayoutContent {...props}/>
      <Spinner />
    </>
  )
}

MainPublicLayout.propTypes = {
  classNs: PropTypes.string,
  location: PropTypes.object,
  route: PropTypes.object
}

export default MainPublicLayout
