import React from 'react'
import { useSanity } from 'store/sanity';

import ArticleHeader from "components/article-header/article-header"
import Report from "routes/report/report"

import "routes/start/_start-page.scss"

const Start = () => {
  const [{ settings }] = useSanity()

  return (
    <>
      <ArticleHeader
        classNs="start-page__wrapper__header"
        title={<React.Fragment>{settings.titlePrefix} <span className="start-page__wrapper__header-highlight">{settings.title}</span></React.Fragment>}
        subtitle={settings.subtitle}
      ></ArticleHeader>
      <Report locationId={'hela-sverige'} categoryId='alla' />
    </>
  )
}

export default Start
