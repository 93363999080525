
// import errorHandler from "api/error-handler";
import env from 'environments'
import userManager from 'utils/userManager';

const API_BASE = env.API_BASE || "api";
const FUNC_BASE = env.FUNC_BASE || "api";

export const WAITING = 'WAITING'
export const LOADING = 'LOADING'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'


export const funcClient = async (path, options) => {

  const {method = 'GET', data = {}} = options || {}

  const url = new URL(`${FUNC_BASE}${path}`);

  const body = method !== "GET" && data ? JSON.stringify(data) : null;
  if (data && method === "GET") {
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        for (const v of data[key]) {
          url.searchParams.append(key, v)
        }
      } else {
        url.searchParams.append(key, data[key])
      }
    });
  }

  const headers = {
    'Content-Type': 'application/json'
  };

  try {
    const response = await fetch(url, {
      headers: headers,
      method: method,
      body: body,
    });

    if (response.ok === false) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    } else if (response.status === 204) {
      return {};
    } else {
      const json = await response.json();

      if (typeof json === 'undefined') {
        throw new Error('Felaktigt API svar.');
      }
      return json;
    }

  } catch (e) {
    throw e;
  }
}


export const httpClient = async (path, options) => {

  const {method = 'GET', data = {}} = options || {}

  const url = new URL(`${API_BASE}api/${path}`);
  const user = await userManager.getUser();
  if (!user) {
    throw new Error('No user')
  }
  const token = user?.access_token 
  if (!token) {
    throw new Error('No user token')
  }

  const body = method !== "GET" && data ? JSON.stringify(data) : null;
  if (data && method === "GET") {
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        for (const v of data[key]) {
          url.searchParams.append(key, v)
        }
      } else {
        url.searchParams.append(key, data[key])
      }
    });
  }

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "X-AD-GROUP": sessionStorage.getItem('isAdmin') === 'true' ? 'a3d88b12-9a5c-4be4-bc90-f25848275ba6' : '97a22b7b-6a62-4834-bd00-177c6fcc0d8e'
  };

  try {
    const response = await fetch(url, {
      headers: headers,
      method: method,
      credentials: "omit", // https://developer.mozilla.org/sv-SE/docs/Web/HTTP/CORS/Errors/CORSNotSupportingCredentials
      body: body,
    });

    if (response.ok === false) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
    } else if (response.status === 204) {
      // No Content See: https://developer.mozilla.org/sv-SE/docs/Web/HTTP/Status/204
      return {};
    } else {
      const json = await response.json();

      if (typeof json === 'undefined') {
        // TODO: check the proper way of cheking json errors
        throw new Error('Misslyckades att ansluta till APIet.');
      }
      // TODO: Handle SyntaxError: "JSON.parse: unexpected end of data at line 1 column 1 of the JSON data" Ex. password change
      return json;
    }

  } catch (e) {
    throw e
  }
}
