import React, { useContext, useEffect, useState } from 'react'

import { useForecast } from "store/forecast"
import { useCategories } from "store/categories"

import ForecastTabBar from "routes/forecast/tab-bar"
import Page from "components/page/page";
import Regions from "./settings_regions";
import Categories from "./settings_categories";
import Districts from "./settings_districts";
import Ratings from "./settings_ratings";
import Sizes from "./settings_sizes";

import './settings.scss'
import { useOfficeList } from 'store/offices';

export const DataContext = React.createContext({});

const ForecastSettings = ({ tab, forecastId }) => {

  const [forecast] = useForecast(forecastId)
  const [categories] = useCategories()
  const [offices] = useOfficeList();
  return (
    <DataContext.Provider value={{categories, offices}} >        
      <Page className="project__settings__page">
        <Page.Header title={forecast.name} >
          <ForecastTabBar forecastId={forecastId} />
        </Page.Header>
        <Page.SubMenu choices={[
          {value: `/forecast/${forecastId}/settings/regions`,label: "Regioner"},
          {value: `/forecast/${forecastId}/settings/categories`,label: "Kategorier"},
          {value: `/forecast/${forecastId}/settings/districts`,label: "Kommuner"},
          {value: `/forecast/${forecastId}/settings/ratings`,label: "Bedömningar"},
          {value: `/forecast/${forecastId}/settings/sizes`,label: "Projektstorlek"},
        ]} />
        <Page.Grid columns={2} title="" className="project__settings__page__table">
          <Page.Block span={2}>
              { 
                tab === 'regions' ? <Regions forecastId={forecastId} /> :
                tab === 'categories' ? <Categories forecastId={forecastId} /> :
                tab === 'districts' ? <Districts forecastId={forecastId} /> :
                tab === 'ratings' ? <Ratings forecastId={forecastId} /> :
                tab === 'sizes' ? <Sizes forecastId={forecastId} /> :
                <Regions forecastId={forecastId} /> 
              }

          </Page.Block>
        </Page.Grid>
      </Page>
    </DataContext.Provider>
  )
}

export default ForecastSettings
