import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from "react-redux";

import { useRegionList } from "store/regions"
import { updateOffice, addOffice } from "store/offices"
import { updateForecastOffice } from "store/forecast_offices";
import { useForecast } from "store/forecast"
import { useForecastOffices } from "store/forecast_offices"

import DataTable from "components/data-table/data-table";
import Page from "components/page/page";
import Modal from "components/modal/modal"
import { EditPenIcon } from "components/icon"
import ModalContentContainer from "components/modal/modal-content-container"
import SelectInput from "components/select-input/select-input"
import InputNumber from "components/input-number/input-number";
import InputText from "components/input-text/input-text";


const ForecastRegions = ({forecastId}) => {

  const [forecastOffices] = useForecastOffices(forecastId);
  const [selected, setSelected] = useState(null)
  const [createModalOpen, setCreateModalOpen] = useState(false)

  const handleEditClick = id=> {
    const _selected = forecastOffices.filter(i => i.id === id).pop()
    if (_selected) {
      setSelected(_selected)
    }
  }
  const onCloseEdit = () => {
    setSelected(null)
  }

  return (
    <>
      <DataTable bodyData={forecastOffices}>
        <DataTable.Column name="name" label="Namn" />
        <DataTable.Column name="regionName" label="Region" formatter={(row) => row.region.name} />
        <DataTable.Column name="maxNumberOfProjects" label="Max antal projekt" />
        <DataTable.Column name="districtCount" label="Antal kommuner" />
        <DataTable.Column name="edit" cellContent={<EditPenIcon/>} cellFunc={handleEditClick} />
      </DataTable>
      {
        selected && 
        <Modal isOpen={!!selected} onClose={onCloseEdit} closeButton={true}>
        <EditModal forecastId={forecastId} selected={selected}/>
        </Modal>
      }
      {createModalOpen && 
        <Modal isOpen={createModalOpen} onClose={() => {setCreateModalOpen(false)}} closeButton={true}>
        <EditModal forecastId={forecastId} />
        </Modal>
      }
      
      </>
  )
}

const EditModal = ({ forecastId, selected }) => {
  const dispatch = useDispatch();
  const [officeId, setOfficeId] = useState('')
  const [name, setName] = useState('')
  const [regionId, setRegionId] = useState('')
  const [maxNumberOfProjects, setMaxNumberOfProjects] = useState('')
  const [regions] = useRegionList();

  useEffect(() => {
    if (selected) {
      setOfficeId(selected.id);
      setName(selected.name);
      setRegionId(selected.region && selected.region.id);
      setMaxNumberOfProjects(selected.maxNumberOfProjects);
    }
  }, [selected])

  const onSaveOffice = () => {
    if (selected && selected.id) {
      dispatch(updateOffice({ name, regionId: regionId - 0, officeId: officeId - 0 }))
      dispatch(updateForecastOffice({ forecastId, maxNumberOfProjects, officeId: officeId - 0 }))
    } else {
      dispatch(addOffice({ name, regionId: regionId - 0, id: officeId - 0 }))
      dispatch(updateForecastOffice({ forecastId, maxNumberOfProjects, officeId: officeId - 0 }))
    }
  }
  const options = regions.map(o => ({ id: o.id, value: o.id, label: o.name }))

  return (
    <ModalContentContainer title="Redigera">
      <form
        method="dialog"
      >
        <Page.Grid columns={4} >
          <Page.Block span={2}>
            <InputText
              label="Id"
              placeholder="Id "
              readOnly={selected && selected.id > 0}
              disabled={selected && selected.id > 0}
              name="id"
              required
              value={officeId}
              onValueUpdated={setOfficeId}
            />
          </Page.Block>
          <Page.Block span={2}>
            <InputNumber
              name="maxNumberOfProjects"
              label="Antal projekt"
              value={maxNumberOfProjects}
              onValueChanged={setMaxNumberOfProjects}
              unit="st"
            />
          </Page.Block>
          <Page.Block span={4}>
            <InputText
              label="Namn"
              placeholder="Namn på prognosregionen"
              name="name"
              required
              value={name}
              onValueUpdated={setName}
            />
          </Page.Block>
          <Page.Block span={2}>
            <SelectInput
              placeholder="Välj region"
              label="Region"
              onSelect={setRegionId}
              name="regionId"
              value={regionId}
              options={options}
            />
          </Page.Block>

          <Page.Block span={4}>
          </Page.Block>
          <Page.Block span={1}>
            <button
              onClick={onSaveOffice}
              className="button"
            >Spara</button>
          </Page.Block>
          {/* <Page.Block span={1}>
            <button
                onClick={onDeleteRating}
                className="button-2"
            >Radera</button>
          </Page.Block> */}

        </Page.Grid>
      </form>
    </ModalContentContainer>
  )
}

ForecastRegions.propTypes = {
  classNs: PropTypes.string,
  match: PropTypes.object
}
export default ForecastRegions
