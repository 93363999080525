import React, { useState } from 'react'
import Modal from "components/modal/modal"
import { useDispatch } from "react-redux";

import { useForecastSizes, updateForecastSizes } from "store/forecast_sizes"

import ModalContentContainer from "components/modal/modal-content-container"
import InputNumber from "components/input-number/input-number";
import DataTable from "components/data-table/data-table";
import Page from "components/page/page";
import { EditPenIcon } from "components/icon"

const ForecastSizes = ({ forecastId }) => {

  const [forecastSizes] = useForecastSizes(forecastId);
  const [selected, setSelected] = useState(null)

  const handleEditClick = (_id, _selected) => {
    if (_selected) {
      setSelected(_selected)
    }
  }

  const onCloseEdit = () => {
    setSelected(null)
  }

  return (
    <>
      <DataTable bodyData={forecastSizes} >
        <DataTable.Column name="name" label="Projektstorlek" />
        <DataTable.Column name="minValue" label="Min värde" />
        <DataTable.Column name="maxValue" label="Max värde" />
        <DataTable.Column name="edit" cellContent={<EditPenIcon/>} cellFunc={handleEditClick} />
      </DataTable>
      {
        selected && 
        <Modal isOpen={!!selected} onClose={onCloseEdit} closeButton={true}>
        <EditModal forecastId={forecastId} selected={selected}/>
        </Modal>
      }
    </>
  )
}


const EditModal = ({ forecastId, selected }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(selected.name)
  const [minValue, setMinValue] = useState(selected.minValue)
  const [maxValue, setMaxValue] = useState(selected.maxValue)
  const onSaveRating = () => {
    dispatch(updateForecastSizes({ forecastId, name, minValue, maxValue, id: selected.id }))
  }

  return (
    <ModalContentContainer title="Redigera">
      <form
        method="dialog"
      >
        <Page.Grid columns={4}>
          <Page.Block span={4}>
            <label htmlFor="name">Namn</label>
            <input
              type="text"
              placeholder="Namn på projektstorlek"
              name="name"
              required
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Page.Block>
          <Page.Block span={2}>
            <label htmlFor="minValue">Min värde</label>
            <InputNumber
              placeholder="Ange minValue"
              name="minValue"
              value={minValue}
              onValueChanged={setMinValue}
            />
          </Page.Block>
          <Page.Block span={2}>
            <label htmlFor="maxValue">Max värde</label>
            <InputNumber
              placeholder="Ange maxValue"
              name="maxValue"
              value={maxValue}
              onValueChanged={setMaxValue}
            />
          </Page.Block>
          <Page.Block span={4}>
          </Page.Block>
          <Page.Block span={1}>
            <button
              onClick={onSaveRating}
              className="button"
            >Spara</button>
          </Page.Block>
          {/* <Page.Block span={1}>
            <button
                onClick={onDeleteRating}
                className="button-2"
            >Radera</button>
          </Page.Block> */}

        </Page.Grid>
      </form>
    </ModalContentContainer>
  )
}


export default ForecastSizes
