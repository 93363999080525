import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { useSanity } from 'store/sanity';


import PropTypes from 'prop-types'
import Selector from "components/selector/selector";
import "components/location-selector/_location-selector.scss"

const CLASS_NS = "location-selector";

const LocationSelector = ({
  classNs=CLASS_NS,
  locationId
}) => {
  const getLabelNode = (name, id, level)=>{
    const className = level > 0 ? `${classNs}__selector_list_subItem` : ''
    return (<span className={className}>{name}</span>)
  }

  const history = useHistory()
  const [{countyFlat}] = useSanity()
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState()
  useEffect(() => {
    const _options = countyFlat.map(c => ({
      id: c.id,
      value: c.id,
      label: getLabelNode(c.title, c.id, c.level)
    }))
    setOptions(_options)
  }, [countyFlat])
  
  useEffect(() => {
    if (options.length > 0) {
      const _selected = options.find(o => o.id === locationId)
      setSelected(_selected)
    }
  }, [options, locationId])

  const onSelect = (value) => {
    history.push(`/prognoser/${value}/alla`);
  }

  if (options.length === 0) {
    return null;
  }

  return (
    <nav className={`${classNs}`}>
      <div className={`${classNs}__container`}>
        <label className={`${classNs}__header`} htmlFor="location-selector">Visa prognos för:</label>
        <Selector
          classNs={`${classNs}__selector`}
          name="location-selector"
          buttonText="Region:"
          options={options}
          onSelect={onSelect}
          placeholder="Ingen vald"
          selectedOption={selected}
        />
        
      </div>
    </nav>
  )
}

LocationSelector.propTypes = {
  classNs: PropTypes.string
}
export default LocationSelector
