import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'

const GET_REGION_LIST_LOADING = 'GET_REGION_LIST_LOADING'
const GET_REGION_LIST_SUCCESS = 'GET_REGION_LIST_SUCCESS'
const GET_REGION_LIST_ERROR = 'GET_REGION_LIST_ERROR'

/* ACTIONS */
const getRegionList = () => (dispatch) => {
  dispatch({
    type: GET_REGION_LIST_LOADING
  });

  httpClient(`regions`)
    .then(result => {
      dispatch({
        type: GET_REGION_LIST_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: GET_REGION_LIST_ERROR,
        error,
        payload: error
      });
    })
}

/* HOOKS */
export const useRegionList = () => {
  const dispatch = useDispatch();
  const { rows = [], networkState = WAITING } = useSelector(state => state.regions);

  useEffect(() => {
    if (networkState === WAITING) {
      dispatch(getRegionList())
    }
  }, [networkState, dispatch]);

  return [rows, networkState];
};

/* REDUCER */
const initialState = {
  error: null,
  networkState: WAITING,
  rows: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REGION_LIST_LOADING:
      return {
        ...state,
        networkState: LOADING
      }
    case GET_REGION_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    case GET_REGION_LIST_SUCCESS:
      return {
        ...state,
        rows: action.payload,
        networkState: SUCCESS,
      }

    default:
      return state
  }
}
