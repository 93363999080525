import React from "react";
import PropTypes from "prop-types";

import RadioGroup from "components/radio-group/radio-group";

import "components/tab-bar/_tab-bar.scss"
const CLASS_NS = "tab-bar";

/**
 * TabBar - A horizontally layed out list.
 *
 * @param {string}  [Unknown.classNs=Unknown]   A ClassName constant
 * @param {array}    [Unknown.choices=[]]        Description
 * @param {type}     Unknown.selected            Description
 * @param {function} [Unknown.onChange=() => {}] Description
 *
 * @returns {*} A React components
 */
const TabBar = ({
  classNs = CLASS_NS,
  choices = [],
  selected,
  onChange = () => {},
  children
}) => {

  return (
    <div className={`${classNs}__container ${CLASS_NS}__container`}>
      <RadioGroup classNs={`${CLASS_NS}` } choices={choices} onChange={onChange} selected={selected} children={children} />
    </div>
  );
};

TabBar.propTypes = {
  classNs: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])
};
export default TabBar;
