import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import "components/input-array/_input-array.scss"

const InputArray = ({
  name,
  alter = true,
  label,
  size = 3, 
  value,
  type = 'text',
  onChange,
}) => {


  const onChangePart = (old, index) => (e) => {
    const v =  + e.target.value
    const newValue = [...value]
    newValue[index] = v
    onChange(newValue)
  }

  const onChangeOp = (index) => (e) => {
    const op = e.target.value
    switch (op) {
      case 'clear':
        const cleared = [...value]
        cleared[index] = ''
        onChange(cleared)
      break;
      case 'delete':
        const deleted = [...value]
        deleted.splice(index, 1)
        onChange(deleted)
      break;
      case 'add_left':
        const left = [...value]
        left.splice(index, 0, '')
        onChange(left)
      break;
      case 'add_right':
        const right = [...value]
        right.splice(index + 1, 0, '')
        onChange(right)
      break;
    }
    e.target.value = null
  }

  return (<>
    <div className="input-array">
      {value.map((v, i) => (
          <div className="input-array__outer" key={`outer_${i}`}>
            {label && label(v, i) }

            <div className="input-array__inner">
              <input
                key={`key_${i}`}
                className="input-array__inner__input"
                value={v}
                name={`name_${i}`}
                onChange={onChangePart(v, i)}
                type={type}
                size={size}
                />
              <select className="input-array__inner__op" onChange={onChangeOp(i)}>
                <option value=""></option>
                <option value="clear">Rensa</option>
                { alter && <option value="delete">Radera</option> }
                { alter && <option value="add_left">Lägg till vänster</option> }
                { alter && <option value="add_right">Lägg till höger</option> }
              </select>
            </div>
          </div>
      ))}
    </div>
  </>
  )
}

InputArray.propTypes = {
  classNs: PropTypes.string,
  value: PropTypes.array,
  label: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func
  ]),
  onChange: PropTypes.func,
  formater: PropTypes.func,
  onValueUpdated: PropTypes.func,
  onValueChanged: PropTypes.func,
  buttonContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType
  ]),
  minVal: PropTypes.number,
  maxVal: PropTypes.number,
}

export default InputArray
