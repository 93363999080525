import React, { useEffect } from 'react'

import SanityPage from 'components/sanity-page/sanity-page';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NotFound from 'components/notfound/notfound'
import { useUserProfile } from 'store/profile'
export default () => {

  const history = useHistory()
  const [user] = useUserProfile()
  const { maintenance: page } = useSelector(state => state.sanity.page);
  const { maintenance } = useSelector(state => state.sanity.settings);
  const { loggedin } = user

  useEffect(() => {
    // Redirect to / if maintenance-switch is not on, and user is not loggedin.
    if (maintenance !== true && loggedin !== true) {
      history.push(`/`)
    }
  }, [maintenance, loggedin])

  if (!page) return <NotFound />
  return <SanityPage page={page} />
}
