import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";

import { formatDate } from 'utils/date-helpers'
import ArticleHeader from "components/article-header/article-header"
import Card from "components/card/card";
import BlockContent from '@sanity/block-content-to-react'

import "routes/news/_news.scss"

const NewsItem = ({ id, title, content, publishedAt, imageBgSrc, imageSrc }) => (
  <li className="news-page__news-item">
    <Card
      classNs="news-page__card"
      linkUrl={`/uppdateringar/${id}`}
      supertitle={publishedAt ? <h5 className="news-page__card__supertitle">Publicerad <time dateTime={publishedAt}>{formatDate(publishedAt)}</time></h5> : null}
      title={title}
      imageBgSrc={imageBgSrc}
      imageSrc={imageSrc}
    >
      <BlockContent blocks={content} />
    </Card>
  </li>
)

const News = () => {

  const { news: articles } = useSelector(state => state.sanity);
  const [articleList, setArticleList] = useState([])
  const [showMore, setShowMore] = useState(false);
  const [limit, setLimit] = useState(4);

  useEffect(() => {
    if (articles) {
      setArticleList(articles);
    }
  }, [])

  const getArticles = () => {
    return articles.slice(0, limit);
  }

  const handleShowMore = (e) => { // TODO: onClick should get 4 (or 6) more articles, not the rest as it is now.
    e.preventDefault();

    setShowMore(!showMore);
    if (!showMore) {
      setShowMore(true);
      setArticleList(getArticles());
    }
    setLimit(limit + 4)
  }

  const showButton = limit < articles.length ? true : false

  if (!articleList) {
    return null;
  }

  return (
    <>
      <ArticleHeader
        classNs="news-page__header"
        title="Uppdateringar"
      ></ArticleHeader>
      <article className="page-article">
        <section className="news-page">
          <ul className="news-page__news-list">
            {getArticles().map((a, id) => <NewsItem key={id} {...a} />)}
          </ul>
          <div className="news-page__load-more">
            {showButton && <button className="news-page__load-button" onClick={handleShowMore}>Ladda fler</button>}
          </div>
        </section>
      </article>
    </>
  )
}

export default News
