import React from "react";
import PropTypes from "prop-types";

const CLASS_NS = "loading"

const Loading = ({
  classNs = CLASS_NS,
  aligment = "top",
  size = "L"
}) => {

  return (
    <div className={`${classNs}`} data-align={aligment} data-size={size}>
      <svg className={`${classNs}__circle`} viewBox="25 25 50 50">
        <circle className={`${classNs}__ring`} cx="50" cy="50" r="20" fill="none" strokeWidth="5" strokeMiterlimit="10" />
        <circle className={`${classNs}__path`} cx="50" cy="50" r="20" fill="none" strokeWidth="3" strokeMiterlimit="10" />
      </svg>
    </div>
  );
}

Loading.propTypes = {
  classNs: PropTypes.string,
  size: PropTypes.string,
  alignment: PropTypes.string,
};

export default Loading;
