import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'
import { useUserProfile } from 'store/profile'
import { uiStartSpinner, uiStopSpinner } from 'store/ui_spinner'
const GET_FORECAST_PROJECTS_LOADING = 'GET_FORECAST_PROJECTS_LOADING'
const GET_FORECAST_PROJECTS_SUCCESS = 'GET_FORECAST_PROJECTS_SUCCESS'
const GET_FORECAST_PROJECTS_ERROR = 'GET_FORECAST_PROJECTS_ERROR'


/* ACTIONS */
const getForecastProjectList = ({forecastId, filter = {}}) => (dispatch) => {
  var filterQuery = []
  var sortQuery = []
  var limit = {}

  if (filter.name && filter.name.length > 0) {
    if (isNaN(filter.name)) {
      const _value = encodeURIComponent(filter.name)
      filterQuery.push(`name.like.${_value}`)
    } else {
      const _value = parseInt(filter.name, 10)
      filterQuery.push(`id.eq.${_value}`)
    }
  }
  if (filter.source) {
    const _value = filter.source ?? ''
    filterQuery.push(`source.eq.${_value}`)
  }
  if (filter.ratingId && !isNaN(filter.ratingId)) {
    const _value = parseInt(filter.ratingId, 10)
    filterQuery.push(`rating.eq.${_value}`)
  }
  if (filter.categoryId && !isNaN(filter.categoryId)) {
    const _value = parseInt(filter.categoryId, 10)
    filterQuery.push(`category.eq.${_value}`)
  }
  if (filter.officeId && !isNaN(filter.officeId)) {
    const _value = parseInt(filter.officeId, 10)
    filterQuery.push(`office.eq.${_value}`)
  }
  if (typeof filter.valueMin !== 'undefined') {
    const _value = parseInt(filter.valueMin, 10)
    if (!isNaN(_value)) {
      filterQuery.push(`valueMin.ge.${_value}`)
    }
  }
  if (typeof filter.valueMax !== 'undefined') {
    const _value = parseInt(filter.valueMax, 10)
    if (!isNaN(_value)) {
      filterQuery.push(`valueMax.le.${_value}`)
    }
  }

  if (typeof filter.timeMin !== 'undefined') {
    const _value = parseInt(filter.timeMin, 10)
    if (!isNaN(_value)) {
      filterQuery.push(`timeMin.ge.${_value}`)
    }
  }
  if (typeof filter.timeMax !== 'undefined') {
    const _value = parseInt(filter.timeMax, 10)
    if (!isNaN(_value)) {
      filterQuery.push(`timeMax.le.${_value}`)
    }
  }

  if (filter.startDateMinIso) {
    filterQuery.push(`startDate.ge.${filter.startDateMinIso}`)
  }
  if (filter.startDateMaxIso) {
    filterQuery.push(`endDate.le.${filter.startDateMaxIso}`)
  }
  if (filter.sortBy && filter.sortDir) {
    sortQuery.push(`${filter.sortBy}.${filter.sortDir}`)
  }
  if (filter.limit && !isNaN(filter.limit)) {
    limit = {limit: parseInt(filter.limit, 10)}
  }

  dispatch({type: GET_FORECAST_PROJECTS_LOADING});
  dispatch(uiStartSpinner());
  httpClient(`forecasts/${forecastId}/projects`, {data: {forecastId, filter: filterQuery, order: sortQuery, ...limit}})
    .then(result => {
      dispatch({
        type: GET_FORECAST_PROJECTS_SUCCESS,
        payload: result
      });
      dispatch(uiStopSpinner());
    })
    .catch(error => {
      dispatch({
        type: GET_FORECAST_PROJECTS_ERROR,
        error,
        payload: error
      });
      dispatch(uiStopSpinner());
    })
}


/* HOOKS */

export const useForecastProjectList =  (forecastId) => {
  const dispatch = useDispatch();
  const [user] = useUserProfile()
  const filter = useSelector(state => state.project_filter);
  const { rev = 0 } = filter
  const { isAdmin = false } = user
  const { rows, error, networkState, total, limit, offset, queryString } = useSelector(state => state.forecast_project_list);

  useEffect(() => {
    if (forecastId) {
      if (isAdmin || rev > 0) {
        dispatch(getForecastProjectList({forecastId, filter}));
      }
    }
  }, [forecastId, isAdmin, rev]);
  
  return {rows, error, networkState, total, limit, offset, queryString};
};
/* REDUCER */
const initialState = {
  error: null,
  networkState: WAITING,
  total: 0,
  queryString: '',
  rows: [],
}
export default (state = initialState, action) => {
  switch (action.type) {


    case GET_FORECAST_PROJECTS_LOADING:
      return {
        ...state,
        networkState: LOADING
      }
    case GET_FORECAST_PROJECTS_ERROR:
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    case GET_FORECAST_PROJECTS_SUCCESS:

      return {
        ...state,
        ...action.payload,
        networkState: SUCCESS,
      }

    default:
      return state
  }
}
