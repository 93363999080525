import React, { useContext } from 'react'
import { useDispatch } from "react-redux";

import ForecastTabBar from "routes/forecast/tab-bar"
import DataTable from "components/data-table/data-table";
import Page from "components/page/page";
import SelectInput from "components/select-input/select-input"

import { useForecastDistricts, assignForecastDistrict } from "store/forecast_districts"

import { DataContext } from './settings'

const OfficeSelector = ({ onSelect, selected }) => {
  const { offices } = useContext(DataContext)
  if (!offices) return null;
  const options = offices.map(o => ({ id: o.id, value: o.id, label: o.name }))

  return <SelectInput
    onSelect={onSelect}
    noborder
    name="office"
    placeholder="Välj region"
    value={selected && selected.id}
    options={options}
  />
}


const ForecastDistricts = ({ forecastId }) => {

  const dispatch = useDispatch()
  const [forecastDistricts] = useForecastDistricts(forecastId);

  const onSelect = (row) => (id) => {
    const officeId = id - 0
    const fnDistrictId = row.id
    dispatch(assignForecastDistrict({ forecastId, officeId, fnDistrictId }))
  }

  return (
    <DataTable bodyData={forecastDistricts}>
      <DataTable.Column name="name" label="Namn" />
      <DataTable.Column name="officeName" label="Nuvarande region" formatter={(row) => row.office && row.office.name} />
      <DataTable.Column name="officeId" label="Region" formatter={(row) => <OfficeSelector onSelect={onSelect(row)} selected={row.office}></OfficeSelector>} />
    </DataTable>
  )
}

export default ForecastDistricts
