import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';

// CSS Modules, react-datepicker-cssmodules.css
import "react-datepicker/dist/react-datepicker.css";
import "components/month-picker/_month-picker.scss"

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

registerLocale('sv', sv)

const defaultPlaceholder = "Ange datum";
const CLASS_NS = "month-picker";

function FormMonthPicker({
  classNs=CLASS_NS,
  placeholderText=defaultPlaceholder,
  onChange=()=>{},
  disabled = false,
  autoComplete = "off",
  name="",
  selected
}) {
  const [ interalValue, setInteralValue ] = useState()
  useEffect(() => {
    if (selected === null) {
      setInteralValue()
    } else {
      setInteralValue(selected)
    }
  }, [selected])

  const handleOnChange = (value) => {
    setInteralValue(value)
    if (onChange) {
      onChange(value)
    }
  }
  const handleOnSelect = (date) => { 
    setInteralValue(date)
  };
  return (
    <div className={`${classNs}`}>
      <DatePicker
        autoComplete={autoComplete}
        className={`${classNs}__calendar`}
        placeholderText={placeholderText}
        dateFormat="yyyy MMM"
        disabled={disabled}
        showMonthYearPicker
        locale="sv"
        selected={interalValue}
        onChange={handleOnChange}
        onSelect={handleOnSelect}
        name={name}
      />
      <CalendarIcon className={`${classNs}__icon`}/>
    </div>
  );
}

FormMonthPicker.propTypes = {
  classNs:PropTypes.string,
  selected:PropTypes.object,
  placeholderText:PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
}

export default FormMonthPicker
