import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'

const GET_FORECAST_CATEGORIES_LOADING = 'GET_FORECAST_CATEGORIES_LOADING'
const GET_FORECAST_CATEGORIES_SUCCESS = 'GET_FORECAST_CATEGORIES_SUCCESS'
const GET_FORECAST_CATEGORIES_ERROR = 'GET_FORECAST_CATEGORIES_ERROR'

const ASSIGN_FORECAST_CATEGORY_LOADING = 'ASSIGN_FORECAST_CATEGORY_LOADING'
const ASSIGN_FORECAST_CATEGORY_SUCCESS = 'ASSIGN_FORECAST_CATEGORY_SUCCESS'
const ASSIGN_FORECAST_CATEGORY_ERROR = 'ASSIGN_FORECAST_CATEGORY_ERROR'

/* ACTIONS */
const getForecastCategories = ({forecastId}) => (dispatch) => {
  dispatch({
    type: GET_FORECAST_CATEGORIES_LOADING
  });

  httpClient(`forecasts/${forecastId}/categories`)
    .then(result => {
      dispatch({
        type: GET_FORECAST_CATEGORIES_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: GET_FORECAST_CATEGORIES_ERROR,
        error,
        payload: error
      });
    })
}

export const assignForecastCategory = ({forecastId, fnCategoryId, ...data}) => (dispatch) => {
  dispatch({
    type: ASSIGN_FORECAST_CATEGORY_LOADING
  });

  httpClient(`forecasts/${forecastId}/categories/${fnCategoryId}/assign`, { data , method: 'POST'})
    .then(result => {
      dispatch({
        type: ASSIGN_FORECAST_CATEGORY_SUCCESS,
        payload: result
      });
      dispatch(getForecastCategories({forecastId}))
    })
    .catch(error => {
      dispatch({
        type: ASSIGN_FORECAST_CATEGORY_ERROR,
        error,
        payload: error
      });
    })
}

/* HOOKS */

export const useForecastCategories = (forecastId) => {
  const dispatch = useDispatch();
  const { rows, networkState } = useSelector(state => state.forecast_categories);

  useEffect(() => {
    if ( forecastId) {
      dispatch(getForecastCategories({forecastId}));
    }
  }, [forecastId, dispatch]);
  return [rows, networkState];
};
/* REDUCER */
const initialState = {
  rows: [],
  networkState: WAITING,
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {

    case GET_FORECAST_CATEGORIES_LOADING:
      return {
        ...state,
        networkState: LOADING
      }
    case GET_FORECAST_CATEGORIES_ERROR:
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    case GET_FORECAST_CATEGORIES_SUCCESS:
      return {
        ...state,
        rows: action.payload,
        networkState: SUCCESS,
      }

    default:
      return state
  }
}
