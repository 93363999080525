import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import Header from 'components/header/header';
import SideNav from 'components/side-nav/side-nav';
import NavSection from 'components/side-nav/nav-section';
import 'submodules/byggforetagen-web-components/BfNavigation/BfNavigation';

function useOutsideClose(ref, onClick) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    }
    function handleEscape(event) {
      const key = event.key;
      if (key === 'Escape') {
        onClick();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [ref]);
}

const HeaderMain = ({location, isPublic, user, isOpen = false, navItems = [], logo}) => {
  const wrapperRef = useRef(null);
  const [openState, setOpenState] = useState(isOpen);
  const [pageLoaded, setPageLoaded] = useState(false);

  const closeMenu = () => {
    setOpenState(false);
  };
  const toggleMenu = () => {
    setOpenState(!openState);
  };
  useOutsideClose(wrapperRef, closeMenu);
  // FIXME: Change the suboptimal way of displaying the SideNav component

  // Workaround to assure br-navigation accounts for scrollbar width
  useEffect(() => setPageLoaded(true));

  return (
    <>
      {pageLoaded ? <bf-navigation
        current-site='Prognoser'
        wide-navigation /> : null}
      <Header>
        {logo}
        <nav className='header__nav'>
          <NavSection location={location} navItems={navItems} />
          {user ? (
            <SideNav user={user} openState={openState} wrapperRef={wrapperRef} onClick={toggleMenu}>
              <div className='side-nav__menu'>
                <div className='side-nav__menu-container'>
                  <dd className='side-nav__link'>Inloggad som {user.email}</dd>
                  <dd className='side-nav__link logout'>
                    <Link to={'/logout'}>Logga ut</Link>
                  </dd>
                </div>
              </div>
            </SideNav>
          ) : null}
          {isPublic ? (
            <SideNav
              classNs='mobile-nav'
              openState={openState}
              wrapperRef={wrapperRef}
              onClick={toggleMenu}>
              <div className='side-nav__menu mobile'>
                <div className='side-nav__menu-container'>
                  <NavSection
                    location={location}
                    navItems={navItems}
                    onClick={closeMenu}
                    classNs='side-nav'
                  />
                </div>
              </div>
            </SideNav>
          ) : null}
        </nav>
      </Header>
    </>
  );
};

HeaderMain.propTypes = {
  location: PropTypes.object,
  isPublic: PropTypes.bool,
  user: PropTypes.object,
  isOpen: PropTypes.bool,
  navItems: PropTypes.array,
  logo: PropTypes.object,
};

export default HeaderMain;
