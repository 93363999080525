import React from 'react'
import { useSelector } from "react-redux";

// 

import ArticleHeader from "components/article-header/article-header"
import TrackBar from "components/track-bar/track-bar";
import "routes/news/_news-article.scss"
import Article from 'components/article/article';
import { formatDate } from 'utils/date-helpers'
import BlockContent from '@sanity/block-content-to-react'
import { formatIsoDate } from 'utils/date-helpers'

const NewsArticle = ({articleId}) => {
  const { news } = useSelector(state => state.sanity);
  const article = news.filter(a => a.id === articleId).pop()
  if (!article) {
    return null
  }
  return (
    <>
      <ArticleHeader
        classNs="news-article_header"
        title={article.title}
        subtitle={<BlockContent  blocks={article.preamble} />}
      >
        <div className="news-article__track-date-bar">
          <TrackBar title={article.title} />
          <span className="news-article__datestamp">Publicerad <time dateTime={formatIsoDate(article.publishedAt)}>{formatDate(article.publishedAt)}</time></span>
        </div>
        

      </ArticleHeader>
      <section className="page-article">

        <Article
          classNs="news-article"
          title={article.title}
          imageSrc={article.imageSrc}
          imageBgSrc={article.imageBgSrc}>
          <BlockContent  blocks={article.content} />
        </Article>
      </section>
    </>
  )
}

export default NewsArticle
