import React, { useState, useEffect } from 'react'

import "components/input-text/_input-text.scss"
const CLASS_NS = "input-text";

const InputText = ({
  classNs = CLASS_NS,
  name,
  label,
  value,
  rows = false,
  readOnly = false,
  onChange,
  onValueChanged,
  onValueUpdated,
  placeholder,
  dashedborder = false,
  autoComplete = "off",
  unit = '',
  min,
  max
}) => {
  const [internalValue, setInternalValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    if (typeof value === 'undefined') {
      setInternalValue('')
    } else if (value === null) {
      setInternalValue('')
    } else {
      setInternalValue(value)
    }
  }, [value])

  const onInputFocus = () => {
    setIsFocused(true);
  }
  const onInputBlur = () => {
    setIsFocused(false);
  }

  const onBlur = () => {
    setIsFocused(false);
    if (onValueChanged) {
      onValueChanged(internalValue)
    }
  }
  const onFocus = () => {
    setIsFocused(true);
  }

  const onComponentActive = () => {
    setIsFocused(true);
  }

  const handleOnChange = e => {
    const v = e.target.value
    setInternalValue(v)
    if (onChange) {
      onChange(e, null)
    }
    if (onValueUpdated) {
      onValueUpdated(v)
    }
  }
  return (
    <>
      {label &&
        <label htmlFor={name}>{label}</label>
      }

      <div className={`${classNs}
          ${dashedborder ? `${classNs}__dashedborder` : ''}
        `}
        onClick={onComponentActive}
        onFocus={onFocus}
        onBlur={onBlur}
        data-active={isFocused}
      >
        {rows ?
          <textarea
            className={`${classNs}__textarea`}
            name={name}
            rows={rows}
            onChange={handleOnChange}
            value={internalValue}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            wrap="hard"
          />
          :
          <input
            className={`${classNs}__input`}
            autoComplete={autoComplete}
            name={name}
            readOnly={readOnly}
            onChange={handleOnChange}
            type="text"
            placeholder={placeholder}
            value={internalValue}
            min={min}
            max={max}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
          />

        }
        {
          (unit && typeof unit === "string") ? <span className={`${classNs}__unit`}>{unit}</span> : unit
        }
      </div>
    </>
  )
}

export default InputText
