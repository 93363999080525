import React, { useContext } from 'react'
import Page from "components/page/page";
import {Link} from "react-router-dom";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg"
import {ForecastProjectContext} from './project'
const ForecastProjectNavSection = (props) => {
  const {forecast} = useContext(ForecastProjectContext)
  return (
    <Page.Grid {...props}>
      <Page.Block span={4} >
        <Link to={`/forecast/${forecast.id}/projects`} className="project__page__backlink">
          <>
            <ArrowRight className="project__page__backlink__arrow"/>
            <span>
              Tillbaka till projektlistan
            </span>
          </>
        </Link>
      </Page.Block>
    </Page.Grid>
  )
}

ForecastProjectNavSection.propTypes = {
}
export default ForecastProjectNavSection
