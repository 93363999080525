import React from 'react'
import PropTypes from 'prop-types'
import './_prop-table.scss'

const CLASS_NS="prop-table";

const PropTableRow = ({label, classNs=CLASS_NS, children, hasDescription}) => {
  const modifyClass = hasDescription ? `${classNs}__row__value ${classNs}__row__value-description` : `${classNs}__row__value`;
  return <div className={`${classNs}__row`}>
    {label ? <div className={`${classNs}__row__label`}>{label}</div> : null}
    <div className={modifyClass}>{children}</div>
  </div>
}

const PropTable = ({
  classNs=CLASS_NS,
  children,
  className,
  label
}) => {

  return (
    <section
      className={`${classNs} ${className} `}
    >
      {label ? <header className={`${classNs}__header`}>{label}</header> : null }
      <div className={`${classNs}__content`}>
        {children}
      </div>
    </section>
  )
}

PropTable.Row = PropTableRow

PropTable.propTypes = {
  classNs: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
}

PropTableRow.propTypes = {
  classNs: PropTypes.string,
  label: PropTypes.string,
  hasDescription: PropTypes.bool
}

export default PropTable;