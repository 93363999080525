import * as types from "store/_types";
import { categoryLabelToId } from 'utils/data-helpers'


import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import env from 'environments'
import sanityClient from '@sanity/client'
import { WAITING } from "store/_types";
import { uiStartSpinner, uiStopSpinner } from 'store/ui_spinner'

const client = sanityClient({
  projectId: env.SANITY_PROJECT_ID,
  dataset: env.SANITY_DATASET,
  useCdn: true
})

/* HOOKS */
export const useSanity = () => {

  const dispatch = useDispatch();
  const { networkState, ...sanity } = useSelector(state => state.sanity);

  useEffect(() => {
    if (networkState === WAITING) {

      dispatch({type: types.SANITY_LOADING })
      dispatch(uiStartSpinner())
      Promise.all([
        client.fetch(`
          *[_type == "county"] | order(primary desc, title asc)  { 
            title, 
            "id": region->slug.current, 
            "level": 0,
            "children": *[ _type == "region" && county._ref == ^._id && _id != ^.region->_id] 
              | order(title asc) 
              {
                title,
                "level": 1,
                "id": slug.current
              }
          }    
        `),
        client.fetch(`
          *[_type == "region"] | order(title asc) { 
            title,
            "id": slug.current, 
            subtitle,
            description,
            graph{title, content, comments},  					
            topBuilders{title, content, comments},
            topProjects{title, content, comments},
            investmentTotal{title, content, comments},
            housingPerYear{title, content, comments},
            permitPerYear{title, content, comments}, 
            "county": county->{
              "id": slug.current, 
              title
            }
          } 
        `),
        client.fetch(`
          *[_type == "news"] | order(publishedAt desc) { 
            title,
            "id": slug.current,
            "publishedAt": _createdAt,
            subtitle,
            preamble,
            content,
            "imageSrc": image.asset->url,
            }
        `),
        client.fetch(`
          *[_type == "content"] | order(title asc) { 
            title,
            "id": slug.current,
            subtitle,
            preamble,
            content,
            "image": image.asset->url,
            volumes{content},	
            }
        `),
        client.fetch(`
          *[_type == "contact"] | order(title asc) { 
            name,
            "id": slug.current,
            role,
            primary,
            email,
            address,
            phone,
            "image": image.asset->url,
            }
        `),
        client.fetch(`*[_type == "settings"] {
          _id,
          title,
          titlePrefix,
          subtitle,
          maintenance,
          year,
          forecastStart,
          forecastEnd,
          graph_footer,
          region_data_top_builder_info,
          region_data_top_projects_info,
          region_data_top_investment,
          region_data_top_housing_per_year,
          region_data_top_permit_per_year,
          region_data_graph_yaxisis_unit,
          footer_contact,
          footer_address,
          "downloadURL": downloadFile.asset->url
          }
        `),
      ])
        .then(([county, region, news, content, contact, settings]) => {
          dispatch(uiStopSpinner())
          dispatch({type: types.SANITY_SUCCESS, payload: {county, region, news, content, contact, settings} })        
        })
        .catch(error => {
          dispatch(uiStopSpinner())
          dispatch({type: types.SANITY_ERROR, payload: {error} })        
          console.error(error);
        })
    }
  }, []);

  return [sanity, networkState];
};


/* REDUCER */

const initialState = {
  error: null,
  networkState: WAITING,
  content: [],
  page: {},
  contact: [],
  county: [],
  countyFlat: [],
  news: [],
  region: [],
  settings: {},
  about: {},
  metod: {}
}

const parseCsv = (csv) => {
  return csv.replaceAll('−', '-').split(/[\n]/).map(r => r.split(/[｜|]/).map(v => v.trim()))
}

const parseRegion = (region) => {
  region.forEach(r => {

    if (r.graph && r.graph.content) {
      const { content, title, comments } = r.graph
      const data = parseCsv(content).map(set => {
        const [categoryLabel, subCategoryLabel, ...values] = set
        const categoryId = categoryLabelToId(categoryLabel)
        return {categoryLabel, categoryId, subCategoryLabel, values}
      })
      r.graph = {content, title, comments, data}
    } else {
      r.graph = {data: []}
    }

    
    if (r.topProjects && r.topProjects.content) {
      const { content, title, comments } = r.topProjects
      const data = parseCsv(content).map(set => {
        const [categoryLabel, projectId, name, description, value] = set
        const categoryId = categoryLabelToId(categoryLabel)
        return {categoryId, categoryLabel, projectId, name, description, value}
      })
      r.topProjects = {content, title, comments, data}
    } else {
      r.topProjects = {data: []}
    }


    if (r.topBuilders && r.topBuilders.content) {
      const { content, title, comments } = r.topBuilders
      const data = parseCsv(content).map(set => {
        const [categoryLabel, companyId, name, value] = set
        const categoryId = categoryLabelToId(categoryLabel)
        return {categoryLabel, categoryId, companyId, name, value}
      })
      r.topBuilders = {content, title, comments, data}
    } else {
      r.topBuilders = {data: []}
    }

    if (r.investmentTotal && r.investmentTotal.content) {
      const { content, title, comments } = r.investmentTotal
      const data = parseCsv(content).map(set => {
        const [categoryLabel, name, value] = set
        const categoryId = categoryLabelToId(categoryLabel)
        return {categoryLabel, categoryId, name, value}
      })
      r.investmentTotal = {content, title, comments, data}
    } else {
      r.investmentTotal = {data: []}
    }

    if (r.housingPerYear && r.housingPerYear.content) {
      const { content, title, comments } = r.housingPerYear
      const data = parseCsv(content).map(set => {
        const [year, value] = set
        return {year, value}
      })
      r.housingPerYear = {content, title, comments, data}
    } else {
      r.housingPerYear = {data: []}
    }
    if (r.permitPerYear && r.permitPerYear.content) {
      const { content, title, comments } = r.permitPerYear
      const data = parseCsv(content).map(set => {
        const [year, value] = set
        return {year, value}
      })
      r.permitPerYear = {content, title, comments, data}
    } else {
      r.permitPerYear = {data: []}
    }
  })
  return region
}

const parsePageContent = (page) => {
  if (page.byggvolymer) {
    const { content } = page.byggvolymer.volumes
    const data = parseCsv(content).map(set => {
      const [name, ...values] = set
      const yearlyData = [], chunkSize = 2
      while (values.length > 0) {
        yearlyData.push(values.splice(0, chunkSize));
      }
      yearlyData.reverse() // reverse to match the timeline value indexes
      return {name, yearlyData}
    }).filter(d => d.name)

    page.byggvolymer.volumes = {content, data}
  } else {
    page.byggvolymer.volumes = {data: []}
  }
  return page
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SANITY_LOADING:
      return {
        ...state,
      }
    case types.SANITY_ERROR:
      return {
        ...state,
        ...initialState.public,
        error: action.payload,
      }
    case types.SANITY_SUCCESS:
      const {county, region, news, content, contact, settings} = action.payload
      const page = content.reduce((acc, p) => { acc[p.id] = p ; return acc; }, {})
      return {
        content, 
        contact,
        page: page.byggvolymer ? parsePageContent(page) : page,
        county, 
        countyFlat: county.reduce((acc, cur) => {acc = acc.concat(cur).concat(cur.children || []); return acc }, []), 
        news, 
        region: parseRegion(region), 
        settings: settings[0]
      }

    default:
      return state
  }
}
