import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import PropTypes from 'prop-types'
import { tabChoices } from 'utils/constants';
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";

import TabBar from "components/tab-bar/tab-bar"
import "routes/report/_report-tab-bar.scss"

const tabChoicesDefault = "alla"

const ReportTabBar = ({ locationId }) => {

  const { pathname } = useLocation();
  const history = useHistory()
  const { settings } = useSelector(state => state.sanity);

  const [selected, setSelected] = useState(tabChoicesDefault);

  useEffect(() => {
    // Change selected based on url change
    const lastPath = pathname.split("/").pop();
    if (tabChoices.find(item => item.value === lastPath)) {
      setSelected(lastPath)
    }
  }, [pathname])

  const onTabBarChange = e => {
    const { value } = e.target;
    history.push(`/prognoser/${locationId}/${value}`);
  }
  return (
    <>
      <div className="report-tab-bar__wrapper">
        <TabBar
          classNs="report-tab-bar"
          choices={tabChoices}
          onChange={onTabBarChange}
          selected={selected}
        >
          {settings.downloadURL ? (
            <div className="report-tab-bar__download-link">
              <a href={`${settings.downloadURL}?dl=`} className="button-link-red">Ladda ner data <DownloadIcon className="button-link-red__icon" /></a>
            </div>
          ) : null}
        </TabBar>
      </div>

    </>
  )
}

ReportTabBar.propTypes = {
  locationId: PropTypes.string,
}
export default ReportTabBar
