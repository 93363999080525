const template = document.createElement('template');
template.innerHTML = `
    <style>
        :host {
            --bf-wc-menu-text-color: #354A51;
            --bf-wc-menu-background-color: #FFFC4D;
            --bf-wc-nav-height: 73px;

            --br-wc-chariot-down: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6L8 11L13 6' stroke='%23354A51' stroke-width='2' stroke-miterlimit='10'/%3E%3C/svg%3E");
            --br-wc-chariot-up: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 11L8 6L3 11' stroke='%23354A51' stroke-width='2' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");
            --br-wc-arrow-right: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24"/><path fill-rule="evenodd" clip-rule="evenodd" d="M18.346 10.5085L13.0171 5.09026L15.1484 3L24 12L15.1484 21L13.0171 18.9097L18.346 13.4915H0V10.5085H18.346Z" fill="%23354A51"/></svg>');
            --br-wc-arrow-right-active: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24"/><path fill-rule="evenodd" clip-rule="evenodd" d="M18.346 10.5085L13.0171 5.09026L15.1484 3L24 12L15.1484 21L13.0171 18.9097L18.346 13.4915H0V10.5085H18.346Z" fill="%23354A51"/></svg>');
            --br-wc-external-link: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.85693 2H13.9998V7.14286' stroke='%23354A51' stroke-width='2' stroke-miterlimit='10'/%3E%3Cpath d='M5.42857 2.85718H2V14H13.1429V10.5715' stroke='%23354A51' stroke-width='2' stroke-miterlimit='10'/%3E%3Cpath d='M13.9999 2L6.28564 9.71429' stroke='%23354A51' stroke-width='2' stroke-miterlimit='10'/%3E%3C/svg%3E");

            font-size: 1rem;
            color: var(--bf-wc-menu-text-color);
        }

        :host([hidden]) {
            display: none;
        }

        a {
            text-decoration: none;
            color: var(--bf-wc-menu-text-color);
        }

        .bf-wc-menu {
            display: flex;
            justify-content: end;

            height: var(--bf-wc-nav-height);
        }

        .bf-wc-menu-header {
            font-size: 1.125em;
            font-weight: 450;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            gap: .44em;
            padding: 0 .75em;
            cursor: pointer;
            width: fit-content;
            outline: 0;
            height: 100%;
            align-self: center;
        }

        .bf-wc-menu-header:after {
            content: '';
            background-image: var(--br-wc-chariot-down);
            width: 16px;
            height: 16px;
            background-size: cover;
            display: block;
        }

        .bf-wc-menu-header.open {
            background-color: var(--bf-wc-menu-background-color);
        }

        .bf-wc-menu-header.open:after {
            content: '';
            background-image: var(--br-wc-chariot-up);
            width: 16px;
            height: 16px;
            background-size: cover;
            display: block;
        }

        .bf-wc-menu-dialog {
            visibility: hidden;
            max-width: 100vw;
            width: 100%;
            max-height: 100%;
            height: 100%;
            margin: 0px;
            background-color: transparent;
            border: 0px transparent;
            outline: 0px transparent;
            padding: 0px;
            display: grid;
            grid-template-rows: var(--bf-wc-nav-height) 1fr;
            grid-template-areas:
                '. . .'
                '. items .';
        }

        .bf-wc-menu-dialog.wide {
            grid-template-columns: 50vw 50vw;

        }

        .bf-wc-menu-dialog::backdrop {
            background: transparent;
        }

        .bf-wc-menu-dialog.open {
            visibility: visible;
        }

        .bf-wc-menu-dialog.open.relative-menu {
            position: relative;
        }

        .bf-wc-menu-items {
            box-sizing: border-box;
            grid-area: items;
            margin: 0;
            padding: 2.67em;
            background-color: var(--bf-wc-menu-background-color);
        }

        .bf-wc-menu-items > a:focus-visible {
            outline: 0px transparent;
        }

        .bf-wc-menu-item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            font-size: 1.3125em;
            font-weight: 450;
            line-height: 2.25em;
            letter-spacing: .2px;

            transition: margin .2s;
            min-width: 100%;
            margin: 0 0 .3em;
            outline: 0px transparent;
        }

        .bf-wc-menu-items-placement {
            justify-self: end;
        }

        .bf-wc-menu-item:after {
            content: '';
            background-image: var(--br-wc-arrow-right);
            width: .76em;
            height: .76em;
            background-size: cover;
            display: block;
        }

        .bf-wc-menu-item.active-choice {
            font-weight: 500 !important;

            justify-content: start;
            align-items: center;
            gap: .59em;
        }

        .bf-wc-menu-item.active-choice:before {
            content: '';
            background-image: var(--br-wc-arrow-right-active);
            width: .76em;
            height: .76em;
            background-size: cover;
            display: block;
        }

        .bf-wc-menu-item.active-choice:after {
            content: none;
            background-image: none;
        }

        .bf-wc-menu-item:not(.active-choice):hover {
            transition: margin .2s;
            margin: 0 1em .3em 1em;
            font-weight: 500;
            min-width: auto;
        }

        .bf-wc-menu-item.secodary-choice {
            font-size: 1em;
        }

        .bf-wc-menu-item.secodary-choice:before {
            font-size: 1.313em;
        }

        .bf-wc-menu-item.secodary-choice:after {
            font-size: 1.313em;
        }

        .bf-wc-menu-item.external:after {
            content: '';
            background-image: var(--br-wc-external-link);
            width: 16px;
            height: 16px;
            background-size: cover;
            display: block;
        }

        .bf-wc-menu-item-sub-header {
            color: var(--bf-wc-menu-text-color);
            font-size: .75em;
            font-weight: 500;
            line-height: 2.25em;
            letter-spacing: 1px;
            margin-top: 3.33em;
            margin-bottom: 0;
        }


        [hidden] {
            display: none;
        }

        /* Mobile screens */
        /* Tablets */
        @media screen and (max-width: 768px) {

            .bf-wc-menu-items {
                padding: 2em 1em;
            }

            .bf-wc-menu-item {
                font-size: 1.313em;
                line-height: 1.75em;
                letter-spacing: .2px;

                margin: 0 0 .3em;
            }
        }
    </style>

    <nav class="bf-wc-menu">
        <div class="bf-wc-menu-header" role="button" >Byggföretagens sajter</div>
        <dialog class="bf-wc-menu-dialog" role="menu">
            <menu class="bf-wc-menu-items">
                <!-- Menu items are generated by handleMenuItems -->
            </menu>
        </dialog>
    </nav>
`;

/**
 * Menu component for Byggföretagen websites.
 *
 * @element bf-menu
 *
*  @attr {string} [menu-header-text] - The text that appears on the menu-header button
 * @attr {Object} [menu-items] - Object containing label, path, static-header and "secondary" boolean propery.
 * @attr {label: string} [menu-items.label] - The label of the menu item
 * @attr {path: string} [menu-items.path] - The path of the link
 * @attr {isMenuHeader: boolean} [menu-items.isMenuHeader] - Makes item label the constant menu button label
 * @attr {secondary: boolean} [menu-items.secondary] - Sets styling of item to look subordinate to previous item
 * @attr {external: boolean} [menu-items.external] - Sets external link icon to item
 * @attr {right | left} [menu-placement] - Determines in witch grid-area the menu-items will be placed
 * @attr {string} [menu-width] - The width of menu-items otherwise 100%
 * @attr {string} [content-area-width] - The width of the main content. If present, the dialog renders in 3 columns where the middle one is this width. Defaults to 100vw

 *
 * @slot [sub-nav] - Slot a sub navigation to make it sticky. Otherwise, leave it outside.
 */
export default class BfMenu extends HTMLElement {
    constructor() {
        super();
        this.shadow = this.attachShadow({
          mode: 'open',
          delegatesFocus: true,
        });
        this.shadowRoot.appendChild(template.content.cloneNode(true));
        this.scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        this.menuHeader = this.shadow.querySelector('.bf-wc-menu-header');
        this.menu = this.shadow.querySelector('.bf-wc-menu-dialog');
        this.menuItems = this.shadow.querySelector('.bf-wc-menu-items');
        this.menuItemsPlacement = '';
        this.styles=this.shadow.styleSheets[0];
        this.menuItemsPlacement = '';
        this.menuWidth = '100%';
        this.contentAreaWidth = null;
    }

    connectedCallback() {
        // Since NextJs don't have access to the document object we do a safety check
        if(typeof document !== 'undefined'){
            document.addEventListener('keydown', (e) => {
            if( this.menu.classList.contains('open') && this.menu.open ){
                this.#closeMenu() }
            })
        }

        this.menuHeader.addEventListener('click', () => {
            if (!this.menuHeader.classList.contains('open')) {
                this.#openMenu();
            }

            // calculate default value for menuItemsPlacement
            if(!this.menuItemsPlacement && document) {
                const documentWidth = document.documentElement.offsetWidth;
                const position = this.menu.getBoundingClientRect();
                if (position.left >= (documentWidth / 2)) {
                    this.menuItemsPlacement = 'right';
                } else {
                    this.menuItemsPlacement = 'left';
                }
            }

            this.#handleMenuPlacement();
        });
        this.menu.addEventListener('click', (e) => {
            if (e.target === this.menu) {
                this.#closeMenu();
            }
        });
    }

    static observedAttributes = ['menu-items', 'menu-header-text', 'menu-placement', 'menu-width', 'content-area-width'];

    attributeChangedCallback(name, oldValue, newValue) {
        switch(name) {
            case 'menu-items': {
                this.#handleMenuItems(newValue);
                break;
            }
            case 'menu-header-text': {
                this.menuHeader.innerText = newValue;
                break;
            }
            case 'menu-placement':{
                this.menuItemsPlacement = newValue;
                break;
            }
            case 'menu-width':{
                this.#handleMenuWidth(newValue);
                break;
            }
            case 'content-area-width':{
                this.#handleContentAreaWidth(newValue);
                break;
            }
            default: {
                break;
            }
        }
    }
    #handleMenuItems(menuItems) {
        const menuItemsJson = JSON.parse(menuItems);
        menuItemsJson.forEach(item => {
            const menuItemP = document.createElement('p');
            if(item.path) {
                menuItemP.innerText = item.label;
                menuItemP.classList.add('bf-wc-menu-item');

                const menuItemA = document.createElement('a');
                menuItemA.href = item.path;

                if(item.isMenuHeader) this.menuHeader.innerText = item.label;
                // check that location is available for NextJs
                if(window?.location && window?.location.href.startsWith(item.path)) menuItemP.classList.add('active-choice');
                if(item.secondary) menuItemP.classList.add('secodary-choice');
                if(item.external) {
                    menuItemP.classList.add('external');
                    menuItemA.target = '_blank';
                }

                menuItemA.appendChild(menuItemP.cloneNode(true));
                this.menuItems.appendChild(menuItemA);
            } else {
                menuItemP.innerText = item.label.toUpperCase();
                menuItemP.classList.add('bf-wc-menu-item-sub-header');
                this.menuItems.appendChild(menuItemP);
            }
        })
    }

    #handleMenuWidth(width){
        this.menuWidth = width;
        if(document) {
            const outerWidth = document.body.clientWidth;
            this.menuItems.style.width = `${outerWidth > 768 ? this.#pixelsToRem(width) : "100%"}`;
        }
    }

    #handleContentAreaWidth(width){
        this.contentAreaWidth = width;
    }

    #handleMenuPlacement(){
        const rectMenuHeader = this.menuHeader.getBoundingClientRect();
        const outerMarginWidth = this.contentAreaWidth ? (document.body.clientWidth - this.contentAreaWidth) / 2 : 0; // calculate outer margins if dialog width is set
        const menuRowHeight =  rectMenuHeader.top + rectMenuHeader.height;
        let menuColumnWidth = 0;
        if(!outerMarginWidth) {
            menuColumnWidth = this.menuWidth;
        } else {
            menuColumnWidth = this.menuItemsPlacement === 'left' ?
                rectMenuHeader.right - outerMarginWidth :
                document.body.clientWidth - rectMenuHeader.left - outerMarginWidth
        }

        this.menu.style.gridTemplateRows = `${menuRowHeight}px 1fr`;
        const calculatedColumns = this.#calculateMenuGridColumns(outerMarginWidth, menuColumnWidth, this.menuItemsPlacement);
        this.menu.style.gridTemplateColumns = calculatedColumns;
    }

    #calculateMenuGridColumns(outerMarginWidth, menuColumnWidth, menuItemsPlacement) {
        if(document.body.getBoundingClientRect().width <= 768) return `0px 1fr ${this.#pixelsToRem(this.scrollbarWidth)}`;
        if(menuItemsPlacement === 'left') {
            return !outerMarginWidth ? `1fr ${this.#pixelsToRem(menuColumnWidth)} ${this.#pixelsToRem(this.scrollbarWidth)}`: `${this.#pixelsToRem(outerMarginWidth)} ${this.#pixelsToRem(menuColumnWidth)} 1fr`;
        }
        if(menuItemsPlacement === 'right') {
            return !outerMarginWidth ? `${this.#pixelsToRem(menuColumnWidth)} 1fr` : `1fr ${this.#pixelsToRem(menuColumnWidth)} ${this.#pixelsToRem(outerMarginWidth + this.scrollbarWidth)}`;
        }
    }

    #openMenu() {
        this.menuHeader.classList.add('open');
        this.menu.classList.add('open');
        this.menu.showModal();
        document.dispatchEvent(new CustomEvent('bf-menu-toggle', {detail: {open: true}}));
        this.#handleScrollBar(true);
        this.setAttribute('menu-open', true);
    }
    #closeMenu() {
        this.menuHeader.classList.remove('open');
        this.menu.classList.remove('open');
        this.menu.close();
        document.dispatchEvent(new CustomEvent('bf-menu-toggle', {detail: {open: false}}));
        this.#handleScrollBar(false);
        this.setAttribute('menu-open', false);
    }

    #handleScrollBar(menuOpen) {
        if(
            document.documentElement.scrollHeight > document.documentElement.clientHeight ||
            !menuOpen
        ) {
            document.documentElement.style = `overflow: ${menuOpen ? 'hidden' : 'initial'};`;
            // prevents the page from moving sideways when scollbar on documentElement is removed
            document.body.style= `overflow-y: ${menuOpen ? 'scroll' : 'initial'};`;
        }
    }

    #pixelsToRem(px) {
        if(!px.toString().endsWith('px') && !/\d$/.test(px)) return px;
        // check that getComputedStyle is available for NextJs
        const fontSize = getComputedStyle && getComputedStyle(document.documentElement).getPropertyValue('font-size');
        const baseFontSize = parseFloat(fontSize);

        if (typeof px === 'string' || px instanceof String)
        px = px.replace('px', '');

        px = parseInt(px);
        return (1 / baseFontSize) * px + 'rem';
    }
}

if(!customElements.get('bf-menu')) customElements.define('bf-menu', BfMenu);
