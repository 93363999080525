import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'

const LIST_CATEGORY_LOADING = 'LIST_CATEGORY_LOADING'
const LIST_CATEGORY_SUCCESS = 'LIST_CATEGORY_SUCCESS'
const LIST_CATEGORY_ERROR = 'LIST_CATEGORY_ERROR'

/* ACTIONS */
const getCategories = () => (dispatch) => {
  dispatch({
    type: LIST_CATEGORY_LOADING
  });

  httpClient(`categories`)
    .then(result => {
      dispatch({
        type: LIST_CATEGORY_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: LIST_CATEGORY_ERROR,
        error,
        payload: error
      });
    })
}

/* HOOKS */
export const useCategories = () => {
  const dispatch = useDispatch();
  const { networkState, rows } = useSelector(state => state.categories);

  useEffect(() => {
    if (networkState === WAITING) {
      dispatch(getCategories())
    }
  }, [networkState, dispatch]);

  return [rows, networkState];
};

/* REDUCER */
const initialState = {
  rows: [],
  networkState: WAITING,
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_CATEGORY_LOADING:
      return {
        ...state,
        networkState: LOADING
      }
    case LIST_CATEGORY_ERROR:
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    case LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        rows: action.payload,
        networkState: SUCCESS,
      }

    default:
      return state
  }
}
