import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';

import "components/select-input/_select-input.scss"

const CLASS_NS = "select-input"

function SelectInput({
  classNs = CLASS_NS,
  options = [],
  disabled = false,
  required = false,
  placeholder = null,
  label,
  empty = null,
  noborder = false,
  dashedborder = false,
  name = "select_input",
  value,
  onSelect = () => { },
  optionTemplate = (item) => item?.label ?? '',
}) {
  const [internalValue, setInternalValue] = useState('')
  useEffect(() => {
    if (typeof value === 'undefined') {
      setInternalValue('')
    } else if (value === null) {
      setInternalValue('')
    } else {
      setInternalValue(value)
    }
  }, [value])
  const onChange = (event) => {
    const _value = event.target.value
    const selected = options.filter(o => `${o.value}` === _value).pop()
    if (selected) {
      onSelect(selected.value, event)
      // setInternalValue(selected.value)
    } else if (empty !== null) {
      onSelect(null, event)
      // setInternalValue('')
    } else {
      onSelect(_value, event)
      // setInternalValue(value)
    }
  }

  return (
    <>
      {label &&
        <label htmlFor={name}>{label}</label>
      }
      <div className={`${classNs}`}>
        <div className={`${classNs}__wrapper`} onClick={() => { }}>
          <select
            name={name}
            disabled={disabled}
            required={required}
            value={internalValue}
            className={`${classNs}__wrapper__select 
              ${noborder ? `${classNs}__wrapper__select__noborder` : ''}
              ${dashedborder ? `${classNs}__wrapper__select__dashedborder` : ''}
            `}
            onChange={onChange}
          >
            {placeholder &&
              <option value="" disabled>{placeholder}</option>
            }
            {empty &&
              <option value="">{empty}</option>
            }
            {
              options.map((item, index) => (
                <option
                  key={index}
                  className={`${classNs}__wrapper__select__option`}
                  value={item.value}
                >
                  {optionTemplate({ ...item })}
                </option>
              ))
            }
          </select>
          <div className={`${classNs}__wrapper__icon`}>
            <ChevronDown />
          </div>
        </div>
      </div>
    </>
  );
}

SelectInput.propTypes = {
  classNs: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  optionTemplate: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string
}
export default SelectInput;
