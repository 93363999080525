import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TabBar from "components/tab-bar/tab-bar"

import { useForecast } from "store/forecast"
import { useConstant } from 'store/constants'
import { useForecastSizes } from "store/forecast_sizes"
import { useForecastRatings } from "store/forecast_ratings"

import ForecastTabBar from "routes/forecast/tab-bar"
import Page from "components/page/page";
import Ratingmap from "./ratingmap";
import DelayDistributionTable from "./delaydistribution_table";
import ValueDistributionTable from "./valuedistribution_table";
import CategoryValueDistributionTable from './category_valuedistribution_table'

import './weighting.scss'

export const DataContext = React.createContext({});

const ForecastWeighting = ({ tab, forecastId }) => {

  const [forecast] = useForecast(forecastId)
  
  const [{ progresses }] = useConstant();
  const progressesMap = progresses.reduce((p, c) => { p[c.id] = c.name; return p }, {})
  const progressesOptions = progresses.map(r => ({label: `${r.id} : ${r.name}`, value: r.id}))
  
  const [sizes] = useForecastSizes(1)
  const sizesMap = sizes.reduce((p, c) => {
      p[c.id] = c.name
      return p
  }, {})
  const sizeOptions = sizes.map(r => ({label: `${r.id} : ${r.name}`, value: r.id}))
  const [ratings] = useForecastRatings(forecastId)
  const ratingsMap = ratings.reduce((p, c) => {
      p[c.ratingId] = c
      return p
  }, {})
  const ratingsOptions = ratings.map(r => ({label: `${r.ratingId} : ${r.name}`, value: r.ratingId}))

  return (
    <DataContext.Provider value={{ratingsMap, sizesMap, progressesMap, progressesOptions, ratingsOptions, sizeOptions}}>
      <Page className="project__weighting__page">
        <Page.Header title={forecast.name} >
          <ForecastTabBar forecastId={forecastId} />
        </Page.Header>
        <Page.SubMenu choices={[
          {value: `/forecast/${forecastId}/weighting/ratingmap`,label: "Starttid före datahämtning"},
          {value: `/forecast/${forecastId}/weighting/ratingmap_past`,label: "Starttid efter datahämtning"},
          {value: `/forecast/${forecastId}/weighting/distribution`,label: "Fördelning"},
        ]} />
        <Page.Grid columns={2} title="" className="project__weighting__page__table">
          <Page.Block span={2}>
              { tab === 'ratingmap_past' ? <Ratingmap forecastId={forecastId} present={false}/> :
                tab === 'distribution' ? <>
                  <CategoryValueDistributionTable forecastId={forecastId}/>
                  <ValueDistributionTable forecastId={forecastId}/>
                  <DelayDistributionTable forecastId={forecastId}/>
                </> :
                <Ratingmap forecastId={forecastId} present={true}/>
                }

          </Page.Block>
        </Page.Grid>
      </Page>
    </DataContext.Provider>
  )
}

ForecastWeighting.propTypes = {
  match: PropTypes.object
}
export default ForecastWeighting
