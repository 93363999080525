import React from 'react'
import { Link } from 'react-router-dom'

import "components/track-bar/_track-bar.scss"

const classNs = "trackbar"

/**
 * Displays a list of routes which the user has navigated to
 * @returns {*} A React component
 */
const TrackBar = ({
  title = ""
}) => {
  return (
    <>
      <nav className={`${classNs}`}>
        <ul className={`${classNs}__list`}>
          <li className={`${classNs}__item`}>
            <Link to="/uppdateringar">Uppdateringar &nbsp;</Link>
          </li>
          <li className={`${classNs}__item`}>
            <span>/ {title}</span>            
          </li>
        </ul>
      </nav>
    </>
  )
}

TrackBar.propTypes = {

}
export default TrackBar
