import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'


import { ReactComponent as PlusIcon } from "assets/icons/plus.svg"
import { Link } from "react-router-dom"
import { sortModArray } from "utils/data-helpers"
import "components/definition-listing/_definition-listing.scss"

const CLASS_NS = "definition-listing";

const Wrapper = ({ expandable, className, children }) => {
  if (expandable) {
    return <div className={className}>{children}</div>
  }
  return <>{children}</>
}
const DefinitionListing = ({
  classNs = CLASS_NS,
  items = [],
  windowWidth
}) => {
  const [activeId, setActiveId] = useState();
  const [columns, setColumns] = useState(4);

  const closeMenu = () => {
    setActiveId(null);
  };

  const onToggleHandler = (id) => () => {
    setActiveId(id === activeId ? null : id)
  }
  useEffect(() => {
    let _columns = 4;
    if (windowWidth < 660) {
      _columns = 1
    } else if (windowWidth < 880) {
      _columns = 2
    } else if (windowWidth < 1040) {
      _columns = 3
    } else {
      _columns = 4
    }
    setColumns(_columns)
  }, [windowWidth])

  const sortedItems = sortModArray(items, columns)

  return (
    <dl className={`${classNs} ${classNs}--grid-${columns}`}>
      {sortedItems.map((item, index) => {
        if (typeof item === 'undefined') return null
        const children = item.children.length > 0 ? item.children : false
        const parentClass = children ? `${classNs}__header ${classNs}__header-highlight` : `${classNs}__header`

        return (
          <Wrapper expandable={!!children} className={`${classNs}__block`} key={index}>
            <dt key={`header-${index}`} className={parentClass} data-active={activeId === item.id} onClick={onToggleHandler(item.id)} >
              {!children ? <Link to={`/prognoser/${item.id}/alla`}>{item.title}</Link> :
                <span>{item.title}</span>}
              {children ?
                <span
                  className={`${classNs}__header-icon`}
                  onClick={onToggleHandler(item.id)}
                ><PlusIcon /></span> :
                null
              }
            </dt>
            {
              children ?
                <div className={`${classNs}__details`} key={`details-${index}`}>
                  <dd className={`${classNs}__detail`}>
                    <Link to={`/prognoser/${item.id}/alla`} onClick={closeMenu}>Hela {item.title}</Link>
                  </dd>
                  {
                    children.map((child, idx) => <dd key={idx} className={`${classNs}__detail`}>
                      <Link to={`/prognoser/${child.id}/alla`} onClick={closeMenu}>{child.title}</Link>
                    </dd>)
                  }
                </div> :
                null
            }
          </Wrapper>
        )
      }
      )}
    </dl>
  )
}

DefinitionListing.propTypes = {
  classNs: PropTypes.string,
  items: PropTypes.array,
}
export default DefinitionListing
