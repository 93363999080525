import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'
import { uiStartSpinner, uiStopSpinner } from 'store/ui_spinner'
import { uiStartChecked } from 'store/ui_checked'

const GET_FORECAST_PROJECTS_LOADING = 'GET_FORECAST_PROJECTS_LOADING'
const GET_FORECAST_PROJECTS_ERROR = 'GET_FORECAST_PROJECTS_ERROR'
const GET_FORECAST_PROJECTS_SUCCESS = 'GET_FORECAST_PROJECTS_SUCCESS'

const GET_FORECAST_PROJECT_LOADING = 'GET_FORECAST_PROJECT_LOADING'
const GET_FORECAST_PROJECT_SUCCESS = 'GET_FORECAST_PROJECT_SUCCESS'
const GET_FORECAST_PROJECT_ERROR = 'GET_FORECAST_PROJECT_ERROR'

const UPDATE_FORECAST_PROJECT_LOADING = 'UPDATE_FORECAST_PROJECT_LOADING'
const UPDATE_FORECAST_PROJECT_SUCCESS = 'UPDATE_FORECAST_PROJECT_SUCCESS'
const UPDATE_FORECAST_PROJECT_ERROR = 'UPDATE_FORECAST_PROJECT_ERROR'

const DELETE_FORECAST_PROJECT_LOADING = 'DELETE_FORECAST_PROJECT_LOADING'
const DELETE_FORECAST_PROJECT_SUCCESS = 'DELETE_FORECAST_PROJECT_SUCCESS'
const DELETE_FORECAST_PROJECT_ERROR = 'DELETE_FORECAST_PROJECT_ERROR'

const RATE_FORECAST_PROJECT_LOADING = 'RATE_FORECAST_PROJECT_LOADING'
const RATE_FORECAST_PROJECT_SUCCESS = 'RATE_FORECAST_PROJECT_SUCCESS'
const RATE_FORECAST_PROJECT_ERROR = 'RATE_FORECAST_PROJECT_ERROR'

const REVIEWED_FORECAST_PROJECT_LOADING = 'REVIEWED_FORECAST_PROJECT_LOADING'
const REVIEWED_FORECAST_PROJECT_SUCCESS = 'REVIEWED_FORECAST_PROJECT_SUCCESS'
const REVIEWED_FORECAST_PROJECT_ERROR = 'REVIEWED_FORECAST_PROJECT_ERROR'

const ASSIGN_FORECAST_PROJECT_LOADING = 'ASSIGN_FORECAST_PROJECT_LOADING'
const ASSIGN_FORECAST_PROJECT_SUCCESS = 'ASSIGN_FORECAST_PROJECT_SUCCESS'
const ASSIGN_FORECAST_PROJECT_ERROR = 'ASSIGN_FORECAST_PROJECT_ERROR'

const ADD_FORECAST_PROJECT_LOADING = 'ADD_FORECAST_PROJECT_LOADING'
const ADD_FORECAST_PROJECT_SUCCESS = 'ADD_FORECAST_PROJECT_SUCCESS'
const ADD_FORECAST_PROJECT_ERROR = 'ADD_FORECAST_PROJECT_ERROR'

/* ACTIONS */
const getForecastProject = ({forecastId, projectId}) => (dispatch) => {
  dispatch({
    type: GET_FORECAST_PROJECT_LOADING
  });

  httpClient(`forecasts/${forecastId}/projects/${projectId}`)
    .then(result => {
      const { project, project_values } = result;
      dispatch({
        type: GET_FORECAST_PROJECT_SUCCESS,
        payload: { ...project, values: project_values }
      });
    })
    .catch(error => {
      dispatch({
        type: GET_FORECAST_PROJECT_ERROR,
        error,
        payload: error
      });
    })
}
export const updateForecastProject = ({forecastId, projectId, ...data}) => (dispatch) => {
  dispatch({
    type: UPDATE_FORECAST_PROJECT_LOADING
  });
  dispatch(uiStartSpinner());
  httpClient(`forecasts/${forecastId}/projects/${projectId}`, { data, method: 'POST'})
    .then(result => {
      dispatch({
        type: UPDATE_FORECAST_PROJECT_SUCCESS,
        payload: result
      });
      dispatch(uiStopSpinner());
      dispatch(getForecastProject({forecastId, projectId}));
    })
    .catch(error => {
      dispatch({
        type: UPDATE_FORECAST_PROJECT_ERROR,
        error,
        payload: error
      });
      dispatch(uiStopSpinner());

    })
}

export const rateForecastProject = ({forecastId, projectId, ...data}) => (dispatch) => {
  dispatch({
    type: RATE_FORECAST_PROJECT_LOADING
  });

  httpClient(`forecasts/${forecastId}/projects/${projectId}/rate`, { data, method: 'POST'})
    .then(result => {
      dispatch({
        type: RATE_FORECAST_PROJECT_SUCCESS,
        payload: result
      });
      dispatch(getForecastProject({forecastId, projectId}));
    })
    .catch(error => {
      dispatch({
        type: RATE_FORECAST_PROJECT_ERROR,
        error,
        payload: error
      });
    })
}
export const reviewedForecastProject = ({forecastId, projectId, ...data}) => (dispatch) => {
  dispatch({
    type: REVIEWED_FORECAST_PROJECT_LOADING
  });

  httpClient(`forecasts/${forecastId}/projects/${projectId}/reviewed`, { data, method: 'POST'})
    .then(result => {
      dispatch({
        type: REVIEWED_FORECAST_PROJECT_SUCCESS,
        payload: result
      });
      dispatch(getForecastProject({forecastId, projectId}))
      dispatch(uiStartChecked())
    })
    .catch(error => {
      dispatch({
        type: REVIEWED_FORECAST_PROJECT_ERROR,
        error,
        payload: error
      });
    })
}
export const assignForecastProject = ({forecastId, projectId, ...data}) => (dispatch) => {
  dispatch({
    type: ASSIGN_FORECAST_PROJECT_LOADING
  });

  httpClient(`forecasts/${forecastId}/projects/${projectId}/assign`, { data, method: 'POST'})
    .then(result => {
      dispatch({
        type: ASSIGN_FORECAST_PROJECT_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: ASSIGN_FORECAST_PROJECT_ERROR,
        error,
        payload: error
      });
    })
}

export const addProject = ({forecastId, ...data}) => (dispatch) => {
  dispatch({
    type: ADD_FORECAST_PROJECT_LOADING
  });

  httpClient(`forecasts/${forecastId}/projects`, { data, method: 'POST' })
    .then(result => {
      dispatch({
        type: ADD_FORECAST_PROJECT_SUCCESS,
        payload: result
      });
      window.location.href = `/forecast/${forecastId}/projects/${result}`
    })
    .catch(error => {
      dispatch({
        type: ADD_FORECAST_PROJECT_ERROR,
        error,
        payload: error
      });
    })
}

export const deleteForecastProject = ({forecastId, projectId}) => (dispatch) => {
  dispatch({
    type: DELETE_FORECAST_PROJECT_LOADING
  });
  dispatch(uiStartSpinner());
  httpClient(`forecasts/${forecastId}/projects/${projectId}`, { method: 'DELETE'})
    .then(result => {
      dispatch({
        type: DELETE_FORECAST_PROJECT_SUCCESS,
        payload: result
      });
      dispatch(uiStopSpinner());
      window.location.href = `/forecast/${forecastId}/projects`

    })
    .catch(error => {
      dispatch({
        type: DELETE_FORECAST_PROJECT_ERROR,
        error,
        payload: error
      });
      dispatch(uiStopSpinner());

    })
}
/* HOOKS */

export const useForecastProject = (forecastId, projectId) => {
  const dispatch = useDispatch();
  const { networkState, ...project } = useSelector((state) => state.forecast_project);
  const oldProjectId = project.id
  useEffect(() => {
    dispatch(getForecastProject({forecastId, projectId}));
  }, [forecastId, projectId, oldProjectId]);
  return [project, networkState];
};

/* REDUCER */
const initialState = {
  // _cache: {},
  networkState: WAITING,
  error: null
}

export default function (state = initialState, action) {

  switch (action.type) {


    case GET_FORECAST_PROJECTS_LOADING:
      return {
        ...state,
      }
    case GET_FORECAST_PROJECTS_ERROR:
      return {
        ...state,
        // _cache: {}
      }
    case GET_FORECAST_PROJECTS_SUCCESS:
      // const _cache = action.payload.rows.reduce((obj, item) => (obj[item.projectId] = item, obj) ,{});
      return {
        ...state,
        // _cache: {
        // ...state._cache,
        // ..._cache,
        // }
      }

    case GET_FORECAST_PROJECT_LOADING: {
      // // const {projectId} = action.params        
      // // //   const _cache = state._cache[projectId] || {}
      return {
        ...state,
        // // ..._cache,
        networkState: LOADING
      }
    }
    case GET_FORECAST_PROJECT_SUCCESS:{
      // const {projectId} = action.params
      return {
        // ...state._cache[projectId],
        ...state,
        ...action.payload,
        networkState: SUCCESS
      }
    }
    case GET_FORECAST_PROJECT_ERROR:{
      // const {projectId} = action.params
      return {
        ...state,
        error: action.payload,
        networkState: ERROR
      }   
    }   
    default:
      return state
  }
}
