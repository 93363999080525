import React from 'react'
import Header from 'components/header/header';

// 
import "layouts/_login.layout.scss"

const LoginLayout = ({children}) => {
  return (
    <>
      <Header />
      <main className="main-login">
        {children}
      </main>
      {/*<Footer />*/}
    </>
  )
}

export default LoginLayout
