import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';

// CSS Modules, react-datepicker-cssmodules.css
import "react-datepicker/dist/react-datepicker.css";
import "components/date-picker/_date-picker.scss"

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

registerLocale('sv', sv)

const defaultPlaceholder = "Ange datum";
const CLASS_NS = "date-picker";

function FormDatePicker({
  classNs=CLASS_NS,
  placeholderText=defaultPlaceholder,
  onChange=()=>{},
  autoComplete="off",
  name="",
  selected = ""
}) {
  const [ selectedState, setSelectedState ] = useState(selected)
  useEffect(() => {
    setSelectedState(selected)
  }, [selected])

  const onSelect = (date) => { setSelectedState(date)};

  return (
    <div className={`${classNs}`}>
      <DatePicker
        autoComplete={autoComplete}
        className={`${classNs}__calendar`}
        placeholderText={placeholderText}
        dateFormat="d MMM yyyy"
        locale="sv"
        selected={selectedState}
        onChange={onChange}
        onSelect={onSelect}
        name={name}
      />
      <CalendarIcon className={`${classNs}__icon`}/>
    </div>
  );
}

FormDatePicker.propTypes = {
  classNs:PropTypes.string,
  onSelect:PropTypes.func,
  selected:PropTypes.object,
  placeholderText:PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
}

export default FormDatePicker
