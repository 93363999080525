import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from "react-router-dom";


import { Link } from "react-router-dom";
import dialogPolyfill from 'dialog-polyfill';

import {ReactComponent as CrossIcon} from "assets/icons/cross.svg"

const CLASS_NS = "modal"
/*
 * Displays an modal which can be dismissed. Uses native dialog component.
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog
 */
const Modal = ({
  classNs=CLASS_NS,
  children,
  // onClick=e=>{document.querySelector('dialog').close()},
  isOpen=true,
  closeButton=true,
  closeUrl="",
  onClose
}) => {

  const history = useHistory();
  const [isOpenLocal, setIsOpenLocal] = useState(isOpen)
  const dialogNode = useRef();

  useEffect(() => {
    setIsOpenLocal(isOpen)
  }, [isOpen])

  useEffect(()=>{
    // init
    // const dialog = document.querySelector('dialog');
    dialogPolyfill.registerDialog(dialogNode.current);


    // cleanup
    return ()=>{}

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Effect hook for the isOpen parameter. NB! this is not a state variable.
  useEffect(()=>{
    // The native dialog element places a "open" attribute on iteself when the
    // showModal() is triggered. Likewise it removes it when the close() method is triggered.
    if (isOpenLocal && !dialogNode.current.open) {
      // Open the dialog so that elements behind it cannot be interacted with.
      dialogNode.current.showModal();
    } else {
      if (dialogNode.current.open) {
        dialogNode.current.close()
      }
    }

  }, [isOpenLocal])// eslint-disable-line react-hooks/exhaustive-deps

  const onCloseInternal = ()=>{
    // isOpen = false;
    if (onClose) {
      onClose()
    }
    setIsOpenLocal(false)
    if (closeUrl) {
      history.push(closeUrl)
    }
  }
  const CloseButtonNodeType = closeUrl ? Link : "button";

  return (
    <dialog
      className={`${classNs}`}
      role="dialog"
      ref={dialogNode}
      onClose={onCloseInternal}
    >
      {
        closeButton ? <CloseButtonNodeType
          className={`${classNs}__close-button`}
          onClick={onCloseInternal}
          to={closeUrl}
        ><CrossIcon /></CloseButtonNodeType> :
          null
      }
      {children}
    </dialog>
  )
}

export default Modal
