export const rangeTo = (start, length) => Array(length).fill(start).map((x, y) => x - y).reverse()

export const krToMkr = (value) => {
  if (isNaN(value)) {
    return null
  }

  if (value < 1_000_000) {
    return 0
  }

  const mvalue = value / 1_000_000
  if (mvalue === Math.round(mvalue)) {
    return mvalue.toFixed(0)
  }
  return mvalue.toFixed(1)
}

export const formatKr = (value) => {
  if (isNaN(value)) {
    return null
  }
  var nf = new Intl.NumberFormat('sv-SE');

  if (value < 1_000_000) {
    return [nf.format(value), 'kr']
  }

  if (true || value < 1_000_000_000) {
    const v = Math.round(value / 1_000_000)
    return [nf.format(v), 'mkr']
  }

  const v = Math.round(value / 1000_000_000)
  return [nf.format(v), 'mdkr']

}

export const debounce = (callback, delay = 250) => {
  let timeoutId = null
  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      timeoutId = null
      callback(...args)
    }, delay)
  }
}


// TODO Handle this in Sanity ?
export const categoryIdToLabel = (id) => {
  const map = {
    lokaler: 'Lokaler',
    anlaggningar: 'Anläggningar',
    bostader: 'Bostäder',
    alla: 'Alla',
  }
  return map[id] || 'Alla'
}

// TODO Handle this in Sanity ?
export const categoryLabelToId = (label) => {
  const map = {
    'Lokaler': 'lokaler',
    'Anläggningar': 'anlaggningar',
    'Bostäder': 'bostader',
    'Alla': 'alla',
  }
  return map[label] || 'alla'
}

export const transpose = (array) => {
  const result = array[0].map((_, colIndex) => array.map(row => row[colIndex]));
  return result
}

export const sortArrayColumns = (array, columns) => {

  const parts = []
  const length = array.length
  for (var i = 0; i < columns; i++) {
    parts.push(Math.ceil((length - i) / columns))
  }
  // const s = parts.reduce((s, n) => s + n, 0)
  const a = [...array]
  const result = []
  for (var c = 0; c < columns; c++) {
    for (var r = 0; r < parts[c]; r++) {
      if (!result[c]) {
        result[c] = []
      }
      result[c][r] = a.shift()
    }
  }

  return result
}

export const sortModArray = (array = [], columns) => {
  const parts = sortArrayColumns(array, columns)
  const result = transpose(parts).flat()
  return result.flat()
}

export const normalizeValues = (val, max, min) => {
  return (val - min) / (max - min)
}

