import { useSelector } from "react-redux";
import * as types from "store/_types";
const initialState = {
  isAdmin: false,
}


export const useUserProfile = () => {
  const { user, isLoadingUser } = useSelector(state => state.oidc);
  const { isAdmin, isAdminGroup } = useSelector(state => state.profile);
  const loggedin = !isLoadingUser && user && user.id_token ? true : false
  const profile = loggedin ? user.profile : {}
  const {name, preferred_username: email } = profile;
  return [{loggedin, name, email, isAdmin, isAdminGroup, isLoadingUser}];
};


export default function (state = initialState, action) {

  switch (action.type) {
    case 'redux-oidc/USER_FOUND':
      const {profile} = action.payload
      if (profile['https://sverigesbyggindustrier.se/ad_group']) {
        // const adminGroup = profile['https://sverigesbyggindustrier.se/ad_group'].filter(g => g === env.AD_GROUP_SUPERUSER) 
        const isAdminOverride = sessionStorage.getItem('isAdmin') !== 'false'
        const isAdmin = isAdminOverride // adminGroup.length > 0
        const isAdminGroup = true // adminGroup.length > 0

        // const userGroup = profile['https://sverigesbyggindustrier.se/ad_group'].filter(g => g === env.AD_GROUP_USER) 
        const isUser = true // userGroup.length > 0
        const isUserGroup = true // userGroup.length > 0

        return {
          ...state,
          isAdmin: isAdmin,
          isAdminGroup: isAdminGroup,
          isUser,
          isUserGroup,
        }
      }
    
      break;
    case types.SET_PROFILE_IS_ADMIN:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
