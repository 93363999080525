import React from 'react'
import PropTypes from 'prop-types'

import './_footer.scss'

const CLASS_NS = "footer"

const Footer = ({
  classNs=CLASS_NS,
  children
}) => {

  return (
    <footer className={`${classNs}`}>
      <address className={`${classNs}__container`}>
        {children}
      </address>
    </footer>
  )
}

Footer.propTypes = {
  classNs: PropTypes.string,
}

export default Footer
