import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'

const GET_FORECAST_SIZES_LOADING = 'GET_FORECAST_SIZES_LOADING'
const GET_FORECAST_SIZES_SUCCESS = 'GET_FORECAST_SIZES_SUCCESS'
const GET_FORECAST_SIZES_ERROR = 'GET_FORECAST_SIZES_ERROR'

const UPDATE_FORECAST_SIZES_LOADING = 'UPDATE_FORECAST_SIZES_LOADING'
const UPDATE_FORECAST_SIZES_SUCCESS = 'UPDATE_FORECAST_SIZES_SUCCESS'
const UPDATE_FORECAST_SIZES_ERROR = 'UPDATE_FORECAST_SIZES_ERROR'

/* ACTIONS */
export const getForecastSizes = ({forecastId}) => (dispatch) => {
  dispatch({
    type: GET_FORECAST_SIZES_LOADING
  });

  httpClient(`forecasts/${forecastId}/sizes`)
    .then(result => {
      dispatch({
        type: GET_FORECAST_SIZES_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: GET_FORECAST_SIZES_ERROR,
        error,
        payload: error
      });
    })
}

export const updateForecastSizes = ({forecastId, id, ...data}) => (dispatch) => {
  dispatch({
    type: UPDATE_FORECAST_SIZES_LOADING
  });

  httpClient(`forecasts/${forecastId}/sizes/${id}`, { data , method: 'POST'})
    .then(result => {
      dispatch({
        type: UPDATE_FORECAST_SIZES_SUCCESS,
        payload: result
      });
      dispatch(getForecastSizes({forecastId}))
    })
    .catch(error => {
      dispatch({
        type: UPDATE_FORECAST_SIZES_ERROR,
        error,
        payload: error
      });
    })
}

/* HOOKS */
export const useForecastSizes = (forecastId) => {

  const dispatch = useDispatch();
  const { rows, networkState } = useSelector(state => state.forecast_sizes);

  useEffect(() => {
    dispatch(getForecastSizes({forecastId}));
  }, [forecastId, dispatch]);
  return [rows, networkState];
};
/* REDUCER */
const initialState = {
  rows: [],
  networkState: WAITING,
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {


    case GET_FORECAST_SIZES_LOADING:
      return {
        ...state,
        networkState: LOADING
      }
    case GET_FORECAST_SIZES_ERROR:
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    case GET_FORECAST_SIZES_SUCCESS:
      return {
        ...state,
        rows: action.payload,
        networkState: SUCCESS,
      }

    default:
      return state
  }
}
