import React from 'react'
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types'

import ForecastTabBar from "routes/forecast/tab-bar"
import SelectInput from "components/select-input/select-input"
import Page from "components/page/page";
import PropTable from 'components/prop-table/prop-table';

import { useForecast, updateForecastStatus, importForecast, deleteForecast } from "store/forecast"
import { useForecastReferences } from "store/forecast_references"
import { useConstant } from 'store/constants'

import { formatIsoDateInterval, formatIsoDate } from 'utils/date-helpers'
import './overview.scss'

// const data = {
//   labels: ['Scatter'],
//   datasets: [
//     {
//       label: 'My First dataset',
//       fill: false,
//       backgroundColor: 'rgba(75,192,192,0.4)',
//       pointBorderColor: 'rgba(75,192,192,1)',
//       pointBackgroundColor: '#fff',
//       pointBorderWidth: 5,
//       pointHoverRadius: 5,
//       pointHoverBackgroundColor: 'rgba(75,192,192,1)',
//       pointHoverBorderColor: 'rgba(220,220,220,1)',
//       pointHoverBorderWidth: 2,
//       pointRadius: 1,
//       pointHitRadius: 10,
//       data: [
//         {x: 25	, y: 1},
//         {x: 4	, y: 3},
//         {x: 1	, y: 3},
//         {x: 1	, y: 3},
//         {x: 2	, y: 3},
//         {x: 2	, y: 4},
//         {x: 10	, y: 4},
//         {x: 2	, y: 5},
//         {x: 30	, y: 5},
//         {x: 30	, y: 6},
//         {x: 4	, y: 6},
//         {x: 22	, y: 6},
//         {x: 18	, y: 6},
//         {x: 30	, y: 6},
//         {x: 8	, y: 7},
//         {x: 4	, y: 7},
//         {x: 65	, y: 8},
//         {x: 35	, y: 10},
//         {x: 30	, y: 10},
//         {x: 15	, y: 11},
//         {x: 30	, y: 11},
//         {x: 20	, y: 12},
//         {x: 30	, y: 12},
//         {x: 30	, y: 12},
//         {x: 15	, y: 13},
//         {x: 15	, y: 13},
//         {x: 100	, y: 13},
//         {x: 25	, y: 15},
//         {x: 35	, y: 15},
//         {x: 30	, y: 15},
//         {x: 70	, y: 15},
//         {x: 250	, y: 16},
//         {x: 6	, y: 16},
//         {x: 60	, y: 16},
//         {x: 45	, y: 16},
//         {x: 170	, y: 16},
//         {x: 70	, y: 18},
//         {x: 30	, y: 18},
//         {x: 86	, y: 18},
//         {x: 130	, y: 19},
//         {x: 70	, y: 21},
//         {x: 50	, y: 21},
//         {x: 125	, y: 22},
//         {x: 35	, y: 24},
//         {x: 70	, y: 24},
//         {x: 70	, y: 24},
//         {x: 275	, y: 26},
//         {x: 200	, y: 26},
//         {x: 21	, y: 30},
//         {x: 700	, y: 31},
//         {x: 225	, y: 33},
//         {x: 185	, y: 34},
//         {x: 260	, y: 42},
//         {x: 215	, y: 48}

//       ]
//     }
//   ]
// };

const ForecastOverview = ({forecastId}) => {

  const dispatch = useDispatch();
  const [forecast, networkState] = useForecast(forecastId);
  const [forecastReferences] = useForecastReferences(forecastId);
  const [{ forecastMethods, forecastStatuses }] = useConstant();

  const handleSetStatus = (status, event) => {
    dispatch(updateForecastStatus({ forecastId, status }))
    // switch (value) {
    //   case 'DRAFT':
    //     dispatch(forecastActions.draftForecastStatus({id: forecastId}));
    //     break;
    //   case 'STARTED':
    //     dispatch(forecastActions.startForecastStatus({id: forecastId}));
    //     break;
    //   case 'COMPLETED':
    //     dispatch(forecastActions.completeForecastStatus({id: forecastId}));
    //     break;
    //   case 'ARCHIVED':
    //     dispatch(forecastActions.archiveForecastStatus({id: forecastId}));
    //     break;
    //   default:
    // }
    event.stopPropagation();
  }

  const handleImport = () => {
    dispatch(importForecast({ forecastId }))
  }

  const handleDelete = () => {
    dispatch(deleteForecast({ forecastId }))
  }

  const options = Object.entries(forecastStatuses).map(([id, label]) => ({ id, label, value: id }))
  return (
    <Page className="project__overview__page">
      <Page.Header title={forecast.name} >
        <ForecastTabBar forecastId={forecastId} />
      </Page.Header>
      <Page.Grid columns={4} className="project__overview__page__table">

        { forecast.status === 'ARCHIVED' ? <Page.Block start={4} span={1}>
            <button disabled={forecastReferences.length > 0} className="button" onClick={handleDelete}>Radera Prognos</button>
          </Page.Block> 
          : 
          <Page.Block start={4} span={1}>
            <button className="button-2" onClick={handleImport}>Importera data från Faktanet.</button>
          </Page.Block>
        }
        <Page.Block start={1} span={2}>
          <PropTable label="Fakta" >
            <PropTable.Row label="Prognostyp">{forecastMethods[forecast.method]}</PropTable.Row>
            <PropTable.Row label="Importintervall">{forecast.projectStartYear} - {forecast.projectEndYear}</PropTable.Row>
            <PropTable.Row label="Prognosintervall">{forecast.forecastStartYear} - {forecast.forecastEndYear}</PropTable.Row>
            <PropTable.Row label="Senaste importen">{formatIsoDate(forecast.lastUpdatedProject)}</PropTable.Row>
            <PropTable.Row label="Antal projekt">{forecast.totalCount}</PropTable.Row>
            <PropTable.Row label="Antal bedömda projekt">{forecast.reviewedCount}</PropTable.Row>
            { forecastReferences ? 
              <PropTable.Row label="Underprognoser">
                {forecastReferences.map(r => (
                  <>
                    <a key={r.forecastId} href={`/forecast/${r.forecastId}/overview`}>
                      {r.name}
                    </a> , 
                  </>
                ))}
              </PropTable.Row>
              :
              null 
            }
          </PropTable>

        </Page.Block>
        <Page.Block span={2}>
          <PropTable label="Prognosarbete" >
            <PropTable.Row label="Status">
              <SelectInput
                placeholder="Ange status"
                onSelect={handleSetStatus}
                name="status"
                noborder
                options={options}
                value={forecast.status}
              />
            </PropTable.Row>
            <PropTable.Row label="Start- och slutdatum">{formatIsoDateInterval(forecast.createdAt, forecast.deadlineDate)}</PropTable.Row>
            <PropTable.Row label="Dagar kvar">{forecast.daysToDeadline}</PropTable.Row>
          </PropTable>
        </Page.Block>
      </Page.Grid>
    </Page>
  )
}
ForecastOverview.propTypes = {
  classNs: PropTypes.string,
}
export default ForecastOverview

