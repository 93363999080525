import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'

const GET_FORECAST_LIST_LOADING = 'GET_FORECAST_LIST_LOADING'
const GET_FORECAST_LIST_SUCCESS = 'GET_FORECAST_LIST_SUCCESS'
const GET_FORECAST_LIST_ERROR = 'GET_FORECAST_LIST_ERROR'


/* ACTIONS */
export const getForecastList = () => (dispatch) => {
  dispatch({
    type: GET_FORECAST_LIST_LOADING
  });

  httpClient(`forecasts`)
    .then(result => {
      dispatch({
        type: GET_FORECAST_LIST_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: GET_FORECAST_LIST_ERROR,
        error,
        payload: error
      });
    })
}
/* HOOKS */

export const useForecastList = () => {

  const dispatch = useDispatch();
  const { rows } = useSelector(state => state.forecast_list);

  useEffect(() => {
    dispatch(getForecastList());
  }, [dispatch]);
  return [rows, dispatch];
};
/* REDUCER */
const initialState = {
  rows: [],
  networkState: WAITING,
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FORECAST_LIST_LOADING:
      return {
        ...state,
        networkState: LOADING
      }
    case GET_FORECAST_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    case GET_FORECAST_LIST_SUCCESS:
      return {
        ...state,
        rows: action.payload,
        networkState: SUCCESS,
      }

    default:
      return state
  }
}
