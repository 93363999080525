// NB! The naming convension here is to use REST-like prefixes get/set/update/remove
// followed by the data name and state LOADING, ERROR, SUCCESS
// Network states used by application
export const WAITING = 'WAITING'
export const LOADING = 'LOADING'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'

// GENERATOR
export const CANCEL_GENERATOR = "CANCEL_GENERATOR";
export const NOT_FOUND = "NOT_FOUND";


// USERS
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const LOGOUT_USER_LOADING = "LOGOUT_USER_LOADING";
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

export const GET_ME_SUCCESS = "GET_ME_SUCCESS";
export const GET_USER_ASSOCIATION_SUCCESS = "GET_USER_ASSOCIATION_SUCCESS";
export const ACCOUNT_CREATED_SUCCESS = "ACCOUNT_CREATED_SUCCESS";
export const ACCOUNT_CREATED_REDIRECTED = "ACCOUNT_CREATED_REDIRECTED";
export const CLEAR_USER = "CLEAR_USER";

// CATEGORY
export const LIST_CATEGORY_LOADING = 'LIST_CATEGORY_LOADING'
export const LIST_CATEGORY_SUCCESS = 'LIST_CATEGORY_SUCCESS'
export const LIST_CATEGORY_ERROR = 'LIST_CATEGORY_ERROR'

// PROGRESS
export const LIST_PROGRESS_LOADING = 'LIST_PROGRESS_LOADING'
export const LIST_PROGRESS_ERROR = 'LIST_PROGRESS_ERROR'
export const LIST_PROGRESS_SUCCESS = 'LIST_PROGRESS_SUCCESS'

// Region
export const GET_REGION_LIST_LOADING = 'GET_REGION_LIST_LOADING'
export const GET_REGION_LIST_SUCCESS = 'GET_REGION_LIST_SUCCESS'
export const GET_REGION_LIST_ERROR = 'GET_REGION_LIST_ERROR'

// CONFIG
export const GET_CONSTANT_LOADING = 'GET_CONSTANT_LOADING'
export const GET_CONSTANT_SUCCESS = 'GET_CONSTANT_SUCCESS'
export const GET_CONSTANT_ERROR = 'GET_CONSTANT_ERROR'

// CONTRACTORS
export const GET_CONTRACTORS_LOADING = 'GET_CONTRACTORS_LOADING'
export const GET_CONTRACTORS_SUCCESS = 'GET_CONTRACTORS_SUCCESS'
export const GET_CONTRACTORS_ERROR = 'GET_CONTRACTORS_ERROR'

// FORECAST
export const GET_FORECAST_LIST_LOADING = 'GET_FORECAST_LIST_LOADING'
export const GET_FORECAST_LIST_SUCCESS = 'GET_FORECAST_LIST_SUCCESS'
export const GET_FORECAST_LIST_ERROR = 'GET_FORECAST_LIST_ERROR'

export const ADD_FORECAST_LOADING = 'ADD_FORECAST_LOADING'
export const ADD_FORECAST_SUCCESS = 'ADD_FORECAST_SUCCESS'
export const ADD_FORECAST_ERROR = 'ADD_FORECAST_ERROR'

export const GET_FORECAST_LOADING = 'GET_FORECAST_LOADING'
export const GET_FORECAST_SUCCESS = 'GET_FORECAST_SUCCESS'
export const GET_FORECAST_ERROR = 'GET_FORECAST_ERROR'

export const GET_FORECAST_PROJECTS_LOADING = 'GET_FORECAST_PROJECTS_LOADING'
export const GET_FORECAST_PROJECTS_SUCCESS = 'GET_FORECAST_PROJECTS_SUCCESS'
export const GET_FORECAST_PROJECTS_ERROR = 'GET_FORECAST_PROJECTS_ERROR'

export const GET_FORECAST_PROJECT_LOADING = 'GET_FORECAST_PROJECT_LOADING'
export const GET_FORECAST_PROJECT_SUCCESS = 'GET_FORECAST_PROJECT_SUCCESS'
export const GET_FORECAST_PROJECT_ERROR = 'GET_FORECAST_PROJECT_ERROR'

export const GET_FORECAST_STATS_LOADING = 'GET_FORECAST_STATS_LOADING'
export const GET_FORECAST_STATS_ERROR = 'GET_FORECAST_STATS_ERROR'
export const GET_FORECAST_STATS_SUCCESS = 'GET_FORECAST_STATS_SUCCESS'

export const RATE_FORECAST_PROJECT_LOADING = 'RATE_FORECAST_PROJECT_LOADING'
export const RATE_FORECAST_PROJECT_SUCCESS = 'RATE_FORECAST_PROJECT_SUCCESS'
export const RATE_FORECAST_PROJECT_ERROR = 'RATE_FORECAST_PROJECT_ERROR'

export const ASSIGN_FORECAST_PROJECT_LOADING = 'ASSIGN_FORECAST_PROJECT_LOADING'
export const ASSIGN_FORECAST_PROJECT_SUCCESS = 'ASSIGN_FORECAST_PROJECT_SUCCESS'
export const ASSIGN_FORECAST_PROJECT_ERROR = 'ASSIGN_FORECAST_PROJECT_ERROR'

export const REVIEWED_FORECAST_PROJECT_LOADING = 'REVIEWED_FORECAST_PROJECT_LOADING'
export const REVIEWED_FORECAST_PROJECT_SUCCESS = 'REVIEWED_FORECAST_PROJECT_SUCCESS'
export const REVIEWED_FORECAST_PROJECT_ERROR = 'REVIEWED_FORECAST_PROJECT_ERROR'

export const GET_PROJECT_LOADING = 'GET_PROJECT_LOADING'
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS'
export const GET_PROJECT_ERROR = 'GET_PROJECT_ERROR'

export const LIST_PROJECT_NOTE_LOADING = 'LIST_PROJECT_NOTE_LOADING'
export const LIST_PROJECT_NOTE_ERROR = 'LIST_PROJECT_NOTE_ERROR'
export const LIST_PROJECT_NOTE_SUCCESS = 'LIST_PROJECT_NOTE_SUCCESS'

export const ADD_PROJECT_NOTE_LOADING = 'ADD_PROJECT_NOTE_LOADING'
export const ADD_PROJECT_NOTE_ERROR = 'ADD_PROJECT_NOTE_ERROR'
export const ADD_PROJECT_NOTE_SUCCESS = 'ADD_PROJECT_NOTE_SUCCESS'

export const GET_FORECAST_CATEGORIES_LOADING = 'GET_FORECAST_CATEGORIES_LOADING'
export const GET_FORECAST_CATEGORIES_SUCCESS = 'GET_FORECAST_CATEGORIES_SUCCESS'
export const GET_FORECAST_CATEGORIES_ERROR = 'GET_FORECAST_CATEGORIES_ERROR'

export const GET_FORECAST_MUNICIPALITIES_LOADING = 'GET_FORECAST_MUNICIPALITIES_LOADING'
export const GET_FORECAST_MUNICIPALITIES_SUCCESS = 'GET_FORECAST_MUNICIPALITIES_SUCCESS'
export const GET_FORECAST_MUNICIPALITIES_ERROR = 'GET_FORECAST_MUNICIPALITIES_ERROR'

export const GET_FORECAST_OFFICES_LOADING = 'GET_FORECAST_OFFICES_LOADING'
export const GET_FORECAST_OFFICES_SUCCESS = 'GET_FORECAST_OFFICES_SUCCESS'
export const GET_FORECAST_OFFICES_ERROR = 'GET_FORECAST_OFFICES_ERROR'

export const UPDATE_FORECAST_OFFICES_LOADING = 'UPDATE_FORECAST_OFFICES_LOADING'
export const UPDATE_FORECAST_OFFICES_SUCCESS = 'UPDATE_FORECAST_OFFICES_SUCCESS'
export const UPDATE_FORECAST_OFFICES_ERROR = 'UPDATE_FORECAST_OFFICES_ERROR'

export const GET_FORECAST_RATINGS_LOADING = 'GET_FORECAST_RATINGS_LOADING'
export const GET_FORECAST_RATINGS_SUCCESS = 'GET_FORECAST_RATINGS_SUCCESS'
export const GET_FORECAST_RATINGS_ERROR = 'GET_FORECAST_RATINGS_ERROR'

export const UPDATE_FORECAST_FIELD = "UPDATE_FORECAST_FIELD"
export const UPDATE_PROJECT_FILTER = "UPDATE_PROJECT_FILTER"
export const CLEAR_PROJECT_FILTER = "CLEAR_PROJECT_FILTER"

// TODO: rename/remove
export const PROGNOS_API_FETCHING = `PROGNOS_API_FETCHING`; // FIXME: change to LOADING
export const PROGNOS_API_SUCCESS = `PROGNOS_API_SUCCESS`;
export const PROGNOS_API_ERROR = `PROGNOS_API_ERROR`;

// SEARCH
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const GET_SEARCH_RESULTS_LOADING = 'GET_SEARCH_RESULTS_LOADING'
export const GET_SEARCH_RESULTS_SUCCESS = 'GET_SEARCH_RESULTS_SUCCESS'
export const GET_SEARCH_RESULTS_ERROR = 'GET_SEARCH_RESULTS_ERROR'
export const GET_QUICK_SEARCH_LOADING = 'GET_QUICK_SEARCH_LOADING'
export const GET_QUICK_SEARCH_SUCCESS = 'GET_QUICK_SEARCH_SUCCESS'
export const GET_QUICK_SEARCH_ERROR = 'GET_QUICK_SEARCH_ERROR'
export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER'
export const SET_CONTENT_FIELD_FILTER = 'SET_CONTENT_FIELD_FILTER'
export const SET_SELECTED_RESULT = 'SET_SELECTED_RESULT'
export const RESET_SEARCH = 'RESET_SEARCH'

// PROFILE
export const SET_PROFILE_IS_ADMIN = 'SET_PROFILE_IS_ADMIN'

// LAYOUT

// OFFICES
export const GET_OFFICES_LOADING = 'GET_OFFICES_LOADING'
export const GET_OFFICES_SUCCESS = 'GET_OFFICES_SUCCESS'
export const GET_OFFICES_ERROR = 'GET_OFFICES_ERROR'


// PUBLIC
export const GET_PUBLIC_LOADING = 'GET_PUBLIC_LOADING'
export const GET_PUBLIC_SUCCESS = 'GET_PUBLIC_SUCCESS'
export const GET_PUBLIC_ERROR = 'GET_PUBLIC_ERROR'

// UI
export const UI_START_SPINNER = 'UI_START_SPINNER'
export const UI_STOP_SPINNER = 'UI_STOP_SPINNER'

export const UI_START_CHECKED = 'UI_START_CHECKED'
export const UI_STOP_CHECKED = 'UI_STOP_CHECKED'

export const UI_SHOW_SNACKBAR = 'UI_SHOW_SNACKBAR'
export const UI_CLOSE_SNACKBAR = 'UI_CLOSE_SNACKBAR'

// Sanity
export const SANITY_LOADING = 'SANITY_LOADING'
export const SANITY_SUCCESS = 'SANITY_SUCCESS'
export const SANITY_ERROR = 'SANITY_ERROR'
