import * as types from "store/_types";
// import {ai} from 'store'

/* ACTIONS */
export const uiShowSnackbar = (payload) => ({
  type: types.UI_SHOW_SNACKBAR,
  payload
});

export const uiCloseSnackbar = (icon = null) => ({
  type: types.UI_CLOSE_SNACKBAR,
  payload: {icon}
});

/* REDUCER */
const initialState = {
  show: false,
  icon: "",
  message: ""
}

export default function (state = initialState, action) {
  if (action.type.endsWith('ERROR')) {
    // ai.trackException({ error: new Error(JSON.stringify(action.payload)) })

    return {        
      ...state,
      message: action.payload?.message || action.payload,
      icon: 'error',
      show: true
    }
  }

  switch (action.type) {
    case types.UI_SHOW_SNACKBAR:{
      return {        
        ...state,
        ...action.payload,
        show: true
      }
    }

    case types.UI_CLOSE_SNACKBAR:{      
      const {icon} = action.payload
      if (!icon || icon === state.icon) {
        return {        
          ...initialState
        }
      }
    }

    default:
      return state
  }
}
