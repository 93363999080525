import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { uiCloseSnackbar } from 'store/ui_snackbar'

import './_snackbar.scss'
import { CrossIcon, WifiOffIcon, InfoIcon, ErrorIcon } from 'components/icon'

const Snackbar = () => {

  const dispatch = useDispatch()
  const { show, icon, message } = useSelector(state => state.ui_snackbar)
  if (!show) {
    return null;
  }
  const handleClose = () => {
    dispatch(uiCloseSnackbar())
  }
  var display = typeof message === 'string' ? message : JSON.stringify(message)
  // Too many error object.. revistit this.
  try {
    const json = JSON.parse(display)
    display = JSON.stringify(json, null, 4)
  } catch (err) {
    // 
  }
  if (message?.error?.message) {
    display = message?.error?.message
  }
  else if (message?.error?.Message) {
    display = message?.error?.Message
  }
  else if (message?.error?.error) {
    display = message?.error?.error
  }
  return (
    <div className="snackbar">
      <div className="snackbar__inner">
        <div className="snackbar__inner__icon">
          {icon === 'error' && <ErrorIcon />}
          {icon === 'info' && <InfoIcon />}
          {icon === 'wifi-off' && <WifiOffIcon />}
        </div>
        <div className="snackbar__inner__message">
          <details>
            <summary>Något gick fel. Se detaljer</summary>
            {display}
          </details>
        </div>
        <div className="snackbar__inner__close" onClick={handleClose}>
          <CrossIcon />
        </div>
      </div>
    </div>
  )
}

export default Snackbar
