import React from 'react'

import SanityPage from 'components/sanity-page/sanity-page';
import { useSelector } from "react-redux";

export default () => {

  const { metod: page } = useSelector(state => state.sanity.page);
  if (!page) return null
  return <SanityPage page={page} />
}
