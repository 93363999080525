import dev from './dev'
const {
  REACT_APP_SSO_AUTHORITY: SSO_AUTHORITY,
  REACT_APP_API_BASE: API_BASE,    
  REACT_APP_FUNC_BASE: FUNC_BASE,    
  REACT_APP_DEFAULT_ROUTE: DEFAULT_ROUTE
} = process.env

// Remove empty envs, and add envs to dev object.
Object.entries({ SSO_AUTHORITY, API_BASE, FUNC_BASE, DEFAULT_ROUTE }).filter(([_i, v]) => !!v).forEach(([i, v]) => dev[i] = v)

export default dev

