import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, funcClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'
import { getForecastList } from 'store/forecast_list'
import { uiStartSpinner, uiStopSpinner } from "./ui_spinner";

const GET_FORECAST_LOADING = 'GET_FORECAST_LOADING'
const GET_FORECAST_SUCCESS = 'GET_FORECAST_SUCCESS'
const GET_FORECAST_ERROR = 'GET_FORECAST_ERROR'

const ADD_FORECAST_POLL_LOADING = 'ADD_FORECAST_POLL_LOADING'
const ADD_FORECAST_POLL_SUCCESS = 'ADD_FORECAST_POLL_SUCCESS'
const ADD_FORECAST_POLL_ERROR = 'ADD_FORECAST_POLL_ERROR'
const ADD_FORECAST_FUNC_LOADING = 'ADD_FORECAST_FUNC_LOADING'
const ADD_FORECAST_FUNC_SUCCESS = 'ADD_FORECAST_FUNC_SUCCESS'
const ADD_FORECAST_FUNC_ERROR = 'ADD_FORECAST_FUNC_ERROR'

const DELETE_FORECAST_POLL_LOADING = 'DELETE_FORECAST_POLL_LOADING'
const DELETE_FORECAST_POLL_SUCCESS = 'DELETE_FORECAST_POLL_SUCCESS'
const DELETE_FORECAST_POLL_ERROR = 'DELETE_FORECAST_POLL_ERROR'
const DELETE_FORECAST_FUNC_LOADING = 'DELETE_FORECAST_FUNC_LOADING'
const DELETE_FORECAST_FUNC_SUCCESS = 'DELETE_FORECAST_FUNC_SUCCESS'
const DELETE_FORECAST_FUNC_ERROR = 'DELETE_FORECAST_FUNC_ERROR'

const UPDATE_FORECAST_STATUS_LOADING = 'UPDATE_FORECAST_STATUS_LOADING'
const UPDATE_FORECAST_STATUS_SUCCESS = 'UPDATE_FORECAST_STATUS_SUCCESS'
const UPDATE_FORECAST_STATUS_ERROR = 'UPDATE_FORECAST_STATUS_ERROR'

const IMPORT_FORECAST_POLL_LOADING = 'IMPORT_FORECAST_POLL_LOADING'
const IMPORT_FORECAST_POLL_SUCCESS = 'IMPORT_FORECAST_POLL_SUCCESS'
const IMPORT_FORECAST_POLL_ERROR = 'IMPORT_FORECAST_POLL_ERROR'

const IMPORT_FORECAST_FUNC_LOADING = 'IMPORT_FORECAST_FUNC_LOADING'
const IMPORT_FORECAST_FUNC_SUCCESS = 'IMPORT_FORECAST_FUNC_SUCCESS'
const IMPORT_FORECAST_FUNC_ERROR = 'IMPORT_FORECAST_FUNC_ERROR'

const EXPORT_FORECAST_PROJECTS_LOADING = 'EXPORT_FORECAST_PROJECTS_LOADING'
const EXPORT_FORECAST_PROJECTS_SUCCESS = 'EXPORT_FORECAST_PROJECTS_SUCCESS'
const EXPORT_FORECAST_PROJECTS_ERROR = 'EXPORT_FORECAST_PROJECTS_ERROR'

/* ACTIONS */
const getForecast = ({ forecastId }) => (dispatch) => {
  dispatch({
    type: GET_FORECAST_LOADING
  });

  httpClient(`forecasts/${forecastId}`)
    .then(result => {
      dispatch({
        type: GET_FORECAST_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: GET_FORECAST_ERROR,
        error,
        payload: error
      });
    })
}

export const addForecastPoll = ({ forecastId, url }) => (dispatch) => {

  dispatch({
    type: ADD_FORECAST_POLL_LOADING
  });

  fetch(url)      
    .then(response => response.json())
    .then(json => {
      if (json.runtimeStatus === 'Running') {
        setTimeout(() => {
          dispatch(addForecastPoll({ url }))
        }, 5000)
      } else {        
        dispatch({
          type: ADD_FORECAST_POLL_SUCCESS,
          payload: json
        });
        dispatch(getForecastList());
      }
    })
    .catch(error => {
      dispatch({
        type: ADD_FORECAST_POLL_ERROR,
        error,
        payload: error
      });
    })
  }

export const addForecast = (data) => (dispatch) => {

  dispatch({
    type: ADD_FORECAST_FUNC_LOADING
  });

  const { referenceForecastId } = data
  funcClient(`CreateForecast_HttpStart`, {method: 'POST', data: {...data, token: 'abc112223333' }})      
    .then(result => {
      dispatch({
        type: ADD_FORECAST_FUNC_SUCCESS,
        payload: result
      });
      dispatch(importForecastPoll({ url: result.statusQueryGetUri }))
    })
    .catch(error => {
      dispatch({
        type: ADD_FORECAST_FUNC_ERROR,
        error,
        payload: error
      });
    })
}

// export const addForecast = ( data ) => (dispatch) => {
//   dispatch({
//     type: ADD_FORECAST_LOADING
//   });
// /* xxx*/
//   httpClient(`forecasts/`, { data , method: 'POST' })
//     .then(result => {
//       dispatch({
//         type: ADD_FORECAST_SUCCESS,
//         payload: result
//       });
//       dispatch(getForecastList())
//     })
//     .catch(error => {
//       dispatch({
//         type: ADD_FORECAST_ERROR,
//         error,
//         payload: error
//       });
//     })
// }

export const updateForecastStatus = ({ forecastId, status }) => (dispatch) => {
  dispatch({
    type: UPDATE_FORECAST_STATUS_LOADING
  });

  httpClient(`forecasts/${forecastId}/status`, { data: { status }, method: 'POST' })
    .then(result => {
      dispatch({
        type: UPDATE_FORECAST_STATUS_SUCCESS,
        payload: result
      });
      dispatch(getForecast({ forecastId }))
    })
    .catch(error => {
      dispatch({
        type: UPDATE_FORECAST_STATUS_ERROR,
        error,
        payload: error
      });
    })
}


export const deleteForecastPoll = ({ forecastId, url }) => (dispatch) => {

  dispatch({
    type: DELETE_FORECAST_POLL_LOADING
  });

  fetch(url)      
    .then(response => response.json())
    .then(json => {
      if (json.runtimeStatus === 'Running' || json.runtimeStatus === 'Pending' ) {
        setTimeout(() => {
          dispatch(deleteForecastPoll({ url }))
        }, 5000)
      } else {        
        dispatch(uiStopSpinner());
        dispatch({
          type: DELETE_FORECAST_POLL_SUCCESS,
          payload: json
        });
        dispatch(getForecastList());
        window.location.href = '/forecast/';
      }
    })
    .catch(error => {
      dispatch(uiStopSpinner());
      dispatch({
        type: DELETE_FORECAST_POLL_ERROR,
        error,
        payload: error
      });
    })
  }

export const deleteForecast = (data) => (dispatch) => {

  dispatch({
    type: DELETE_FORECAST_FUNC_LOADING
  });
  dispatch(uiStartSpinner());

  funcClient(`DeleteForecast_HttpStart`, {method: 'POST', data: {...data, token: 'abc112223333' }})      
    .then(result => {
      dispatch({
        type: DELETE_FORECAST_FUNC_SUCCESS,
        payload: result
      });
      dispatch(deleteForecastPoll({ url: result.statusQueryGetUri }))
    })
    .catch(error => {
      dispatch(uiStopSpinner());
      dispatch({
        type: DELETE_FORECAST_FUNC_ERROR,
        error,
        payload: error
      });
    })
}

export const importForecastPoll = ({ forecastId, url }) => (dispatch) => {

  dispatch({
    type: IMPORT_FORECAST_POLL_LOADING
  });

  fetch(url)      
    .then(response => response.json())
    .then(json => {
      if (json.runtimeStatus === 'Running') {
        setTimeout(() => {
          dispatch(importForecastPoll({ forecastId, url }))
        }, 5000)
      } else {        
        dispatch({
          type: IMPORT_FORECAST_POLL_SUCCESS,
          payload: json
        });
        dispatch(getForecastList({ forecastId }));
      }
    })
    .catch(error => {
      dispatch({
        type: IMPORT_FORECAST_POLL_ERROR,
        error,
        payload: error
      });
    })
  }

export const importForecast = ({ forecastId }) => (dispatch) => {

  dispatch({
    type: IMPORT_FORECAST_FUNC_LOADING
  });

  funcClient(`ImportFnProjects_HttpStart`, {data: {forecastId, token: 'abc112223333' }})      
    .then(result => {
      dispatch({
        type: IMPORT_FORECAST_FUNC_SUCCESS,
        payload: result
      });
      dispatch(importForecastPoll({ forecastId, url: result.statusQueryGetUri }))
    })
    .catch(error => {
      dispatch({
        type: IMPORT_FORECAST_FUNC_ERROR,
        error,
        payload: error
      });
    })
}


export const exportForecastProjects = ({ forecastId }) => (dispatch) => {
  dispatch({
    type: EXPORT_FORECAST_PROJECTS_LOADING
  });

  httpClient(`forecasts/${forecastId}/projects/export`, { method: 'POST' })
    .then(result => {
      dispatch({
        type: EXPORT_FORECAST_PROJECTS_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: EXPORT_FORECAST_PROJECTS_ERROR,
        error,
        payload: error
      });
    })
}

/* HOOKS */

export const useForecast = (forecastId) => {

  const dispatch = useDispatch();
  const { networkState, ...forecast } = useSelector((state) => state.forecast);

  useEffect(() => {
    if (forecastId !== forecast?.id) {
      dispatch(getForecast({ forecastId }));
    }
  }, [forecastId, dispatch]);
  return [forecast, networkState];
};

/* REDUCER */
const initialState = {
  networkState: WAITING,
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FORECAST_LOADING:
      return {
        ...state,
        networkState: LOADING
      }
    case GET_FORECAST_ERROR:
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    case GET_FORECAST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        networkState: SUCCESS,
      }

    default:
      return state
  }
}
