import React, { useEffect, useState } from 'react'
import { useForecastRatings } from "store/forecast_ratings"
import { useDispatch } from 'react-redux';

import { useForecastWeightDelayDistribution, updateForecastWeightDelayDistribution } from "store/forecast_weight_delaydistribution"
import DataTable from "components/data-table/data-table";
import InputArray from 'components/input-array/input-array';

const DelayDistributionTable = ({forecastId}) => {
    const dispatch = useDispatch()
    const [ratings] = useForecastRatings(forecastId);
    const [data, setData] = useState([])
    const [forecastWeightDelayDistribution] = useForecastWeightDelayDistribution(forecastId);
    useEffect(() => {
        setData(forecastWeightDelayDistribution)
    }, [forecastWeightDelayDistribution])
    const months = [0, 3, 6, 9, 12, 15, 18, 21] // unique(forecastWeightDelayDistribution.map(d => d.offsetMonth))
    
    ratings.forEach(r => {
        r.value = data.filter( d => d.ratingId === r.ratingId).map(d => d.weight)
    })

    const onChange = (ratingId) => (value) => {
        const newvalue = value.map((v, i) => ({ratingId, offsetMonth: i * 3 , weight: v}))
        const currvalue = data.filter( d => d.ratingId !== ratingId)
        setData([...currvalue, ...newvalue])
    }
    
    const onSave = () => {
        dispatch(updateForecastWeightDelayDistribution({forecastId, data}))
    }
    return (
        <>
            <DataTable 
                bodyData={ratings} 
                footer={<button className="button-2" onClick={onSave}>Spara</button>}
            >
                <DataTable.Column name="name" label="Bedömningsalternativ fördelning per kvartal" 
                    formatter={(row) => <>{row.ratingId} : {row.name}</>} 
                />
                <DataTable.Column name="month" 
                    label={<Labels months={Object.keys(months)} />}
                    formatter={(row) => <InputArray alter={false} value={row.value}  onChange={onChange(row.ratingId)}  /> }
                /> 
            </DataTable>
        </>

    )
}

const Labels = ({months}) => {
    return <div className="" style={{display: 'inline-flex', width: '100%', flexDirection:'row'}}>
        { months?.map( v => (
            <div key={v} style={{flex: 1, justifyContent:'space-between', textAlign: 'center'}}>{v}</div>
        ))}
    </div>
}

export default DelayDistributionTable;