import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from "react-router-dom";

import PropTypes from 'prop-types'

import TabBar from "components/tab-bar/tab-bar"
import { useUserProfile } from 'store/profile'


const tabChoicesDefault="defaultValue"
const tabChoices=[
  {
    value: "overview",
    label: "Översikt"
  },
  {
    value: "projects",
    label: "Projekt"
  },
  {
    value: "settings",
    label: "Inställningar"
  },
  {
    value: "weighting",
    label: "Viktning"
  }
]

const ForecastTabBar = ({forecastId}) => {

  const history = useHistory()
  const { pathname } = useLocation()
  const [selected, setSelected] = useState(tabChoicesDefault);
  const [user] = useUserProfile()

  useEffect( ()=>{
    // Change selected based on url change
    const lastPath = pathname.split("/").pop();
    if ( tabChoices.find(item=>item.value === lastPath)) {
      setSelected(lastPath)
    }
  }, [pathname])

  const onTabBarChange = e=>{
    const {value} = e.target;
    history.push(`/forecast/${forecastId}/${value}`);
  }

  if (!user.isAdmin) {
    return null
  }

  return (
    <TabBar
      choices={tabChoices}
      onChange={onTabBarChange}
      selected={selected}
    />
  )
}


ForecastTabBar.propTypes = {
  forecast: PropTypes.object,
}
export default ForecastTabBar
