export default {
  FUNC_BASE: 'https://b-bsab-analys-prod-func.azurewebsites.net/api/',
  API_BASE: 'https://b-bsab-analys-prod-app.azurewebsites.net/',
  AD_GROUP_USER: '__b7df4fed-6134-4d95-a8a9-97aae65f05fa',
  AD_GROUP_SUPERUSER: 'b7df4fed-6134-4d95-a8a9-97aae65f05fa',
  SSO_AUTHORITY: 'https://sso.byggforetagen.se/',
  SANITY_PROJECT_ID: 'vugx7jvv',
  SANITY_DATASET: 'production',
  GOOGLE_ANALYTICS_ID: 'G-JF2XD7TQ03',
  DEFAULT_ROUTE: '/start',
  PROGNOS_DATASET: 'prod'
}

