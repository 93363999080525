import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'


// import progress from 'assets/icons/progress.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import "components/progress/_progress.scss"

const CLASS_NS = "progress";

const ProgressItem = ({label, active=false, classNs=CLASS_NS}) => {
  var clsLine = classNames(`${classNs}__line`, {[`${classNs}__line_active`]: active});
  var clsItem = classNames(`${classNs}__item`, {[`${classNs}__item__active`]: active});   
  var clsLabel = classNames(`${classNs}__item__label`, {[`${classNs}__item__label__active`]: active});   
  return <>
    <div className={clsLine}></div>
    <div className={clsItem}>
      <CheckIcon className={`${classNs}__item__image`} />
      <div className={clsLabel}>{label}</div>
    </div>
  </>
}

const Progress = ({
  classNs=CLASS_NS,
  states = []
}) => {
  return (
    <div className={`${classNs}`}>
      {states.map(s => <ProgressItem key={s.label} {...s} />)}
    </div>
  )
}

Progress.propTypes = {
  classNs: PropTypes.string,
  url: PropTypes.string,
}
export default Progress
