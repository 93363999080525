import React from 'react'
import './_notfound.scss'
const CLASS_NS = "notfound"

const NotFound = ({
  classNs=CLASS_NS,
  children
}) => {

  return (
    <div className={classNs}>
      Sidan hittades inte.
      {children}
    </div>
  )
}

export default NotFound
