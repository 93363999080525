import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import PropTable from 'components/prop-table/prop-table';
import Page from "components/page/page";
import { ReactComponent as OpenIcon } from 'assets/icons/open.svg';

import { ForecastProjectContext } from './project'


import { formatIsoDate } from 'utils/date-helpers';

const ForecastProjectByggfaktaSection = (props) => {
  const { fnProject } = useContext(ForecastProjectContext)
  if (!fnProject || !fnProject.raw) {
    return null
  }
  
  const fnProjectRaw = fnProject.raw
  const { cat_dev_type, roles } = fnProjectRaw
  console.log({fnProjectRaw});

  return (
    <>
      <a id="project-byggfakta-section" name="project-byggfakta-section" />
      <Page.Grid {...props} variant="white" title="Bedömningsunderlag från Byggfakta">
        {fnProjectRaw &&
          <>
            <Page.Block span={4}>
              <PropTable label="Beskrivning" >
                <PropTable.Row label={fnProjectRaw.description} />
              </PropTable>
            </Page.Block>
            <Page.Block span={2}>
              <PropTable label="Översikt" >
                <PropTable.Row label="Namn">{fnProjectRaw.project_name}</PropTable.Row>
                <PropTable.Row label="Byggfakta Smart">
                  <a target="_smart" href={`https://smart.byggfakta.se/leads/projects/${fnProjectRaw.id}`}>[klicka här]</a>
                </PropTable.Row>
                {fnProjectRaw.district_name && <PropTable.Row label="Kommun">{fnProjectRaw.district_name}</PropTable.Row>}
                {fnProjectRaw.mainCategory && <PropTable.Row label="Huvudkategori">{fnProjectRaw.mainCategory.name}</PropTable.Row>}
                <PropTable.Row label="Kontrollerades">{formatIsoDate(fnProjectRaw.updated_at)}</PropTable.Row>
                {fnProjectRaw.fnlLink && <PropTable.Row label="Länk till Byggfakta"><a target="_blank" href={fnProject.fnlLink}><OpenIcon /></a></PropTable.Row>}
              </PropTable>
            </Page.Block>
          </>
        }
        {cat_dev_type &&
          <Page.Block span={2}>
            <PropTable label="Kategorier" >
              {cat_dev_type.map(c =>
                <PropTable.Row key={`${c.category_id}-${c.dev_type_id}`} label={c.name}>{c.dev_type_name} - {c.category_name}</PropTable.Row>
              )}
            </PropTable>
          </Page.Block>
        }
        {false && roles &&
          <Page.Block span={2}>
            <PropTable label="Kontakt" >
              {roles.map(r =>
                r.contacts.filter(c => c.contact).map(c => 
                  
                  <PropTable.Row key={`${c.contact_id}-${c.contact_role_id}`} label={`${c.contact.first_name} ${c.contact.last_name}`}>{r.company_role} {c.contact.communications?.map( cc => <>{cc.value}</>)}</PropTable.Row>

                )
              )}
                
            </PropTable>
          </Page.Block>
        }

      </Page.Grid>
    </>
  )
}

ForecastProjectByggfaktaSection.propTypes = {
  project: PropTypes.object,
  progresses: PropTypes.object
}
export default ForecastProjectByggfaktaSection
