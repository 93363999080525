import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// import ImageMissing from "components/image-missing/image-missing";
import {ReactComponent as CrossIcon} from "assets/icons/cross.svg";
import { isFunction } from "utils/js-helpers";

import "components/card/_card.scss"
const CLASS_NS = "card";
/**
 * Genric card object which can be populated with product data
 * @returns {*} - React.Component
 */
const Card = ({
  classNs = CLASS_NS,
  nodeType = "div",
  supertitle,
  title,
  subtitle,
  topContentAlignment = "horizontal",
  onRemove,
  imageSrc = "",
  imageBgSrc = "",
  // Use one of three linking options
  imageLinkUrl = "",
  linkUrl = "",
  clickHandler = null,
  // -- end of link usage
  modifiers = "",
  children,
}) => {
  const CardNodeType = nodeType;

  const removeButton = isFunction(onRemove) ?
    <button className={`${classNs}__remove-button`} onClick={onRemove}>
      <CrossIcon className={`${classNs}__remove-icon`}/>
    </button> :
    null;

  const ContainerNodeType = (linkUrl && Link) || (imageLinkUrl && "a") || (isFunction(clickHandler) && "button") || "div";

  // TODO: place a onload event listeniner for this element and if failed place
  // an image missing onto the element.
  const imageElement =
    (imageSrc && <img src={imageSrc} className={`${classNs}__image`} alt={title || "bild"} />) ||
    (imageBgSrc && <div className={`${classNs}__bgimage`} style={{ backgroundImage: `url(${imageBgSrc})` }}></div>) ||
    null;

  const setModifiers = () => {
    return modifiers.length === 0 ? "" : modifiers.split(" ").map((modifier) => `${classNs}--${modifier}`);
  };

  return (
    <CardNodeType className={`${classNs} ${setModifiers()}`}>
      <ContainerNodeType
        className={`${classNs}__container`}
        to={linkUrl || null}
        href={imageLinkUrl}
        onClick={clickHandler}
        data-alignment={topContentAlignment}>
        {removeButton}
        {imageElement}
        <div className={`${classNs}__content`}>
          {typeof supertitle === "string" ? <h5 className={`${classNs}__supertitle`}>{supertitle}</h5> : supertitle}
          {typeof title === "string" ? <h3 className={`${classNs}__title`}>{title}</h3> : title}
          {typeof subtitle === "string" ? <h4 className={`${classNs}__subtitle`}>{subtitle}</h4> : subtitle}
          {children}
        </div>
      </ContainerNodeType>
    </CardNodeType>
  );
};

Card.propTypes = {
  classNs: PropTypes.string,
  nodeType: PropTypes.string,
  supertitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  topContentAlignment: PropTypes.string,
  onRemove: PropTypes.func,
  imageSrc: PropTypes.string,
  imageBgSrc: PropTypes.string,
  // Use one of three linking options
  imageLinkUrl: PropTypes.string,
  linkUrl: PropTypes.string,
  clickHandler: PropTypes.func,
  // -- end of link usage
  modifiers: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
export default Card;
