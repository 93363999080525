import React from 'react';

import './_button.scss'

const Button = ({
  children,
  ...props
}) => {

  return (
    <button
      className="button-2"
      {...props}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
}

export default Button
