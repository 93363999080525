import * as types from "store/_types";

/* ACTIONS */
export const uiStartChecked = () => ({
  type: types.UI_START_CHECKED,
});

export const uiStopChecked = () => ({
  type: types.UI_STOP_CHECKED,
});

/* REDUCER */
const initialState = {
  show: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.UI_START_CHECKED:{
      return {        
        ...state,
        show: true
      }
    }

    case types.UI_STOP_CHECKED:{
      return {        
        ...state,
        show: false
      }
    }

    default:
      return state
  }
}
