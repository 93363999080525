import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'

const GET_OFFICE_LIST_LOADING = 'GET_OFFICE_LIST_LOADING'
const GET_OFFICE_LIST_SUCCESS = 'GET_OFFICE_LIST_SUCCESS'
const GET_OFFICE_LIST_ERROR = 'GET_OFFICE_LIST_ERROR'

const UPDATE_OFFICE_LOADING = 'UPDATE_OFFICE_LOADING'
const UPDATE_OFFICE_SUCCESS = 'UPDATE_OFFICE_SUCCESS'
const UPDATE_OFFICE_ERROR = 'UPDATE_OFFICE_ERROR'

const ADD_OFFICE_LOADING = 'ADD_OFFICE_LOADING'
const ADD_OFFICE_SUCCESS = 'ADD_OFFICE_SUCCESS'
const ADD_OFFICE_ERROR = 'ADD_OFFICE_ERROR'


/* ACTIONS */
const getOfficeList = () => (dispatch) => {
  dispatch({
    type: GET_OFFICE_LIST_LOADING
  });

  httpClient(`offices`)
    .then(result => {
      dispatch({
        type: GET_OFFICE_LIST_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: GET_OFFICE_LIST_ERROR,
        error,
        payload: error
      });
    })
}

export const updateOffice = ({ officeId, ...data }) => (dispatch) => {
  dispatch({
    type: UPDATE_OFFICE_LOADING
  });

  httpClient(`offices/${officeId}`, { data, method: 'POST' })
    .then(result => {
      dispatch({
        type: UPDATE_OFFICE_SUCCESS,
        payload: result
      });
      dispatch(getOfficeList())
    })
    .catch(error => {
      dispatch({
        type: UPDATE_OFFICE_ERROR,
        error,
        payload: error
      });
    })
}

export const addOffice = ({ officeId, ...data }) => (dispatch) => {
  dispatch({
    type: ADD_OFFICE_LOADING
  });

  httpClient(`offices/${officeId}`, { data, method: 'POST' })
    .then(result => {
      dispatch({
        type: ADD_OFFICE_SUCCESS,
        payload: result
      });
      dispatch(getOfficeList())
    })
    .catch(error => {
      dispatch({
        type: ADD_OFFICE_ERROR,
        error,
        payload: error
      });
    })
}

/* HOOKS */
export const useOfficeList = () => {
  const dispatch = useDispatch();
  const { rows = [], networkState = WAITING } = useSelector(state => state.offices);

  useEffect(() => {
    if (networkState === WAITING) {
      dispatch(getOfficeList())
    }
  }, [networkState, dispatch]);

  return [rows, networkState];
};
/* REDUCER */
const initialState = {
  error: null,
  networkState: WAITING,
  rows: [],
}
export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case GET_OFFICE_LIST_LOADING:
      return {
        ...state,
        networkState: LOADING
      }
    case GET_OFFICE_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    case GET_OFFICE_LIST_SUCCESS:
      return {
        ...state,
        rows: action.payload,
        networkState: SUCCESS,
      }

    default:
      return state
  }
}
