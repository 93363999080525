import React, { } from 'react'
import classNames from 'classnames'

import ArticleHeader from "components/article-header/article-header"
import Page from "components/page/page"
import Loading from 'components/loading/loading'

import { useForecast } from "store/forecast"
import { useProject } from "store/project"
import { useProjectNotes } from "store/project_notes"
import { useForecastProject } from "store/forecast_project"
import { useForecastRatings } from "store/forecast_ratings"
import { useCategories } from "store/categories"
import { useOfficeList } from "store/offices"
import { useConstant } from 'store/constants'
import { useUserProfile } from 'store/profile'


import ForecastProjectProgressSection from './project-progress-section'
import ForecastProjectRatingSection from './project-rating-section'
import ForecastProjectNotesSection from './project-notes-section'
import ForecastProjectByggfaktaSection from './project-byggfakta-section'
import ForecastProjectNavSection from './project-nav-section'

import { WAITING, ERROR } from "store/_types";

import "./project.scss";

export const ForecastProjectContext = React.createContext({});

const Project = ({ forecastId, projectId }) => {

  const [user] = useUserProfile()
  const [forecast, forecastNetworkState] = useForecast(forecastId);
  const [project, projectNetworkState] = useForecastProject(forecastId, projectId);
  const [fnProject] = useProject(projectId);
  const [forecastRatings, forecastRatingsNetworkState] = useForecastRatings(forecastId)
  const [notes, faktanetProjectNoteNetworkState] = useProjectNotes(projectId)
  const [categories, categoriesNetworkState] = useCategories()
  const [offices, officesNetworkState] = useOfficeList()
  const [constant] = useConstant()

  if (projectNetworkState === WAITING || forecastNetworkState === WAITING || forecastRatingsNetworkState === WAITING || forecastRatingsNetworkState === WAITING || categoriesNetworkState === WAITING || officesNetworkState === WAITING || faktanetProjectNoteNetworkState === WAITING) {
    return <Loading />
  }
  if (projectNetworkState === ERROR || forecastNetworkState === ERROR || forecastRatingsNetworkState === ERROR || forecastRatingsNetworkState === ERROR || categoriesNetworkState === ERROR || officesNetworkState === ERROR || faktanetProjectNoteNetworkState === ERROR) {
    return <Loading />
  }
  if (project.projectId !== projectId) {
    // return <Loading/>
  }
  const isRated = !!project.ratedAt
  const headerCls = classNames('project__page__header', {
    [`project__page__header--rated`]: isRated
  })

  return (
    <ForecastProjectContext.Provider value={{ forecast, project, forecastRatings, fnProject, categories, constant, user, offices, notes }} >
      <Page className="project__page">
        <ForecastProjectNavSection className="project__page__nav" />
        <Page.Header className={headerCls}>
          <ArticleHeader
            classNs="project__page__header"
            supertitle={project.name}
            title={project.title}
            message={isRated && 'Status: Projektet är bedömt och inskickat'}
          />
        </Page.Header>

        <ForecastProjectProgressSection className="project__page__progress" />
        <ForecastProjectRatingSection className="project__page__rating" />
        <ForecastProjectNotesSection className="project__page__notes" />
        <ForecastProjectByggfaktaSection className="project__page__byggfakta" />
      </Page>
    </ForecastProjectContext.Provider>
  )
}

Project.propTypes = {
}
export default Project
