import React, {useState, useEffect} from 'react'

import SanityPage from 'components/sanity-page/sanity-page';
import { useSelector } from "react-redux";
import NotFound from 'components/notfound/notfound'
import { useSanity } from 'store/sanity'
import Timeline from 'components/timeline/timeline';
import SwedenMap from 'components/sweden-map/sweden-map';
import { replaceDiacritics } from 'utils/string-helpers'
import "routes/volumes/_volumes.scss"


const timelineLabels = (mapData, forecastEnd) => {
  if(!mapData) return []
  const minValue = mapData[0].yearlyData.length - 1

  let max = forecastEnd
  let min = max - minValue
  let years = []

  while (max>= min) {
    years.push(min++)
  }
  years = years.map(year => year.toString())
  return years
}

const filteredMapData = (labels, year, mapData) => {
  if(!mapData || !labels.length) return []
  // returns an array with county data based on the timeline's selected year
  const sortedData = mapData.map((county) => {
    const yearIndex = labels.indexOf(year)
    return {
      id: replaceDiacritics(county.name.toLowerCase()), // match with map's path name
      year: year,
      name: county.name,
      totalVolume: county.yearlyData[yearIndex][0],
      change: county.yearlyData[yearIndex][1]
    }
  })
  return sortedData
}

export default () => {
  const [mapData, setMapData] = useState([])
  const [labels, setLabels] = useState([])
  const [dataYear, setDataYear] = useState('')
  const [{settings}] = useSanity()
  const { byggvolymer: page } = useSelector(state => state.sanity.page);

  useEffect(() => {
    const data = page && page.volumes ? page.volumes.data : []
    const _labels = timelineLabels(data, settings.forecastEnd)
    setLabels(_labels)
    setMapData(data)
    setDataYear(_labels ? _labels[_labels.length - 1] : '')
  }, [page.volumes])

  if (!page) return <NotFound />
  return (
    <>
      <SanityPage classNs="sanity-page-volumes" page={page} timelineLabels={labels} mapData={mapData} aside={
        <section className="sanity-page-volumes__graph">
          <SwedenMap className="sanity-page-volumes__graph" mapData={filteredMapData(labels, dataYear, mapData)} />
        </section>
      }>
        <Timeline
          inputLabel="Se förändring över tid:"
          labels={labels}
          value={dataYear}
          onSelect={setDataYear}
        />
      </SanityPage>
    </>
  )
}
