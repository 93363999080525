import React, { useEffect, useState } from 'react'
import DataTable from "components/data-table/data-table";
import DataList from "components/data-list/data-list";
import InputInline from "components/input-inline/input-inline";

import { useForecastRatings } from "store/forecast_ratings"
import { useForecastWeightCategoryValueDistribution, updateForecastWeightCategoryValueDistribution } from "store/forecast_weight_categoryvaluedistribution"
import { useDispatch } from 'react-redux';
import InputArray from 'components/input-array/input-array';
import InputNumber from 'components/input-number/input-number';

const ValueDistributionTable = ({forecastId}) => {
    const dispatch = useDispatch()
    const [value, setValue] = useState([])
    const [forecastWeightCategoryValueDistribution] = useForecastWeightCategoryValueDistribution(forecastId);

    useEffect(() => {
        setValue(forecastWeightCategoryValueDistribution.weight);
    }, [forecastWeightCategoryValueDistribution])

    const onValueChanged = (value) => {
        setValue(value)
    }
    const onSave = () => {
        dispatch(updateForecastWeightCategoryValueDistribution({forecastId, data: {weight: value}}))
    }
    console.log({forecastWeightCategoryValueDistribution});
    return (
        <>

            <DataList 
                header="Värdefördelning (procent) till huvudkategori" 
                footer={<button className="button-2" onClick={onSave}>Spara</button>}
            >
                <div>   
                    <InputNumber value={value} onValueChanged={onValueChanged} />
                    <div></div>
                </div>
            </DataList>
        </>

    )
}

export default ValueDistributionTable;