import React from 'react'
import "components/article/_article.scss"

const CLASS_NS = "article"

const Article = ({
  classNs=CLASS_NS,
  title,
  imageSrc,
  imageBgSrc,
  children
}) => {

  const imageElement =
  (imageSrc && <img src={imageSrc} className={`${classNs}__image`} alt={title || "bild"} />) ||
  (imageBgSrc && <div className={`${classNs}__bgimage`} style={{ backgroundImage: `url(${imageBgSrc})` }}></div>) ||
  null;
  
  return (
    <>
      <article className={`${classNs} ${CLASS_NS}`}>
        <section className={`${classNs}__article-section`}>
          {imageElement}
          <div className={`${classNs}__content`}>
            {children}
          </div>
        </section>
      </article>
    </>
  )
}

export default Article