import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Wrapper, Button, Menu, MenuItem } from 'react-aria-menubutton'

import "components/selector/_selector.scss"
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';

const CLASS_NS = "selector"
const defaultOption = { id: 0, value: 0, label: 'default', children: [] }
const defaultPlaceholder = "Välj"

/*
 * A controlled component which is used by the Search component
 */
function Selector({
  classNs = CLASS_NS,
  options = [defaultOption],
  disabled = false,
  required = false,
  placeholder = defaultPlaceholder,
  onSelect = () => { },
  onMenuToggle = () => { },
  onBlur = () => { },
  wrapperId = null,
  name = "selector",
  optionTemplate = ({ label }) => <span className={`${classNs}__result`}>{label}</span>,
  buttonText = "",
  closeOnBlur = false,
  closeOnSelect = true,
  selectedOption = null,
  showSelected = true // Show selected label in the button instead of the placeholder
}) {

  const [selected, setSelected] = useState({})
  useEffect(() => {
    if (selectedOption && selectedOption.value) {
      const selectedObject = options.find(option => option.value === selectedOption.value);
      setSelected(selectedObject);
    }
  }, [selectedOption])

  const onSelection = (value, event) => {
    if (showSelected) {
      const selectedObject = options.filter(option => option.value === value)[0];
      setSelected(selectedObject);
    }
    // Find the button from which to pass forward as target.
    const wrapper = event.target.closest("[data-js='selector-wrapper']");
    const input = wrapper ? wrapper.querySelector("[data-js='selector-input']") : event.target;
    input.setAttribute("value", value); // Have to force setting the value before sending it off
    event.target = input; // Set input as target instead which contains more useful information for validation
    onSelect(value, event)
  }

  const onButtonBlur = e => {
    var input = e.target.previousElementSibling;
    e.target = input; // Set input as target instead which contains more useful information for validation
    onBlur(e);
  }

  return (
    <Wrapper
      id={wrapperId}
      className={`${classNs}`}
      onSelection={onSelection}
      onMenuToggle={onMenuToggle}
      closeOnSelection={closeOnSelect}
      closeOnBlur={closeOnBlur}
      data-js="selector-wrapper"
      tag="menu"
    >
      <input
        style={{ position: "fixed", width: 0, height: 0, padding: 0, margin: 0, border: 0 }}
        type="text"
        name={name}
        value={(selected && selected.value) ? selected.value : ""}
        onChange={() => { }}
        required={required}
        data-js="selector-input"
        tabIndex="-1"
      />
      <Button
        className={`${classNs}__button`}
        tag="span"
        disabled={disabled}
        onBlur={onButtonBlur}
      >{buttonText ? <span className={`${classNs}__button-text`}>{buttonText}</span> : ""} {(selected && selected.label) ? selected.label : placeholder}<ChevronDown className={`${classNs}__icon`} /></Button>
      <Menu
        className={`${classNs}__list`}
        tag={"ul"}
      >
        {
          options.map((item, index) => (
            <MenuItem
              key={index}
              className={`${classNs}__item`}
              tag="li"
              value={item.value}
            >
              {optionTemplate({ ...item })}
            </MenuItem>
          ))
        }
      </Menu>
    </Wrapper>

  );
}

Selector.propTypes = {
  classNs: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  wrapperId: PropTypes.string,
  optionTemplate: PropTypes.func,
  placeholder: PropTypes.string
}
export default Selector;
