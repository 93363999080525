import React from 'react'
import { useSelector } from "react-redux";
import './_spinner.scss'

/*
 * Displays an instruction which can be dismissed.
 */
const Spinner = () => {

  const { show } = useSelector(state => state.ui_spinner)
  if (!show) {
    return null;
  }
  return (
    <div className="spinner">
      <div className="spinner__inner">
        <div className="spinner__inner__double-bounce1"></div>
        <div className="spinner__inner__double-bounce2"></div>
      </div>
    </div>
  )
}

export default Spinner
