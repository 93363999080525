import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types'
import ModalContentContainer from "components/modal/modal-content-container"
import InputInline from "components/input-inline/input-inline";
import Page from "components/page/page";

import { putForecastWeightRatingMap } from "store/forecast_weight_ratingmap"

const ForecastCategoriesModal = ({ present, forecastId }) => {

  const dispatch = useDispatch();
  const [valid, setValid] = useState(false)
  const [name, setName] = useState(1)
  useEffect(() => {
    setValid(
      name !== null
    )
  }, [name])
  const onSaveRating = () => {
    dispatch(putForecastWeightRatingMap({
      forecastId, data: {
        name,
      }
    }))
  }

  return (
    <ModalContentContainer title="Lägg till">
      <form
        method="dialog"
      >
        <Page.Grid columns={4}>

          <Page.Block span={4}>
              <label htmlFor="name">Kategorinamn</label>
              <InputText 
              type="text" 
              name="name" 
              required 
              value={name} 
              onChange={e => setName(e.target.value)}
              />
              <p className="input-error">{errorMessages.has("name") ? errorMessages.get("name") : null}</p>
            </Page.Block>

          <Page.Block span={4}>
          </Page.Block>
          <Page.Block span={1}>
            <button
              disabled={!valid}
              onClick={onSaveRating}
              className="button"
            >Spara</button>
          </Page.Block>
        </Page.Grid>
      </form>
    </ModalContentContainer>
  )
}


ForecastCategoriesModal.propTypes = {
  forecastId: PropTypes.number
}
export default ForecastCategoriesModal