import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import NavBar from "components/nav-bar/nav-bar"

import "components/page/_page.scss"

const Page = ({ className, children }) => {
  var pageClass = classNames('page',
    {
      [className]: !!className
    });
  return (
    <article className={pageClass}>
      {children}
    </article>
  )
}

const Section = ({ className, action, variant, title, children }) => {
  var sectionClass = classNames('page__section',
    {
      [className]: !!className,
      [`page__section--${variant}`]: !!variant
    });
  return (
    <section className={sectionClass}>
      {title &&
        <header className="page__section__header">
          {title &&
            <h3>{title}</h3>
          }
          {action}
        </header>
      }
      <div className="page__section__content">
        {children}
      </div>
    </section>
  )
}

const Grid = ({ className, columns = 4, action, variant, title, children }) => {
  var sectionClass = classNames(`page__grid`,
    {
      [className]: !!className,
      [`page__grid--${variant}`]: !!variant
    });
  var contentClass = classNames(`page__grid__content`,
    {
      [`page__grid__content--columns${columns}`]: columns !== false
    });
  return (
    <section className={sectionClass}>
      {title &&
        <header className="page__grid__header">
          {title &&
            <h3>{title}</h3>
          }
          {action}
        </header>
      }
      <div className={contentClass}>
        {children}
      </div>
    </section>
  )
}

const Block = ({ children, className, span = 1, start = false }) => {
  var blockClass = classNames(`page__block`,
    {
      [className]: !!className,
    });

  var style = {
    gridColumn: start ? `${start} / span ${span}` : `span ${span}`
  }

  return <div className={blockClass} style={style}>
    {children}
  </div>
}

const ElevatedBlock = ({ children, span }) => {
  return <div className={`page__block page__block--elevated page__block--span${span}`}>
    {children}
  </div>
}


const Header = ({
  title = "",
  supertitle = "",
  subtitle = "",
  back,
  children,
  className,
  variant
}) => {

  var cls = classNames(`page__header`,
    {
      [className]: !!className,
      [`page__header--${variant}`]: !!variant
    });
  return (
    <header className={cls}>
      <div className="page__header__content ">
        {back ? <div className="page__header__content__back ">{back}</div> : null}
        {supertitle ? <h6 className="page__header__content__supertitle ">{supertitle}</h6> : null}
        {title ? <h2 className="page__header__content__title ">{title}</h2> : null}
        {subtitle ? <h3 className="page__header__content__subtitle ">{subtitle}</h3> : null}
        {children}
      </div>
    </header>
  )
}



const SubMenu = ({ choices }) => {

  var cls = classNames(`page__submenu`, 'page__submenu')
  return (
    <Page.Grid columns={4} className={cls}>
      <Page.Block span={4}>
        <NavBar choices={choices} />
      </Page.Block>
    </Page.Grid>
  )
}

Page.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
}

Page.Grid = Grid
Page.Section = Section
Page.Block = Block
Page.ElevatedBlock = ElevatedBlock
Page.Header = Header
Page.SubMenu = SubMenu

export default Page
