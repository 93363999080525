import React from 'react'
// 
import { useSelector } from "react-redux";

import ArticleHeader from "components/article-header/article-header"
import Card from "components/card/card";
import "routes/contact/_contact.scss"

const ContactItem = ({ name, role, address, phone, email, image }) => (
  <li className="contact__item">
    <Card
      classNs="contact__card"
      title={name}
      subtitle={role}
      address={address}
      phone={phone}
      email={email}
      modifiers="contact"
      imageSrc={image}
    >
      <span>
        {typeof address === "string" ? <p className="contact__card__address">{`Kontor: ${address}`}</p> : address}
        {typeof phone === "string" ? <a href={`tel:${phone}`} className="contact__card__phone">{`Tel: ${phone}`}</a> : phone}
      </span>
      {typeof email === "string" ? <p className="contact__card__email__overflow-ellipsis"><a href={`mailto:${email}`} className="contact__card__email"> {email}</a></p> : email}
    </Card>
  </li>
)

const Contact = () => {
  const { contact } = useSelector(state => state.sanity);

  const primary = contact.filter(c => c.primary).pop();
  const others = contact.filter(c => !c.primary);

  return (
    <>
      <ArticleHeader
        classNs="contact_header"
        title="Kontaktpersoner"
      ></ArticleHeader>
      <article className="page-article">
        <section className="contact">
          {primary && <>
            <h3>Ansvarig Regionala prognoser</h3>
            <ul className="contact__list">
              <ContactItem key={primary.id} {...primary} />
            </ul>
          </>
          }
          {others.length > 0 &&
            <>
              <h3>Övriga kontaktpersoner</h3>
              <ul className="contact__list">
                {others.map((c, id) => <ContactItem key={id} {...c} />)}
              </ul>
            </>
          }
        </section>
      </article>
    </>
  )
}

export default Contact
