import React, { useState, useEffect } from 'react'
// 
import { useHistory } from "react-router-dom";

import Page from "components/page/page"
import Modal from "components/modal/modal"
import Loading from 'components/loading/loading'
import DataTable from "components/data-table/data-table";
import Button from "components/button/button";
import { ArrowRightIcon } from "components/icon"

import { formatIsoDate } from 'utils/date-helpers'
import { useUserProfile } from 'store/profile';
import { useForecastList } from "store/forecast_list"

import { /*WAITING,*/ LOADING, /*ERROR,*/ /*SUCCESS*/ } from "store/_types";

import CreateModal from './create-modal'
import "./forecasts.scss";

const Forecasts = () => {
  const history = useHistory()
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [forecastList, networkState] = useForecastList()
  const [user] = useUserProfile()


  useEffect(() => {
    if (!user.isAdmin) {
      const forecast = forecastList.filter(f => f.status === 'STARTED').pop()
      if (forecast && forecast.id) {
        history.push(`/forecast/${forecast.id}/projects`)
      }
    }
  }, [user, forecastList])

  const handleForecastClick = id => history.push(`/forecast/${id}/overview`)
  const forecastListStarted = forecastList.filter(f => f.status === 'STARTED')
  const forecastListDraft = forecastList.filter(f => f.status === 'DRAFT' ||  f.status === null)
  const forecastListCompleted = forecastList.filter(f => f.status === 'COMPLETED')
  const forecastListArchived = forecastList.filter(f => f.status === 'ARCHIVED')

  if (!user.isAdmin) {
    return <Loading />
  }

  return (
    <Page className="forecasts__page">
      <Page.Section className="forecasts__page__section" title="Pågående prognoser" action={
          <Button onClick={() => { setCreateModalOpen(true) }}>Skapa ny prognos</Button>
      }>
        <DataTable
          bodyData={forecastListStarted}
          isLoading={networkState === LOADING}
        >
          <DataTable.Column name="name" label="Namn" />
          <DataTable.Column name="intervall" label="Prognosintervall" formatter={(row) => `${row.projectStartYear} - ${row.forecastEndYear}`} />
          <DataTable.Column name="deadline" label="Slutdatum" formatter={(row) => formatIsoDate(row.deadlineDate)} />
          <DataTable.Column name="progress" label="Bedömda projekt" formatter={(row) => <><span>{row.progress}</span><span>%</span></>} />
          <DataTable.Column name="edit" cellContent={<ArrowRightIcon />} cellFunc={handleForecastClick} />
        </DataTable>
      </Page.Section>
      {forecastListDraft.length > 0 &&
        <Page.Section className="forecasts__page__section" title="Prognosutkast">
          <DataTable
            bodyData={forecastListDraft}
            isLoading={forecastListDraft.length == 0}
          >
            <DataTable.Column name="name" label="Namn" />
            <DataTable.Column name="intervall" label="Prognosintervall" formatter={(row) => `${row.projectStartYear} - ${row.forecastEndYear}`} />
            <DataTable.Column name="deadline" label="Slutdatum" formatter={(row) => formatIsoDate(row.deadlineDate)} />
            <DataTable.Column name="progress" label="Bedömda projekt" formatter={(row) => <><span>{row.progress}</span><span>%</span></>} />
            <DataTable.Column name="edit" cellContent={<ArrowRightIcon />} cellFunc={handleForecastClick} />
          </DataTable>
        </Page.Section>
      }
      {forecastListCompleted.length > 0 &&
        <Page.Section className="forecasts__page__section" title="Avslutade">
          <DataTable
            bodyData={forecastListCompleted}
            isLoading={forecastListCompleted.length == 0}
          >
            <DataTable.Column name="name" label="Namn" />
            <DataTable.Column name="intervall" label="Prognosintervall" formatter={(row) => `${row.projectStartYear} - ${row.forecastEndYear}`} />
            <DataTable.Column name="deadline" label="Slutdatum" formatter={(row) => formatIsoDate(row.deadlineDate)} />
            <DataTable.Column name="progress" label="Bedömda projekt" formatter={(row) => <><span>{row.progress}</span><span>%</span></>} />
            <DataTable.Column name="edit" cellContent={<ArrowRightIcon />} cellFunc={handleForecastClick} />
          </DataTable>
        </Page.Section>
      }
      {forecastListArchived.length > 0 &&
        <Page.Section className="forecasts__page__section" title="Arkiverade">
          <DataTable
            bodyData={forecastListArchived}
            isLoading={forecastListArchived.length == 0}
          >
            <DataTable.Column name="name" label="Namn" />
            <DataTable.Column name="intervall" label="Prognosintervall" formatter={(row) => `${row.projectStartYear} - ${row.forecastEndYear}`} />
            <DataTable.Column name="deadline" label="Slutdatum" formatter={(row) => formatIsoDate(row.deadlineDate)} />
            <DataTable.Column name="progress" label="Bedömda projekt" formatter={(row) => <><span>{row.progress}</span><span>%</span></>} />
            <DataTable.Column name="edit" cellContent={<ArrowRightIcon />} cellFunc={handleForecastClick} />
          </DataTable>
        </Page.Section>
      }
      {createModalOpen &&
        <Modal isOpen={createModalOpen} onClose={() => { setCreateModalOpen(false) }} closeButton={true}>
          <CreateModal onClose={() => { setCreateModalOpen(false) }} />
        </Modal>}
    </Page>
  )
}

export default Forecasts
