import React, { useContext, useState } from 'react'
import { useDispatch } from "react-redux";

import DataTable from "components/data-table/data-table";
import SelectInput from "components/select-input/select-input"
import Page from "components/page/page";

import { useCategories } from "store/categories"
import { useForecastCategories, assignForecastCategory } from "store/forecast_categories"
import { DataContext } from './settings'
import Modal from 'components/modal/modal';
import Button from 'components/button/button';
import ForecastCategoriesModal from './settings_categories_modal';

const CategorySelector = ({ onSelect, selected }) => {
  const { categories } = useContext(DataContext)
  if (!categories) return null;
  const options = categories.map(o => ({ id: o.id, value: o.id, label: o.name }))

  return <SelectInput
    onSelect={onSelect}
    noborder
    name="category"
    placeholder="Välj kategori"
    value={selected?.id}
    options={options}
  />
}

const ForecastCategories = ({ forecastId }) => {
  const dispatch = useDispatch()
  const [categories] = useCategories();
  const [forecastCategories] = useForecastCategories(forecastId);
  const [categoryIds, setCategoryId] = useState([]);
  const [addModalOpen, setAddModalOpen] = useState(false)
  const onSelect = (row) => (id) => {
    dispatch(assignForecastCategory({ forecastId, categoryId: id - 0, fnCategoryId: row.fnCategoryId }))
  }
  const onChange = (row) => (e) => {
    if (e.target.checked) {
      setCategoryId(selected => ([...selected, row.id]));
    } else {
      setCategoryId(selected => selected.filter(s => s !== row.id));
    }
  }
  const list = forecastCategories
  .filter(c => categoryIds.length === 0 || categoryIds.includes(c.category?.id))
  .map(i => ({
    ...i,
    path: i.path.indexOf(':') === -1 ? 
      <span style={{fontWeight: 'bold'}}>{i.path}</span>
      :
      <span>
        <span style={{paddingLeft: '2em'}}>{i.name}</span>
      </span>
    }))
  return (
    <>
      <Button onClick={() => setAddModalOpen(true)}>Lägg till</Button>
      <Page.Grid columns={2}>
        <Page.Block span={1}>
          <DataTable bodyData={categories}>
            <DataTable.Column name="category" label="Kategori" formatter={(row) => <input type="checkbox" onChange={onChange(row)} />} />
            <DataTable.Column name="name" label="Namn" />
          </DataTable>
        </Page.Block>
        <Page.Block span={1}>
          <DataTable bodyData={list} >
            <DataTable.Column name="path" label="Namn" />
            <DataTable.Column name="category" label="Kategori" formatter={(row) => <CategorySelector onSelect={onSelect(row)} selected={row.category}></CategorySelector>} />
          </DataTable>
        </Page.Block>
        {
          addModalOpen && 
            <Modal isOpen={!!addModalOpen} onClose={() => setAddModalOpen(false)} closeButton={true}>
              <ForecastCategoriesModal forecastId={forecastId}/>
            </Modal>
        }
        
      </Page.Grid>
    </>
  )
}

export default ForecastCategories
