import { format, formatISO, parseISO } from 'date-fns';

export const formatIsoDate = (datetime) => {
  try {
    var dt = datetime instanceof Date ? datetime : parseISO(datetime);
    return formatISO(dt, { representation: 'date' });
  } catch (e) {
    // console.error(e);
  }
  return '';
}

export const formatMonthDate = (datetime) => {
  try {
    var dt = datetime instanceof Date ? datetime : parseISO(datetime);
    return format(dt, 'yyyy-LL');
  } catch (e) {
    // console.error(e);
  }
  return '';
}

export const formatDate = (datetime) => {
  // TODO format to, ex 21 maj 2020
  if (!datetime) {
    return datetime;
  }
  return formatIsoDate(datetime);
}

export const parseIsoDate = (datetime) => {
  try {
    var dt = datetime instanceof Date ? datetime : parseISO(datetime);
    return dt;
  } catch (e) {
    // console.error(e);
  }
  return '';
}

export const formatIsoDateInterval = (start, end) => {
  const formatStart = formatIsoDate(start)
  const formatEnd = formatIsoDate(end)
  return `${formatStart} / ${formatEnd}`
}

export const rangeOfYears = (start, end) => {
  return Array(end - start + 1).fill(start).map((year, index) => year + index);
}

