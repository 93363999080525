import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types'
import ModalContentContainer from "components/modal/modal-content-container"
import InputInline from "components/input-inline/input-inline";
import InputNumber from "components/input-number/input-number";
import Page from "components/page/page";
import SelectInput from "components/select-input/select-input";

import { putForecastWeightRatingMap } from "store/forecast_weight_ratingmap"
import { DataContext } from './weighting'

const RatingmapModal = ({ present, forecastId }) => {

  const {ratingsMap, sizesMap, progressesMap, progressesOptions, ratingsOptions, sizeOptions} = useContext(DataContext)

  const dispatch = useDispatch();
  const [valid, setValid] = useState(false)
  const [ratingId, setRatingId] = useState(1)
  const [progressId, setProgressId] = useState(1)
  const [fcSizeId, setFcSizeId] = useState(1)
  const [monthDiffFrom, setMonthDiffFrom] = useState(null)
  const [monthDiffTo, setMonthDiffTo] = useState(null)
  useEffect(() => {
    setValid(
      ratingId !== null &&
      progressId !== null &&
      fcSizeId !== null &&
      (present || monthDiffFrom !== null )
    )
  }, [ratingId, progressId, fcSizeId, monthDiffFrom, monthDiffTo, present])
  const onSaveRating = () => {
    dispatch(putForecastWeightRatingMap({
      forecastId, data: {
        fcSizeId,
        progressId,
        ratingId,
        monthDiffFrom,
        monthDiffTo
      }
    }))
  }

  return (
    <ModalContentContainer title="Lägg till">
      <form
        method="dialog"
      >
        <Page.Grid columns={4}>

          <Page.Block span={4}>
            <label htmlFor="ratingId">Projektstorlek</label>
            <SelectInput name="rating" value={fcSizeId} options={sizeOptions} onSelect={setFcSizeId} />
          </Page.Block>

          <Page.Block span={4}>
            <label htmlFor="ratingId">Stadie</label>
            <SelectInput name="progress" value={progressId} options={progressesOptions} onSelect={setProgressId} />
          </Page.Block>

          <Page.Block span={4}>
            <label htmlFor="ratingId">Poäng</label>
            <SelectInput name="rating" value={ratingId} options={ratingsOptions} onSelect={setRatingId} />

          </Page.Block>

          { !present ? <>
            <Page.Block span={4}>
              <label htmlFor="monthDiffFrom">Månad diff från</label>
              <InputInline required  type="number" name="monthDiffFrom" value={monthDiffFrom}  onValueChanged={setMonthDiffFrom}  /> 
            </Page.Block>

            <Page.Block span={4}>
              <label htmlFor="monthDiffTo">Månad diff till</label>
              <InputInline required  type="number" name="monthDiffTo" value={monthDiffTo}  onValueChanged={setMonthDiffTo}  /> 
            </Page.Block>          
          </> : null }
          <Page.Block span={4}>
          </Page.Block>
          <Page.Block span={1}>
            <button
              disabled={!valid}
              onClick={onSaveRating}
              className="button"
            >Spara</button>
          </Page.Block>
        </Page.Grid>
      </form>
    </ModalContentContainer>
  )
}


RatingmapModal.propTypes = {
  forecastId: PropTypes.number
}
export default RatingmapModal