import React from "react";
import PropTypes from "prop-types";

import "components/anchor/_anchor.scss"
const CLASS_NS = "anchor";
const Anchor = ({classNs = CLASS_NS,children, ...rest }) => {
  return <a className={classNs} {...rest}>{children}</a>
};

Anchor.propTypes = {
  classNs: PropTypes.string
};
export default Anchor;
