import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'

const GET_FORECAST_WEIGHT_RATINGMAPDIFFB_LOADING = 'GET_FORECAST_WEIGHT_RATINGMAPDIFFB_LOADING'
const GET_FORECAST_WEIGHT_RATINGMAPDIFFB_SUCCESS = 'GET_FORECAST_WEIGHT_RATINGMAPDIFFB_SUCCESS'
const GET_FORECAST_WEIGHT_RATINGMAPDIFFB_ERROR = 'GET_FORECAST_WEIGHT_RATINGMAPDIFFB_ERROR'


const UPDATE_FORECAST_WEIGHT_RATINGMAPDIFFB_LOADING = 'UPDATE_FORECAST_WEIGHT_RATINGMAPDIFFB_LOADING'
const UPDATE_FORECAST_WEIGHT_RATINGMAPDIFFB_SUCCESS = 'UPDATE_FORECAST_WEIGHT_RATINGMAPDIFFB_SUCCESS'
const UPDATE_FORECAST_WEIGHT_RATINGMAPDIFFB_ERROR = 'UPDATE_FORECAST_WEIGHT_RATINGMAPDIFFB_ERROR'


/* ACTIONS */
const getForecastWeightRatingMapDiffB = ({forecastId}) => (dispatch) => {
  dispatch({
    type: GET_FORECAST_WEIGHT_RATINGMAPDIFFB_LOADING
  });

  httpClient(`forecasts/${forecastId}/weight/ratingmapdiffb`)
    .then(result => {
      dispatch({
        type: GET_FORECAST_WEIGHT_RATINGMAPDIFFB_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: GET_FORECAST_WEIGHT_RATINGMAPDIFFB_ERROR,
        error,
        payload: error
      });
    })
}


export const updateForecastWeightRatingMapDiffB = ({forecastId, data}) => (dispatch) => {
  dispatch({
    type: UPDATE_FORECAST_WEIGHT_RATINGMAPDIFFB_LOADING
  });

  httpClient(`forecasts/${forecastId}/weight/ratingmapdiffb`, { data , method: 'POST'})
    .then(result => {
      dispatch({
        type: UPDATE_FORECAST_WEIGHT_RATINGMAPDIFFB_SUCCESS,
        payload: result
      });
      dispatch(getForecastWeightRatingMapDiffB({forecastId}))
    })
    .catch(error => {
      dispatch({
        type: UPDATE_FORECAST_WEIGHT_RATINGMAPDIFFB_ERROR,
        error,
        payload: error
      });
    })
}

/* HOOKS */

export const useForecastWeightRatingMapDiffB = (forecastId) => {

  const dispatch = useDispatch();
  const { rows, networkState } = useSelector(state => state.forecast_weight_ratingmapdiffb);

  useEffect(() => {
    dispatch(getForecastWeightRatingMapDiffB({forecastId}));
  }, [forecastId, dispatch]);
  return [rows, networkState];
};
/* REDUCER */
const initialState = {
  rows: [],
  networkState: WAITING,
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {


    case GET_FORECAST_WEIGHT_RATINGMAPDIFFB_LOADING:
      return {
        ...state,
        networkState: LOADING
      }
    case GET_FORECAST_WEIGHT_RATINGMAPDIFFB_ERROR:
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    case GET_FORECAST_WEIGHT_RATINGMAPDIFFB_SUCCESS:
      return {
        ...state,
        rows: action.payload,
        networkState: SUCCESS,
      }

    default:
      return state
  }
}
