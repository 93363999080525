import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import "components/input-inline/_input-inline.scss"
const CLASS_NS = "input-inline";

const toInternalValue = value => {
  if (typeof value === 'undefined') {
    return ''
  } else if (value === null) {
    return ''
  } else {
    return `${value}`.replace(/[^0-9-+\.]/g,'')
  }
}
const fromInternalValue = value => {
  if (value === '') {
    return null
  } else {
    return value.replace(/[^0-9-+]/g,'') - 0
  }
}

const InputInline = ({
  classNs=CLASS_NS,
  name,
  label,
  value,
  placeholder,
  onChange,
  onValueChanged,
  onValueUpdated,
  formater = v => v,
  autoComplete="off",
  min,
  max
}) => {
  const [internalValue, setInternalValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    const v = toInternalValue(value)
    setInternalValue(v)
  }, [value])
  
  const onInputFocus = ()=>{
    setIsFocused(true);
  }
  const onInputBlur = ()=> {
    setIsFocused(false);
  }

  const onBlur = ()=>{
    setIsFocused(false);
    if (onValueChanged) {
      const v = fromInternalValue(internalValue)
      onValueChanged(v)
    }
  }
  const onFocus = ()=>{
    setIsFocused(true);
  }

  const onComponentActive = ()=>{
    setIsFocused(true);
  }

  const handleOnChange = e=>{
    const v = fromInternalValue(e.target.value)
    const vv = toInternalValue(v)
    setInternalValue(vv)
    e.target.value = v; 
    if (onChange) {
      onChange(e, null)
    }
    if (onValueUpdated) {
      onValueUpdated(v)
    }
  }
  const displayValue = isFocused ? internalValue : formater(internalValue)
  return (<>
    {label && 
        <label htmlFor={name}>{label}</label>
    }
    <div className={`${classNs}`}
      onClick={onComponentActive}
      onFocus={onFocus}
      onBlur={onBlur}
      data-active={isFocused}
    >
      <input
        className={`${classNs}__input`}
        autoComplete={autoComplete}
        name={name}
        placeholder={placeholder}
        onChange={handleOnChange}
        type="text"
        value={displayValue}
        min={min}
        max={max}
        size={3}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
      />
    </div>
  </>
  )
}

InputInline.propTypes = {
  classNs: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  formater: PropTypes.func,
  onValueUpdated: PropTypes.func,
  onValueChanged: PropTypes.func,
  buttonContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType
  ]),
  minVal: PropTypes.number,
  maxVal: PropTypes.number,
}
export default InputInline
