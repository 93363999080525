import React from 'react'
import { useSelector } from "react-redux";

// 

import Graph from 'components/graph/graph';
import LocationSelector from "components/location-selector/location-selector"
import RegionData from 'routes/report/region-data';
import ReportTabBar from "./report-tab-bar"


import "routes/report/_report.scss"

const Report = ({locationId, categoryId}) => {
  const { region } = useSelector(state => state.sanity);
  const regionData = region.find(c => c.id === locationId)

  const graphData = regionData?.graph?.data ?? false
  return (
    <>
      <LocationSelector locationId={locationId} />
      <ReportTabBar locationId={locationId} />
      { graphData && <Graph data={graphData} categoryId={categoryId} locationId={locationId} /> }
      { regionData && <RegionData locationId={locationId} categoryId={categoryId} regionData={regionData} /> }
    </>
  )
}

export default Report
