import { combineReducers } from 'redux'

import { reducer as oidc } from "redux-oidc";
import profile from 'store/profile';
import user from 'store/user';

import sanity from 'store/sanity';
import ui_checked from 'store/ui_checked';
import ui_spinner from 'store/ui_spinner';
import ui_snackbar from 'store/ui_snackbar';

import forecast_faktanet_categories from 'store/forecast_faktanet_categories';
import forecast_faktanet_districts from 'store/forecast_faktanet_districts';
import forecast_ratings from 'store/forecast_ratings';
import forecast_sizes from 'store/forecast_sizes';
import forecast_stats from 'store/forecast_stats';
import forecast_references from 'store/forecast_references';
import forecast_categories from 'store/forecast_categories';
import forecast_districts from 'store/forecast_districts';
import forecast_offices from 'store/forecast_offices';
import forecast_list from 'store/forecast_list';
import forecast_project from 'store/forecast_project';
import forecast_project_list from 'store/forecast_project_list';
import forecast_weight_ratingmap from 'store/forecast_weight_ratingmap';
import forecast_weight_delaydistribution from 'store/forecast_weight_delaydistribution';
import forecast_weight_valuedistribution from 'store/forecast_weight_valuedistribution';
import forecast_weight_categoryvaluedistribution from 'store/forecast_weight_categoryvaluedistribution';
import forecast_weight_ratingmapdiffb from 'store/forecast_weight_ratingmapdiffb';
import forecast from 'store/forecast';
import project_filter from 'store/project_filter';
import project_notes from 'store/project_notes';
import project from 'store/project';
import constants from 'store/constants';
import categories from 'store/categories';
import offices from 'store/offices';
import regions from 'store/regions';

const reducers = combineReducers({
  oidc,
  sanity,
  profile,
  user,
  constants,
  categories,
  offices,
  regions,
  forecast_faktanet_categories,
  forecast_faktanet_districts,
  forecast_ratings,
  forecast_sizes,
  forecast_stats,
  forecast_references,
  forecast_categories,
  forecast_districts,
  forecast_offices,
  forecast_list,
  forecast_project,
  forecast_project_list,
  forecast_weight_ratingmap,
  forecast_weight_delaydistribution,
  forecast_weight_valuedistribution,
  forecast_weight_categoryvaluedistribution,
  forecast_weight_ratingmapdiffb,
  forecast,
  project_filter,
  project_notes,
  project,
  ui_checked,
  ui_spinner,
  ui_snackbar,
})

export default reducers
