import React, { useState, useContext, useEffect } from 'react'
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types'
import { addMonths, differenceInMonths } from 'date-fns'
import FormMonthPicker from "components/month-picker/month-picker"
import InputNumber from "components/input-number/input-number";
import Page from "components/page/page";
import Estimation from "components/estimation/estimation";
import Anchor from "components/anchor/anchor";
import SelectInput from "components/select-input/select-input"
import { uiShowSnackbar } from 'store/ui_snackbar'


import { updateForecastProject, rateForecastProject, assignForecastProject, reviewedForecastProject, deleteForecastProject } from 'store/forecast_project'
import { ForecastProjectContext } from './project'

import { formatDate, formatIsoDate, formatMonthDate, parseIsoDate } from 'utils/date-helpers';
import { isNull } from 'lodash';
import InputText from 'components/input-text/input-text';
import Spinner from 'components/spinner/spinner';

const valueFormater = (new Intl.NumberFormat('sv-SE')).format

const formatValueRange = (range, ...fallback) => {
  if (range) {
    if (range.gte && range.lte ) {
      return `${valueFormater(range.gte)} - ${valueFormater(range.lte)}`
    }
    return Object.values(range).join(' - ');
  }
  return fallback.filter(f => f !== null).shift() ?? 'Ej officiell';
}

const firstNonNull = (...values) => {
  return values.filter(f => f !== null && f !== undefined).shift() || 'Ej officiell';
}

const getHasLinearRegressionValue = (project) => {
  if (!project.values) {
    return null
  }
  const pv3 = project && project.values && project.values.filter(p => p.providerId === 3).pop()
  if (pv3 != null) {
    // We got a manually modified projekt. Ie Values are not base on automation.
    return false
  }
  const pv2 = project && project.values && project.values.filter(p => p.providerId === 2).pop()
  if (pv2 && pv2.month > 0) {
    // Values are base on automation.
    return true
  }
  return false
}

const ForecastProjectRatingSection = (props) => {
  const dispatch = useDispatch();
  const { forecastRatings, project, fnProject, categories, forecast, constant, user } = useContext(ForecastProjectContext);
  const [isRated, setIsRated] = useState(false)
  const [ratingId, setRatingId] = useState(null)
  const [projectId, setProjectId] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const [valueKr, setValueKr] = useState(null)
  const [month, setMonth] = useState(null)
  const [hasLinearRegressionValue, setHasLinearRegressionValue] = useState(false)
  const [method, setMethod] = useState(null)
  const [isFaktanet, setIsFaktanet] = useState(true)
  useEffect(() => {
    if (project && project.projectId) {
      setIsRated(project.providerId === 3)
      if (project.providerId === 3) {
        setRatingId(project.ratingId)
      } else {
        setRatingId(project.ratingId)
      }
      setProjectId(project.projectId)
      setName(project.name)
      setStartDate(project.startDate && parseIsoDate(project.startDate))
      setEndDate(project.endDate && parseIsoDate(project.endDate))
      setCategoryId(project.categoryId)
      setValueKr(project.value ) 
      setMonth(project.month || null)
      setMethod(project.method || null)
      setHasLinearRegressionValue(getHasLinearRegressionValue(project))
      setIsFaktanet(project.isFaktanet)
    }
  }, [project?.projectId, project?.updatedAt, project?.providerId, project?.values?.length])

  const valid = () => {
    const messages = [
      valueKr === null ? 'byggvärde' : null,
      month === null ? 'byggtid' : null,
      categoryId === null ? 'kategori' : null,
      startDate === null ? 'byggstart' : null,
      endDate === null ? 'färdig' : null
    ].filter(m => !!m)

    if (messages.length > 0) {
      dispatch(uiShowSnackbar({ icon: 'info', message: `Du måste ange följande värden: ${messages.join(', ')}` }))
      return false
    } else {
      return true
    }
  }

  const onSave = () => {
    if (!valid()) return
    const value = valueKr
    const rank = user.isAdmin ? value : null

    if (ratingId !== null) {
      dispatch(rateForecastProject(
        {
          ratingId: ratingId,
          projectId: projectId,
          forecastId: forecast.id
        }
      ))
    }
    dispatch(updateForecastProject(
      {
        name,
        startDate: formatIsoDate(startDate),
        endDate: formatIsoDate(endDate),
        value,
        rank,
        categoryId,
        month,
        projectId,
        forecastId: forecast.id
      }
    ))
    if (user.isAdmin) {
      dispatch(assignForecastProject(
        {
          method: method,
          projectId: projectId,
          forecastId: forecast.id
        }
      ))
    }
  }

  const handleSetMonth = (value) => {
    if (value !== month) {
      if (value === null) {
        setEndDate(null)
        setMonth(null)
      } else {
        const _endDate = addMonths(startDate, value)
        setEndDate(_endDate)
        setMonth(value)
      }
    }
  }

  const handleSetEndDate = (value) => {
    if (value == null) {
      setEndDate(null)
      setMonth(null)
    } else {
      const _month = differenceInMonths(value, startDate)
      if (value !== endDate && _month >= 0) {
        setEndDate(value)
        setMonth(_month)
      } 
    }
  }

  const handleSetStartDate = (value) => {
    if (value == null) {
      setStartDate(null)
      setMonth(null)
    } else {
      setStartDate(value)
      if (month >= 0) {
        setEndDate(addMonths(value,month))
      } else {
        setEndDate(null)
      }
    }
  }

  const handleScrollToByggfakta = () => {
    const element = document.querySelector('#project-byggfakta-section')
    const topPos = element.getBoundingClientRect().top + window.pageYOffset

    window.scrollTo({
      top: topPos,
      behavior: 'smooth'
    })
  }

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const handleDelete = (e) => {
    dispatch(deleteForecastProject({
      projectId: projectId,
      forecastId: forecast.id
    }));
  }

  const onSaveAndReview = () => {
    if (!valid()) return
    onSave()
    handleScrollToTop()
    dispatch(reviewedForecastProject(
      {
        projectId: projectId,
        forecastId: forecast.id
      }
    ))
  }
  const methodOptions = Object.entries(constant.projectMethods).map(([id, label]) => ({ id, value: id, label }))
  const categoryOptions = categories.map(category => ({ id: category.id, value: category.id, label: category.name }))
  const forecastRatingsList = forecastRatings.map(rating => ({ id: rating.ratingId, value: rating.ratingId, label: rating.name }))
  const efnProject = project?.values?.filter(p => p.ProviderId === 1).pop();
  const eProject = project?.values?.filter(p => p.ProviderId === 2).pop();

  if (!project || !fnProject?.raw) {
    return <Spinner />
  }
  return <>
    <Page.Grid {...props} variant="gray" title="Projektinformation" 
      action={
        isFaktanet ? 
          <Anchor onClick={handleScrollToByggfakta}>Bedömningsunderlag från Faktanet</Anchor>
          :
          <>
            <Anchor onClick={() => {}}>Detta projekt kommer inte från Faktanet</Anchor>
            <button className="button" onClick={handleDelete}>Radera projekt</button>
          </>
      }
    >
      {user.isAdmin &&
        <>
          <Page.Block span={2}>
            <label htmlFor="name">Objektnamn</label>
            <InputText name="name" disabled={isFaktanet} required readOnly={isFaktanet} onChange={e => setName(e.target.value)} className={``} placeholder={fnProject.projectName} type="text" value={name} />
          </Page.Block>
          <Page.Block span={2}>
            <label htmlFor="description">Objektsbeskrivning</label>
            <InputText name="description" disabled={isFaktanet} required readOnly={isFaktanet} onChange={e => setDescription(e.target.value)} className={``} placeholder={fnProject.description} type="text" value={description} />
          </Page.Block>
          <Page.Block span={1}>
            <label htmlFor="projectId">ProjektID</label>
            <InputText name="projectId" className={``} placeholder={fnProject.id} type="text" disabled required readOnly value={projectId} />
          </Page.Block>
          <Page.Block span={1}>
            <label htmlFor="projectId">Projektnummer</label>
            <InputText name="projectId" className={``} placeholder={fnProject.source_id} type="text" disabled required readOnly value={fnProject.source_id} />
          </Page.Block>

          <Page.Block span={4} start={1}>
            <h3>Källa</h3>
            <h5>{isFaktanet ? 'Byggfakta' : 'Manuell'} {fnProject.UpdateDate ? formatDate(fnProject.UpdateDate) : ''} </h5>
          </Page.Block>

          <Page.Block span={1} start={1}>
            <label htmlFor="bfStartDate">Byggstart</label>
            <InputText name="bfStartDate" disabled required readOnly type="text" placeholder={firstNonNull(fnProject.raw.start_text, formatDate(fnProject.raw.start_date), formatDate(fnProject.StartDate))} />
          </Page.Block>      
          <Page.Block span={1}>
            <label htmlFor="bfStartDate">Färdigt</label>
            <InputText name="bfStartDate" disabled required readOnly type="text" placeholder={firstNonNull(fnProject.raw.finish_text, formatDate(fnProject.raw.finish_date), formatDate(fnProject.EndDate))}/>
          </Page.Block>      
          <Page.Block span={1}>
            <label htmlFor="bfStartDate">Byggtid</label>
            <InputText name="bfStartDate" disabled required readOnly type="text" placeholder={firstNonNull(fnProject.Month)} />
          </Page.Block>      
          <Page.Block start={1} span={2}>
            <label htmlFor="bfStartDate">Byggvärde</label>
            <InputText name="bfStartDate" disabled required readOnly type="text"  placeholder={formatValueRange(fnProject.raw.value_range, fnProject.raw.value_text, fnProject.raw.value, fnProject.Value)} />
          </Page.Block>      

          { eProject ? 
            <>
              <Page.Block span={4} start={1}>
                <h3>Automatisk bedömning</h3>
                { efnProject.UpdatedAt ? <h5>{formatDate(efnProject.UpdatedAt)}</h5> : <></> }
                { !eProject.Month ? <p>Ingen linjär regission har inte genomförts. </p> : <></> }
              </Page.Block>
              <Page.Block span={1}>
                <label htmlFor="bfStartDate">Byggstart</label>
                <InputText name="bfStartDate" dashedborder disabled required readOnly type="text" placeholder={firstNonNull(fnProject.raw.start_text, formatDate(fnProject.raw.start_date))} />
              </Page.Block>      
              <Page.Block span={1}>
                <label htmlFor="bfStartDate">Färdigt</label>
                <InputText name="bfStartDate" disabled required readOnly type="text" placeholder={formatDate(eProject.EndDate)}/>
              </Page.Block>      
              <Page.Block span={1}>
                <label htmlFor="bfStartDate">Byggtid</label>
                <InputText name="bfStartDate" disabled required readOnly type="text" placeholder={firstNonNull(eProject.Month)} />
              </Page.Block>      
              <Page.Block span={1}>
                <label htmlFor="bfStartDate">Byggvärde</label>
                <InputNumber
                  name="value"
                  value={valueKr}
                  dashedborder 
                  disabled 
                  required 
                  readOnly
                  formater={(new Intl.NumberFormat('sv-SE')).format}
                  placeholder={efnProject.Valuevalue} 
                  unit="kr"
                />
              </Page.Block>      

              <Page.Block start={1} span={2}>
                <label htmlFor="category">Kategori</label>
                <SelectInput
                  name="category"
                  placeholder="Välj kategori"
                  readOnly
                  disabled
                  value={efnProject.CategoryId}
                  options={categoryOptions}
                />
              </Page.Block>
              <Page.Block span={2}>
                <label htmlFor="bfStartDate">Bedömning</label>
                <SelectInput
                  name="rating"
                  placeholder="Bedömningsalternativ"
                  value={efnProject.RatingId}
                  readOnly
                  disabled
                  options={forecastRatingsList}
                />
              </Page.Block>      
            </>
            :
            <>
              <Page.Block span={4} start={1}>
                <h3>Automatisk bedömning saknas</h3>
              </Page.Block>
            </>
          }

        </>
      }
      {user.isAdmin &&
        <>
          <Page.Block span={4} start={1}>
            <h3>Aktuell bedömning</h3>
            <p>{ project.providerId === 3 ? 'Manuell bedömning har gjorts på detta projekt.' : 'Ingen manuell bedömning har gjorts på detta projekt.'}</p>
          </Page.Block>
        </>
      }
      <Page.Block span={1} start={1}>
        <label htmlFor="startDate">Byggstart</label>
        <FormMonthPicker
          name="startDate"
          placeholderText={formatIsoDate(fnProject.raw.startDate)}
          selected={startDate}
          onChange={value => handleSetStartDate(value)}
        />
      </Page.Block>
      <Page.Block span={1}>
        <label htmlFor="endDate">Färdigt</label>
        <FormMonthPicker
          name="endDate"
          selected={endDate}
          placeholderText={formatIsoDate(fnProject.endDate)}
          onChange={value => handleSetEndDate(value)}
        />
      </Page.Block>
      <Page.Block span={1}>
        <label htmlFor="month">Byggtid
          {hasLinearRegressionValue ? <Estimation title="Detta projekt saknar byggtid från Byggfakta. Angivet värde är ett estimat, och beräknats av projektets Byggvärde.">Estimerad byggtid</Estimation> : ''}
        </label>
        <InputNumber
          name="month"
          value={month}
          onValueUpdated={handleSetMonth}
          unit="mån"
        />
      </Page.Block>
      <Page.Block span={1}>
        <label htmlFor="value">Byggvärde</label>
        <InputNumber
          name="value"
          value={valueKr}
          formater={(new Intl.NumberFormat('sv-SE')).format}
          placeholder={fnProject.raw.value} 
          onValueUpdated={setValueKr}
          unit="kr"
        />
      </Page.Block>

      {user.isAdmin &&
        <>
          <Page.Block span={2} start={1}>
            <label htmlFor="category">Bedömningsmetod</label>
            <SelectInput
              onSelect={setMethod}
              name="method"
              placeholder="Välj metod"
              value={method}
              options={methodOptions}
            />

          </Page.Block>
        </>
      }
      <Page.Block span={2}>
        <label htmlFor="category">Kategori</label>
        <SelectInput
          onSelect={setCategoryId}
          name="category"
          placeholder="Välj kategori"
          value={categoryId}
          options={categoryOptions}
        />

      </Page.Block>

      <Page.Block span={4}>
        <h3>Ange byggstartens sannolikhet:</h3>
        <SelectInput
          onSelect={setRatingId}
          name="rating"
          placeholder="Bedömningsalternativ"
          value={ratingId}
          dashedborder={!isRated}
          options={forecastRatingsList}
        />

      </Page.Block>
      <Page.ElevatedBlock span={4}>
        <button onClick={onSave} className="button-2">Spara och fortsätt senare</button>
        <button disabled={isNaN(ratingId) || isNull(ratingId)} onClick={onSaveAndReview} className="button">Spara och skicka in projektet</button>
      </Page.ElevatedBlock>
    </Page.Grid>
  </>

}


ForecastProjectRatingSection.propTypes = {
  project: PropTypes.object,
  progresses: PropTypes.object
}
export default ForecastProjectRatingSection
