import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'

const GET_PROJECT_LOADING = 'GET_PROJECT_LOADING'
const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS'
const GET_PROJECT_ERROR = 'GET_PROJECT_ERROR'



/* ACTIONS */
const getProject = ({projectId}) => (dispatch) => {
  dispatch({
    type: GET_PROJECT_LOADING
  });

  httpClient(`projects/${projectId}`)
    .then(({project, raw}) => {
      dispatch({
        type: GET_PROJECT_SUCCESS,
        payload: {...project, raw}
      });
    })
    .catch(error => {
      dispatch({
        type: GET_PROJECT_ERROR,
        error,
        payload: error
      });
    })
}


/* HOOKS */

export const useProject = (projectId) => {
  const dispatch = useDispatch();
  const { networkState, ...project } = useSelector(state => state.project);

  useEffect(() => {
    if (projectId) {
      dispatch(getProject({projectId}))
    }
  }, [projectId, dispatch]);

  return [project, networkState];
};
/* REDUCER */
const initialState = {
  networkState: WAITING,
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_LOADING:
      return {
        ...state,
        networkState: LOADING
      }
    case GET_PROJECT_ERROR:
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    case GET_PROJECT_SUCCESS:
      return {
        ...action.payload,
        networkState: SUCCESS,
      }

    default:
      return state
  }
}
