import * as types from "store/_types";

/* ACTIONS */
export const uiStartSpinner = () => ({
  type: types.UI_START_SPINNER,
});

export const uiStopSpinner = () => ({
  type: types.UI_STOP_SPINNER,
});

/* REDUCER */
const initialState = {
  show: false
}

export default function (state = initialState, action) {
  if (action.type.endsWith('ERROR')) {
    return {         
      ...initialState
    }
  }
  if (action.type.endsWith('LOADING')) {
    return {         
      show: true
    }
  }
  if (action.type.endsWith('SUCCESS')) {
    return {         
      ...initialState
    }
  }
  switch (action.type) {
    case types.UI_START_SPINNER:{
      return {        
        show: true
      }
    }

    case types.UI_STOP_SPINNER:{
      return {        
        ...initialState
      }
    }

    default:
      return state
  }
}
