import React from 'react'
import PropTypes from 'prop-types'

const CLASS_NS = "modal";

const ModalContentContainer = ({
  classNs=CLASS_NS,
  title="",
  children,
}) => {
  return (
    <>
      <header className={`${CLASS_NS}__header ${classNs}__header`}>
        <div className={`${CLASS_NS}__header__container ${classNs}__header__container`}>
          {title ? <h2 className={`${CLASS_NS}__header__container__title ${classNs}__header__container__title`}>{title}</h2> : null}
        </div>
      </header>

      <main
        className={`${classNs}__content-container`}
      >
        {children}
      </main>
    </>
  )
}

ModalContentContainer.propTypes = {
  classNs: PropTypes.string,
  title:PropTypes.string,
  supertitle:PropTypes.string,
  children: PropTypes.object,
}
export default ModalContentContainer
