import localhost from './localhost'
import dev from './dev'
import prod from './prod'
import prodtest from './prodtest'

let current = {}
const host = window.location.host.split(':').shift()
switch (host) {
  case '192.168.80.61':
  case 'localhost':
    current = localhost
    break;
  case 'bbsabprognosdevstorage.z16.web.core.windows.net':
  case 'victorious-ocean-050028e03.1.azurestaticapps.net':
    current = dev
    break;
  case 'mango-wave-025116603.1.azurestaticapps.net':
    // current = prodtest
    // break;
  case 'bbsabanalysprodstorage.z16.web.core.windows.net':
  case 'prognos.byggforetagen.se':
    current = prod
    break;                                    
  default:
    break;
}

export default current