/* eslint no-use-before-define: 0 */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom"
import query_string from "query-string"
import env from 'environments'

import Details from "components/details/details";
import FormMonthPicker from "components/month-picker/month-picker"
import SelectInput from "components/select-input/select-input"
import InputNumber from "components/input-number/input-number";
import InputText from "components/input-text/input-text";
import Page from "components/page/page";

import { updateProjectFilter, clearProjectFilter } from "store/project_filter"

import { useCategories } from "store/categories"
import { useForecastRatings } from "store/forecast_ratings"
import { useForecastOffices } from "store/forecast_offices"
import { useOfficeList } from "store/offices"
import { useUserProfile } from 'store/profile';
import { formatIsoDate } from 'utils/date-helpers'


const ProjectsFilter = ({ total, queryString, limit }) => {
  const dispatch = useDispatch();
  const filter = useSelector(state => state.project_filter)
  const formRef = useRef();
  const routerParams = useParams();
  const forecastId = routerParams.forecastId ? routerParams.forecastId - 0 : null;

  const [nextFilter, setNextFilter] = useState(filter)

  const [user] = useUserProfile()
  const [categories] = useCategories()
  const [offices] = useOfficeList();

  const [forecastRatings] = useForecastRatings(forecastId)
  const [forecastOffices] = useForecastOffices(forecastId);
  const [errorMessages] = useState(new Map());

  useEffect(() => {
    setNextFilter(filter)
  }, [filter.rev])

  useEffect(() => {
    if (!user.isAdmin) {
      const cachedOfficeId = localStorage.getItem('cachedOfficeId') - 0
      if (cachedOfficeId > 0 && filter.officeId !== cachedOfficeId) {
        setNextFilter({ ...nextFilter, officeId: cachedOfficeId })
        onShowResult()
        dispatch(updateProjectFilter({ officeId: cachedOfficeId }));
      }
    }
  }, [])

  const onChangeName = (name) => (value) => {
    setNextFilter({ ...nextFilter, [name]: value })
  };

  const onSelectOfficeId = (value) => {
    if (!user.isAdmin) {
      localStorage.setItem('cachedOfficeId', value)
    }
    setNextFilter({ ...nextFilter, officeId: value })
  }

  const onSelectCategoryId = (value) => {
    setNextFilter({ ...nextFilter, categoryId: value })
  }

  const onSelectRatingId = (value) => {
    setNextFilter({ ...nextFilter, ratingId: value })
  }

  const onChangeStartDateMin = (startDateMin) => {
    const startDateMinIso = formatIsoDate(startDateMin)
    setNextFilter({ ...nextFilter, startDateMin, startDateMinIso })
  }
  const onChangeStartDateMax = (startDateMax) => {
    const startDateMaxIso = formatIsoDate(startDateMax)
    setNextFilter({ ...nextFilter, startDateMax, startDateMaxIso })
  }

  const onChangeValueMax = (value) => {
    const mValue = value !== null ? value * 1000 * 1000 : null
    setNextFilter({ ...nextFilter, valueMax: mValue, valueMaxDisplay: value })
  }

  const onChangeValueMin = (value) => {
    const mValue = value !== null ? value * 1000 * 1000 : null
    setNextFilter({ ...nextFilter, valueMin: mValue, valueMinDisplay: value })
  }

  const onChangeMonthMax = (value) => {
    setNextFilter({ ...nextFilter, timeMax: value })
  }

  const onChangeMonthMin = (value) => {
    setNextFilter({ ...nextFilter, timeMin: value })
  }

  const onSelectSource = (source) => {
    setNextFilter({ ...nextFilter, source })
  }

  const onClearFilter = () => {
    dispatch(clearProjectFilter({ rev: filter.rev + 1 }));
  }
  
  const handleToggle = (isOpen) => {
    setNextFilter({ ...nextFilter, isOpen })
    dispatch(updateProjectFilter({ isOpen }));
  }

  const onShowResult = () => {
    dispatch(updateProjectFilter({ ...nextFilter, rev: filter.rev + 1 }));
    handleToggle(false)
  }

  if (!user.isAdmin) {
    return (
      <Page.Grid columns={4}>
        <Page.Block span={1} className="projects__page__search__header">
          <h3>Välj din region:</h3>
        </Page.Block>
        <Page.Block span={2}>
          <SelectInput
            onSelect={onSelectOfficeId}
            name="office"
            empty="Prognosregioner"
            value={nextFilter.officeId}
            options={forecastOffices.map(office => ({ id: office.id, value: office.id, label: `${office.name}` }))}
            disabled={forecastOffices.length ? false : true}
          />
        </Page.Block>
        <Page.Block start={4} span={1}>
          <button
            className="button"
            onClick={onShowResult}
          >Visa projekt</button>
        </Page.Block>
      </Page.Grid>
    )
  }

  var q = query_string.parse(queryString)
  var queryParams = {
    filter: Array.isArray(q.filter) ? q.filter : 
      typeof q.filter === 'string' ? [q.filter] : [],
    forecastId
  } 
  return (
    <Details
      defaultOpen={nextFilter.isOpen}
      onToggle={handleToggle}
      info={total > 0 ? `Visar ${Math.min(limit, total)} av ${total}` : ''}
      label="Sök och filtrera"
    >
      <form
        method="dialog"
        ref={formRef}
      >
        <Page.Grid columns={4}>
          <Page.Block span={2}>
            <label className="form-layout__label" htmlFor="name">Projektnamn och ID</label>
            <InputText
              className="form-layout__text-field"
              placeholder="Sök projektnamn eller ID"
              name="name"
              value={nextFilter.name}
              onValueUpdated={onChangeName("name")}
            />
            <p className="input-error">{errorMessages.has("name") ? errorMessages.get("name") : null}</p>
          </Page.Block>
          {/* <Page.Block span={2}>
          <label className="form-layout__label" htmlFor="constructionFirm">Byggherre</label>
          <input
            className="form-layout__text-field"
            type="text"
            placeholder="Sök byggherre"
            name="constructionFirm"
            maxLength="255"
            minLength="2"
            value={""}
            onChange={onChange}
          />
          <p className="input-error">{errorMessages.has("constructionFirm") ? errorMessages.get("constructionFirm") : null}</p>
        </Page.Block> */}
          <Page.Block span={2}>
            <label className="form-layout__label" htmlFor="rating">Bedömning</label>
            <SelectInput
              empty="Alla bedömningsalternativ"
              onSelect={onSelectRatingId}
              name="rating"
              value={nextFilter.ratingId}
              options={forecastRatings.map(rating => ({ id: rating.id, value: rating.rank, label: `${rating.rank} : ${rating.name}` }))}
              disabled={forecastRatings.length ? false : true}
            />
            <p className="input-error" data-error={errorMessages.has("rating")}>{errorMessages.has("rating") ? errorMessages.get("rating") : null}</p>
          </Page.Block>
          {/* <Page.Block span={2}>
          <label className="form-layout__label" htmlFor="contractor">Entreprenör</label>
          <input
            className="form-layout__text-field"
            type="text"
            placeholder="Sök entreprenör"
            name="contractor"
            maxLength="255"
            minLength="2"
            value={""}
            onChange={onChange}
          />
          <p className="input-error">{errorMessages.has("contractor") ? errorMessages.get("contractor") : null}</p>
        </Page.Block> */}
          <Page.Block span={2}>
            <label className="form-layout__label" htmlFor="office">Prognosregion</label>
            <SelectInput
              empty="Alla regioner"
              onSelect={onSelectOfficeId}
              name="office"
              value={nextFilter.officeId}
              options={offices.filter(office => office.active).map(office => ({ id: office.id, value: office.id, label: office.name }))}
              disabled={offices.length ? false : true}
            />
            <p className="input-error" data-error={errorMessages.has("office")}>{errorMessages.has("office") ? errorMessages.get("office") : null}</p>
          </Page.Block>
          <Page.Block span={2}>
            <label className="form-layout__label" htmlFor="category">Kategori</label>
            <SelectInput
              empty="Alla kategorier"
              onSelect={onSelectCategoryId}
              name="category"
              value={nextFilter.categoryId}
              options={categories.map(category => ({ id: category.id, value: category.id, label: category.name }))}
              disabled={categories.length ? false : true}
            />
            <p className="input-error">{errorMessages.has("category") ? errorMessages.get("category") : null}</p>
          </Page.Block>
          <Page.Block span={1}>
            <label className="form-layout__label" htmlFor="valueMin">Byggvärde (minst)</label>
            <InputNumber
              autoComplete="off"
              placeholder=""
              name="valueMin"
              value={nextFilter.valueMinDisplay}
              onValueChanged={onChangeValueMin}
              unit="mkr"
            />
            <p className="input-error" data-error={errorMessages.has("valueMin")}>{errorMessages.has("valueMin") ? errorMessages.get("valueMin") : null}</p>
          </Page.Block>
          <Page.Block span={1}>
            <label className="form-layout__label" htmlFor="valueMax">Byggvärde (mest)</label>
            <InputNumber
              autoComplete="off"
              placeholder=""
              name="valueMax"
              value={nextFilter.valueMaxDisplay}
              onValueChanged={onChangeValueMax}
              unit="mkr"
            />
            <p className="input-error" data-error={errorMessages.has("valueMax")}>{errorMessages.has("valueMax") ? errorMessages.get("valueMax") : null}</p>
          </Page.Block>
          <Page.Block span={1}>
            <label className="form-layout__label" htmlFor="timeMin">Byggtid (minst)</label>
            <InputNumber
              autoComplete="off"
              name="timeMin"
              value={nextFilter.timeMin}
              onValueChanged={onChangeMonthMin}
              unit="mån"
            />
            <p className="input-error" data-error={errorMessages.has("timeMin")}>{errorMessages.has("timeMin") ? errorMessages.get("timeMin") : null}</p>
          </Page.Block>
          <Page.Block span={1}>
            <label className="form-layout__label" htmlFor="timeMax">Byggtid (mest)</label>
            <InputNumber
              autoComplete="off"
              name="timeMax"
              value={nextFilter.timeMax}
              onValueChanged={onChangeMonthMax}
              unit="mån"
            />
            <p className="input-error" data-error={errorMessages.has("timeMax")}>{errorMessages.has("timeMax") ? errorMessages.get("timeMax") : null}</p>
          </Page.Block>
          <Page.Block span={1}>
            <label className="form-layout__label" htmlFor="startDateMin">Byggstart (efter)</label>
            <FormMonthPicker
              name="startDateMin"
              placeholderText="Välj månad"
              selected={nextFilter.startDateMin}
              onChange={onChangeStartDateMin}
            />
          </Page.Block>
          <Page.Block span={1}>
            <label className="form-layout__label" htmlFor="startDateMax">Byggstart (innan)</label>
            <FormMonthPicker
              name="startDateMax"
              placeholderText="Välj månad"
              selected={nextFilter.startDateMax}
              onChange={onChangeStartDateMax}
            />
          </Page.Block>
          <Page.Block span={1}>
            <label className="form-layout__label" htmlFor="source">Källa</label>
            <SelectInput
              empty="Alla källor"
              onSelect={onSelectSource}
              name="source"
              value={nextFilter.source}
              options={[
                { id: 'byggfakta', value: 'byggfakta', label: 'Visa byggfakta' },
                { id: 'manual', value: 'manual', label: 'Visa manuella' },
              ]}
            />
          </Page.Block>
          <Page.Block span={4}></Page.Block>

          <Page.Block start={1} span={1}>
            <a target="_blank" download href={`${env.FUNC_BASE}ExportForecastProjects?q=${encodeURIComponent(JSON.stringify(queryParams))}`}
              className="button-2"
              disabled={filter.changed}
            >Ladda ner</a>

            {/* <a download href={`${env.API_BASE}api/forecasts/${forecastId}/projects/export${queryString}`}
              className="button-2"
              disabled={filter.changed}
            >Ladda ner</a> */}
          </Page.Block>

          <Page.Block start={3} span={1}>
            <button
              className="button-2"
              onClick={onClearFilter}
            >Rensa filter</button>
          </Page.Block>
          <Page.Block start={4} span={1}>
            <button
              className="button"
              onClick={onShowResult}
            >Visa resultat</button>
          </Page.Block>
        </Page.Grid>
      </form>
    </Details>
  )
}

export default ProjectsFilter
