import React, { useRef, useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2';
import { saveAs } from 'file-saver';
import { useSanity } from 'store/sanity';
import BlockContent from '@sanity/block-content-to-react'
import useWindowSize from 'utils/use-window-size'
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";

import { tabChoices } from 'utils/constants';
import { rangeTo } from 'utils/data-helpers';
import { categoryIdToLabel } from 'utils/data-helpers'
import "components/graph/_graph.scss"

const CLASS_NS = "graph";
const HIDDEN_LEGEND_MARKER = "HIDDEN";

const isForecast = (numberOfForecastYears, currentYear, endYear) => {
  return currentYear + numberOfForecastYears >= endYear
}

const datasetColor = (index) => {
  switch (index) {
    case 3:
      return {
        borderColor: '#3EB310',
        pointBackgroundColor: '#3EB310',
      }
    case 2:
      return {
        borderColor: '#FF7200',
        pointBackgroundColor: '#FF7200',
      }
    case 1:
      return {
        borderColor: '#8A0000',
        pointBackgroundColor: '#8A0000',
      }
    default:
      return {
        borderColor: '#2C7C91',
        pointBackgroundColor: '#2C7C91',
      }
  }
}

const datasetsBase = (index) => ({
  label: 'Label',
  fill: false,
  lineTension: 0,
  borderColor: '#64744B',
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.3,
  borderJoinStyle: 'miter',
  pointBackgroundColor: '#64744B',
  pointBorderWidth: 4,
  hoverRadius: 6,
  showLine: true,
  spanGaps: true,
  ...datasetColor(index)
})

const maskForecastYears = (numberOfForecastYears, values) => {
  return [...values.slice(0, values.length - numberOfForecastYears), null, null]
}

const buildChartJsDataset = (forecastStart, forecastEnd) => ({ values, label, index }) => {
  const numberOfForecastYears = forecastEnd - forecastStart + 1

  return [
    {
      ...datasetsBase(index),
      borderDash: [0, 0],
      label: `${label}`,
      data: maskForecastYears(numberOfForecastYears, values)
    },
    {
      ...datasetsBase(index),
      borderDash: [10, 3],
      label: `${HIDDEN_LEGEND_MARKER} ${label}`,
      data: values
    },
  ]
}

const buildGraphData = (categoryId, forecastStart, forecastEnd, data) => {
  const categoryLabel = categoryIdToLabel(categoryId)
  const dataList = data.filter(d => d.categoryLabel === categoryLabel).map((d, index) => ({
    values: d.values.map(v => v - 0),
    label: d.subCategoryLabel,
    index
  })
  )
  if (dataList.length === 0) {
    return false
  }
  const length = dataList[0].values.length
  const labels = rangeTo(forecastEnd, length)
  const datasets = dataList.map(buildChartJsDataset(forecastStart, forecastEnd)).flat()

  return {
    labels,
    datasets,
  }
}

const handleLegendClick = function (e, legendItem) {
  const label = legendItem.text;
  let ci = this.chart;
  let re = new RegExp(`${label}`, 'i')

  for (let i = 0; i < ci.data.datasets.length; i++) {
    const meta = ci.getDatasetMeta(i)
    const ds = ci.data.datasets[i]
    if (re.test(ds.label)) {
      meta.hidden = meta.hidden === null ? !ds.hidden : null;
    }
  }
  ci.update();
};

const handleFilterLegend = function (legendItem) {
  return legendItem && legendItem.text.indexOf(HIDDEN_LEGEND_MARKER) !== 0
}

const labelStyle = {
  fontColor: "#2e3a40",
  fontFamily: "'Circular', sans-serif"
}
const tooltipStyle = {
  displayColors: false,
  backgroundColor: "#f6f8f9",
  cornerRadius: 3,
  titleFontColor: "#2e3a40",
  titleFontFamily: "'Circular', sans-serif",
  titleFontSize: 18,
  bodyFontColor: "#2e3a40",
  bodyFontFamily: "'Circular', sans-serif",
  bodyFontSize: 18
}

const graphOptions = (graphData, windowWidth, settings) => {

  if (!graphData || !graphData.labels) return {}
  const { forecastStart, forecastEnd } = settings

  const graphPadding = windowWidth < 550 ? 30 : 0;
  const legendFontSize = windowWidth < 550 ? 12 : 16;
  const legendAlign = windowWidth < 550 ? 'bottom' : 'top'
  const numberOfForecastYears = forecastEnd - forecastStart + 1
  const length = graphData.labels.length
  const axisColors = graphData.labels.map((label, index) => index === length - numberOfForecastYears - 1 ? '#333' : '#c9cecf')
  return {
    responsive: true,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: graphPadding,
        bottom: 0
      }
    },
    tooltips: {
      ...tooltipStyle,
      caretSize: 10,
      xPadding: 16,
      yPadding: 20,
      callbacks: {
        title: function (tooltipItem, data) {
          const index = tooltipItem[0].index;
          return isForecast(numberOfForecastYears, index, length) ? `Prognos ${data.labels[index]}` : `Utfall ${data.labels[index]}`
        },
        label: function (tooltipItem, data) { // TODO: Hide label for Prognos when both are shown
          const index = tooltipItem.index;
          const datasetIndex = tooltipItem.datasetIndex;
          var label = data.datasets[datasetIndex].label
          var value = parseInt(tooltipItem.value)
          if (isForecast(numberOfForecastYears, index, length) || label.indexOf(HIDDEN_LEGEND_MARKER) < 0) {
            label = label.replace(HIDDEN_LEGEND_MARKER, '')
            return `${label}: ${value.toLocaleString('sv-SE')} ${settings.region_data_graph_yaxisis_unit}`
          }
          return ''
        }
      },
    },
    legend: {
      display: true,
      position: legendAlign,
      align: "end",
      padding: 50,
      labels: {
        ...labelStyle,
        fontSize: legendFontSize,
        fontStyle: "bold",
        boxWidth: 5,
        padding: 20,
        lineCap: "round",
        usePointStyle: true,
        filter: handleFilterLegend
      },
      onClick: handleLegendClick
    },
    scales: {

      xAxes: [{
        gridLines: {
          display: true,
          lineWidth: 2,
          color: axisColors,
          tickMarkLength: 0,
        },
        ticks: {
          ...labelStyle,
          fontSize: legendFontSize,
          padding: 12,
        },
      }],
      yAxes: [{
        gridLines: {
          display: true,
          lineWidth: 2,
          color: "#c9cecf",
          tickMarkLength: 20,
          drawBorder: false
        },
        ticks: {
          display: true,
          beginAtZero: false,
          ...labelStyle,
          fontSize: legendFontSize,
          padding: 12,
          callback: function (label = 0) {
            return label.toLocaleString('sv-SE');
          }

        },
      }],
    },
    aspectRatio: 6,
    maintainAspectRatio: false
  }
}

const Graph = ({
  classNs = CLASS_NS,
  data,
  categoryId,
  locationId
}) => {
  const [graphData, setGraphData] = useState({})
  const graphRef = useRef();
  const [{ countyFlat, settings }] = useSanity()
  const { forecastStart, forecastEnd, graph_footer } = settings
  const windowWidth = useWindowSize();

  useEffect(() => {

    setGraphData(buildGraphData(categoryId, forecastStart, forecastEnd, data))
  }, [categoryId, data]);

  const graphTitle = () => {
    const activeRegion = countyFlat.find(c => c.id === locationId);
    const activeTab = tabChoices.find(c => c.value === categoryId);
    if (categoryId === 'alla') {
      return `Alla bygginvesteringar för ${activeRegion.title}`
    } else {
      return `Bygginvesteringar för ${activeTab.label}, ${activeRegion.title}`
    }
  }

  const fillGraphBackground = (graph, color) => {
    const context = graph.getContext('2d');
    context.save();
    context.globalCompositeOperation = 'destination-over';

    context.fillStyle = color;
    context.fillRect(0, 0, graph.width, graph.height);

    context.restore();
  }

  const downloadGraph = () => {
    const graph = document.getElementById('graph');
    fillGraphBackground(graph, 'white');
    if (graph) {
      return graph.toBlob(function (blob) {
        saveAs(blob, `${graphTitle()}.png`)
      })
    } else {
      return null
    }
  }

  return (
    <div className={`${classNs} `}>
      <label className={`${classNs}__title`}>{graphTitle()}</label>
      {graphData && <div className={`${classNs}__wrapper`}>
        <span className={`${classNs}__wrapper__yLabel`}>{settings.region_data_graph_yaxisis_unit}</span>
        <Line id="graph" ref={graphRef} data={graphData} options={graphOptions(graphData, windowWidth, settings)} width={100} height={25} style={labelStyle} />
        <div className={`${classNs}__content`}>
          {graph_footer && <BlockContent blocks={graph_footer} />}
          <button className="button-link-red" onClick={downloadGraph}>Spara som bild<DownloadIcon className="button-link-red_icon" /></button>
        </div>
      </div>
      }
    </div>
  )
}

export default Graph