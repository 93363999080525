import React from 'react'
import './_data-list.scss'

const DataList = ({header, footer, children}) => {


  return (
    <div className="data-list">
      {
        header ? (
          <div className="data-list__header">
            {header}
          </div>
        ) :
          null
      }
      <div className="data-list__body">
        {children}
      </div>
      {
        footer ? (
          <div className="data-list__footer">
            {footer}
          </div>
        ) :
          null
      }
    </div>
  )
}

export default DataList
