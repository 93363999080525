import React from "react";
// import {useDispatch} from "react-redux"
import { CallbackComponent } from "redux-oidc";
import userManager from "utils/userManager";
import { useHistory } from "react-router-dom";

const Callback = () => {
  const history = useHistory()
  let errorMessage = ""

  // define a success callback which receives the signed in user & handles redirection
  const onSuccess = user => {
    const redirectUrl = user.state ? user.state.redirectUrl : "/forecast";
    history.push(redirectUrl)
  }

  // TODO: show error modal something similar when such a component exists.
  const onError = error => {
    let { message } = error;
    if (message.includes('iat is in the future') ||
      message.includes('exp is in the past')) {
      message = 'Inloggningen misslyckades, det verkar vara problem med tidsinställningen på enheten som du försöker logga in ifrån, kontrollera att klockan är rätt inställd.'
    } else {
      message = 'Inloggningen misslyckades.'
    }
    errorMessage = message;
  }

  const onLoginClick = () => {
    history.push('/login')
  }

  return <>
    <CallbackComponent
      userManager={userManager}
      successCallback={onSuccess}
      errorCallback={onError}
    >
      <div>{errorMessage ?
        <>
          <p>errorMessage</p>
          <p><button onClick={onLoginClick}>Försök logga in igen</button></p>
        </>
        : 'Omdirigerar...'}
      </div>
    </CallbackComponent>
  </>
}

export default Callback;
