import React from "react";
import PropTypes from "prop-types";

import "components/estimation/_estimation.scss"
const CLASS_NS = "estimation";
const Estimation = ({classNs = CLASS_NS,children, title, ...rest }) => {
  return <span title={title} className={classNs} {...rest}>{children}</span>
};

Estimation.propTypes = {
  classNs: PropTypes.string
};
export default Estimation;
