import React from 'react'
import classNames from 'classnames'
import {isFunction} from "utils/js-helpers"
import './_data-table.scss'


export const emptyCellData = {
  label: "",
  columnFunc: null,
  cellFunc: null,
  cellContent: null,
  cellClassName: null,
  formatter: (rowData, key)=>rowData[key],
}

export const mergeWithEmptyCellData = data =>({
  ...emptyCellData,
  ...data
})


/**
 * getColumnDataFromRow - Get a plain column object which is then used for the
 * DataTable conmponent.
 * If a column need additional functionality then use this function as a base
 * and customise it to your own needs.
 *
 * @param {object} dataRow An objrct with key-values pairs
 *
 * @returns {object} A valid empty cell data object
 */
export const getColumnDataFromRow = dataRow => {
  return Object.keys(dataRow).reduce( (acc, key)=>{
    acc[key] = mergeWithEmptyCellData({label: key})
    return acc;
  }, {})
}


const DataTableRow = ({
  lineAt = false, 
  rowData={},
  rowSchema=[]
}) => {
  const cls = lineAt ? 'data-table__lineAt' : ''
  return (<tr className={cls}>
    {
      rowSchema.map((value)=>{
        const key = value.name
        const schema = mergeWithEmptyCellData(value)
        const isFunc = isFunction(schema.cellFunc)
        const isChanged = rowData['_changed'][key] !== false
        return <td
          key={key}
          data-changed={isChanged}
          className={isFunc ? `data-table__clickable` : ''}
          onClick={ isFunc ? ()=>{schema.cellFunc(rowData.id, rowData)} : null}
        >{
          isChanged ? schema.formatter(rowData, key) : '|'
        }{
          schema.cellContent
        }</td>
      }
      )
    }
  </tr>)

}

/**
 * DataTable - Description
 * The table column data dictates which data items get places in the rows cells,
 * their formatting, interaction and additional content.
 *
 * @param {object} Unknown Description
 *
 * @returns {*} A React comnponent
 */
const DataTable = ({
  columnData=[],
  bodyData=[],
  emptyMessage,
  showHeader=true,
  onOrderClick,
  lineAt = false,
  isAdmin = true,
  hiderepeatedvalue = false,
  sortBy,
  sortDir,
  children,
  footer

}) => {

  if (columnData && !Array.isArray(columnData)) {
    columnData = Object.entries(columnData).map(([key, value]) => { value.id = key; return value })
  }
  if (children) {
    columnData = React.Children.map(children, child => child?.props)
  }

  const renderBody = bodyData && bodyData.length > 0 || !emptyMessage
  if (bodyData && bodyData[0]) {

    const length = bodyData[0].length
    const prevRow = Array(length)
    bodyData.forEach((v, i) => {
      v['_changed'] = {}
      if (i === lineAt) {
        v['_lineAt'] = true
      }
      var lrchanged = false
      columnData.forEach((prop, i) => {
        const key = prop.name
        const value = v[key]
        lrchanged = lrchanged || prevRow[key] !== value || !prop.hiderepeatedvalue
        v['_changed'][key] = lrchanged
        prevRow[key] = value
      })
    })
  }
  const cls = classNames('data-table', {
    [`data-table--isAdmin`]: isAdmin,
    [`data-table--isUser`]: !isAdmin,
  })
  return (
    <table data-nowrap className={cls}>
      {
        showHeader ? (
          <thead>
            <tr>
              {
                columnData.map( props => <Column key={props.name} {...props} onClick={onOrderClick} sortBy={sortBy} direction={sortBy === props.name && sortDir }/> )
              }
            </tr>
          </thead>
        ) :
          null
      }
      { renderBody &&
        <tbody> 
          {
            Object.values(bodyData).map( (value,index) => 
              <DataTableRow key={index} lineAt={index === lineAt} rowData={value} rowSchema={columnData} /> 
            )
          }
        </tbody>
      }
      { !renderBody &&
        <tfoot> 
          <tr>
            <td colSpan={columnData.length}>
              {emptyMessage}
            </td>
          </tr>
        </tfoot>
      }
      { footer && 
        <tfoot> 
          <tr>
            <td colSpan={columnData.length}>
              {footer}
            </td>
          </tr>
        </tfoot>
      }
    </table>
  )
}

const Column = ({label, name, sortable, direction, onClick}) => {
  const handleClick = (columnname) => {
    if (onClick) {
      onClick(columnname)
    }
  }

  const cls = classNames('data-table__column', {
    [`data-table__column--${direction}`]: !!direction,
    [`data-table__column--sortable`]: !!sortable,
  })

  return (<th 
    onClick={() => handleClick(name)} 
    className={cls}
    key={name}>{label}</th>
  )
}

DataTable.Column = Column

DataTable.propTypes = {

}
export default DataTable
