import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import { useLocation, useHistory } from "react-router-dom";

import HeaderMain from 'components/header-main/header-main';
import Footer from 'components/footer/footer';
import Logo from "components/logo/logo"
import Spinner from "components/spinner/spinner";
import Snackbar from "components/snackbar/snackbar";
import Checked from "components/checked/checked";

import { useUserProfile } from 'store/profile'
import { useConstant } from 'store/constants';
import "layouts/_main.layout.scss"

const MainLayout = (props) => {

  const [user] = useUserProfile()
  const [constant] = useConstant()
  const location = useLocation()
  const history = useHistory()
  const {loggedin, isLoadingUser} = user
  useEffect(() => {
    if (!loggedin && !isLoadingUser) {
      history.push('/login')
    }
  }, [loggedin, isLoadingUser])
  const { children } = props;
  if (constant === null) {
    return null
  }
  const homeUrl = user.isAdmin ? "/forecast" : `/forecast/${constant.defaultForecastId}/projects`
  return (
    <>
      <HeaderMain
        location={location}
        user={user}
        navItems={[
          {
            label: user.isAdmin ? "Pågående prognoser" : "Aktuell prognos",
            link: homeUrl
          },
          {
            label: "Tidigare prognoser",
            link: "/previous-forecast",
            disabled: !user.isAdmin
          }
        ]}
        logo={<Logo url={homeUrl} classNs="header-main_logo" logoType="Bedömningsverktyget" />}
        {...props}
      >
      </HeaderMain>
      <main className="main">
        { children }
      </main>
      
      <Footer>
        <dl className="footer__contact">
          <div>
            <dt className="footer__contact__header">Kontakt</dt>
            <dd className="footer__contact__detail"><a href="tel:+4686985803">08-698 58 03</a></dd>
            <dd className="footer__contact__detail"><a href="mailto:emil.flodin@byggforetagen.se">emil.flodin@byggforetagen.se</a></dd>
          </div>
        </dl>
        <Logo url={homeUrl} classNs={`footer-logo`} logoType="Bedömningsverktyget" />
      </Footer>
      <Spinner />
      <Checked />
      <Snackbar />
    </>
  )
}

MainLayout.propTypes = {
  classNs: PropTypes.string,
  location: PropTypes.object,
  route: PropTypes.object
}

export default MainLayout
