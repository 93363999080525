import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as PlayIcon } from "assets/icons/play.svg"

import "components/timeline/_timeline.scss"

const CLASS_NS = "timeline";

function Timeline({
  classNs = CLASS_NS,
  inputLabel,
  labels,
  value,
  onSelect = () => { },
}) {

  const [inputValue, setInputValue] = useState('')
  const [intervalId, setIntervalId] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (typeof value === 'undefined') {
      setInputValue('')
    } else if (value === null) {
      setInputValue('')
    } else {
      setInputValue(value)
    }
  }, [value])

  const handleChange = (e) => {
    const _value = e.target.value
    if (isPlaying) {
      clearInterval(intervalId)
      setIndex(labels.indexOf(_value))
    }
    if (_value == inputValue) {
      onSelect(inputValue, e)
      setIndex(labels.indexOf(inputValue))
    } else {
      onSelect(_value, e)
      setIndex(labels.indexOf(_value))
    }
  }

  const onClick = (e) => {
    setIsPlaying(true)
    let i = 0
    if (isPlaying) {
      clearInterval(intervalId)
      setIsPlaying(false)
      setIndex(labels.indexOf(inputValue))
      onSelect(inputValue, e)
    } else {
      i = index
      const interval = setInterval(function () {
        if (i < labels.length - 1) {
          setIntervalId(interval)
          setIsPlaying(true)
          onSelect(labels[i++], e)
        } else {
          clearInterval(interval);
          setIsPlaying(false)
          setIndex(0)
          onSelect(labels[labels.length - 1], e)
        }
      }
      , 1000);
    }
  }
  if (!labels.length) return []

  return (
    <>
      <div className={`${classNs}`}>
        <label htmlFor="timeline" className={`${classNs}__label`}>{inputLabel}</label>
        <div className={`${classNs}__slider-container`}>
          <div className={`${classNs}__icon-wrapper`}>
            <PlayIcon className="icon" onClick={() => onClick()} />
          </div>
          <div className={`${classNs}__slider`}>
            <input
              type="range"
              min={labels[0]}
              max={labels[labels.length - 1]}
              className={`${classNs}__slider__input`}
              value={inputValue}
              id="timeline"
              onChange={(e) => handleChange(e)}
            />
            <div id="labels" className={`${classNs}__slider__labels`}>
              {
                labels.map(year => {
                  return (
                    <span key={year} className={`${classNs}__slider__labels__label`}>{year}</span>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Timeline.propTypes = {
  classNs: PropTypes.string,
  inputLabel: PropTypes.string,
  value: PropTypes.string,
  labels: PropTypes.array,
  onSelect: PropTypes.func
}

export default Timeline
