import React from 'react';
import PropTypes from 'prop-types'
import menu from 'assets/icons/meny.svg';
import cross from 'assets/icons/cross.svg';

import './_side-nav.scss'
const CLASS_NS = "side-nav"



/**
 * An expandable navigation element which reveals a side navigation
 * @return {*} A nav component
 */
const SideNav = ({
  classNs=CLASS_NS,
  wrapperRef,
  openState,
  children,
  onClick=()=>{}
}) => {

  return (
    <nav className={`${CLASS_NS} ${classNs}`} data-expanded={openState} aria-expanded={openState} ref={wrapperRef}>
      <dl className={`${CLASS_NS}__list`}>
        <dt className={`${CLASS_NS}__toggle`} onClick={onClick}>
          <img src={openState ? cross : menu} className={`${CLASS_NS}__toggle-icon`} alt="menu icon" />
        </dt>
        {children}
      </dl>
    </nav>
  )
}

SideNav.propTypes = {
  classNs: PropTypes.string,
  className: PropTypes.string,
  openState: PropTypes.bool,
  isOpen: PropTypes.bool,
  children: PropTypes.any
}
export default SideNav
