import React, { useEffect, useState } from 'react'

const DEFAULT_DESCRIPTION = "växlande knappar"
const CLASS_NS = "radio-group"
/*
 * A controlled component which uses native HTML radio input fields.
 * The radio buttons ´checked´ value is boolean (instead of native "checked" value)
 * which signals to Ract to make the component controlled.
 */
const RadioGroup = ({
  choices = [],
  selected = "",
  onChange = () => { },
  groupName = "radioButtons",
  classNs = CLASS_NS,
  description = DEFAULT_DESCRIPTION,
  title = "",
  children
}) => {
  // Internal state which triggers a visual state change
  const [selectedState, setSelectedState] = useState("");  // eslint-disable-line no-unused-vars

  useEffect(() => {
    setSelectedState(selected)
  }, [selected])

  return (
    <div className={classNs}>
      {title ? <h4 className={`${classNs}__title`}>{title}</h4> : null}
      <form
        className={`${classNs}__form`}
        role="radiogroup"
        aria-describedby={description}
      >
        {choices.map((choice, index) => (
          <label
            key={index}
            className={`${classNs}__label`}
            data-label={choice.labelTooltip || null}
          >
            <input
              className={`${classNs}__input`}
              type="radio"
              name={groupName}
              value={choice.value}
              checked={choice.value === selected}
              onChange={onChange}
              disabled={choice.disabled || false}
            />
            {choice.label ? <span className={`${classNs}__text`}>{choice.label}</span> : null}
          </label>
        ))
        }
        {children}
      </form>
    </div>
  )
}

export default RadioGroup;
