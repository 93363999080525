import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

const CLASS_NS="details";

// A React wrapper component for the browser native <details> element
// See: developer.mozilla.org/en-US/docs/Web/HTML/Element/details
const Details = ({
  classNs=CLASS_NS,
  children,
  label,
  info,
  onToggle,
  defaultOpen=true
}) => {

  const [isOpen, setIsOpen] = useState(defaultOpen)
  useEffect(() => {
    setIsOpen(defaultOpen)
  }, [defaultOpen])

  const handleToggle = e=>{
    setIsOpen(e.target.open)
    if (onToggle) {
      onToggle(e.target.open)
    }
  }
  return (
    <details
      className={`${classNs}`}
      open={isOpen}
      onToggle={handleToggle}
    >
      <summary className={`${classNs}_summary`}>
        <span className={`${classNs}_summary_label`}>{label}</span>
        { info && <span className={`${classNs}_summary_info`}>{info}</span> }
      </summary>
      <section className={`${classNs}_content`}>{children}</section>
    </details>
  )
}

Details.propTypes = {
  classNs: PropTypes.string,
  label: PropTypes.string
}
export default Details
