import React from 'react'
// 
import userManager from "utils/userManager";
import Anchor from "components/anchor/anchor";
import "routes/login/_login.scss"

const Login = () => {

  const handleLogin = () => {
    sessionStorage.setItem('isAdmin', 'false')
    userManager.signinRedirect();
  }

  const handleAdminLogin = () => {
    sessionStorage.clear();
    localStorage.clear();
    sessionStorage.setItem('isAdmin', 'true')
    userManager.signinRedirect();
  }
  
  return (
    <article className="login-page">
      <h4 className="login-page__subheader">Prognoswebben</h4>
      <h2 className="login-page__header">Bedömningsverktyget</h2>
      {/* <p className="login-page__intro">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
      <button className="button" onClick={handleLogin}>Logga in</button>
      <p className="login-page__intro">
        <Anchor
          onClick={handleAdminLogin}
        >
          Administratör
        </Anchor>
      </p>
    </article>
  )
}

export default Login
