/* global ReactGA */
import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import ScrollToTop from 'containers/scroll-to-top'
import NavigatorOnline from 'containers/navigator-online'
import env from 'environments'
import ReactGA from "react-ga4";

import Start from 'routes/start/start'
import Report from 'routes/report/report'
import News from 'routes/news/news'
import NewsArticle from 'routes/news/news-article'
import Volumes from 'routes/volumes/volumes.js'

import Method from 'routes/method/method'
import About from 'routes/about/about'
import Maintenance from 'routes/maintenance/maintenance'
import Contact from 'routes/contact/contact'

import Forecast from 'routes/forecast/forecasts'
import Admin from 'routes/admin/admin'
import Overview from 'routes/forecast_overview/overview'
import Projects from 'routes/forecast_projects/projects'
import Weighting from 'routes/forecast_weighting/weighting'
import Settings from 'routes/forecast_settings/settings'
import Project from 'routes/forecast_projects_project/project'


import Callback from 'routes/login/callback'
import Login from 'routes/login/login'
import Logout from 'routes/logout/logout'

import MainPublicLayout from 'layouts/main.public.layout'
import MinimalPublicLayout from 'layouts/minimal.public.layout'
import MainLayout from 'layouts/main.layout'
import LoginLayout from "layouts/login.layout"

const GAHandler = () => {
  const { GOOGLE_ANALYTICS_ID } = env
  const history = useHistory()
  if (GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
    history.listen((location) => {
      const url = location.pathname + location.search;
      ReactGA.send({ hitType: "pageview", page: url });
    });
  }
  return null
}



export default () => {
  useEffect(() => {
    if (env.PROGNOS_DATASET !== 'prod') {
      document.body.classList.toggle('dataset-test', true);
    }
  }, [])

  return <BrowserRouter>
    <GAHandler />
    <ScrollToTop />
    <NavigatorOnline />

    <Switch>
      <Route path="/login">
        <LoginLayout>
          <Switch>
            <Route path="/login/callback" render={() => <Callback />} />
            <Route path="/login" render={() => <Login />} />
            <Route path="/logout" render={() => <Logout />} />
          </Switch>
        </LoginLayout>
      </Route>
      <Route path="/forecast">
        <MainLayout>
          <Switch>
            <Route path="/forecast/:forecastId/overview" render={({ match }) => <Overview forecastId={match.params.forecastId - 0} />} />
            <Route path="/forecast/:forecastId/projects/:projectId" render={({ match }) => <Project forecastId={match.params.forecastId - 0} projectId={match.params.projectId - 0} />} />
            <Route path="/forecast/:forecastId/projects" render={({ match }) => <Projects forecastId={match.params.forecastId - 0} />} />
            {/* <Route path="/forecast/:forecastId/ratings" render={({ match }) => <Ratings forecastId={match.params.forecastId - 0} />} />
            <Route path="/forecast/:forecastId/sizes" render={({ match }) => <Sizes forecastId={match.params.forecastId - 0} />} />
            <Route path="/forecast/:forecastId/districts" render={({ match }) => <Districts forecastId={match.params.forecastId - 0} />} />
            <Route path="/forecast/:forecastId/categories" render={({ match }) => <Categories forecastId={match.params.forecastId - 0} />} />
            <Route path="/forecast/:forecastId/regions" render={({ match }) => <Regions forecastId={match.params.forecastId - 0} />} /> */}
            <Route path="/forecast/:forecastId/weighting/:tab?" render={({ match }) => <Weighting tab={match.params.tab} forecastId={match.params.forecastId - 0} />} />
            <Route path="/forecast/:forecastId/settings/:tab?" render={({ match }) => <Settings tab={match.params.tab} forecastId={match.params.forecastId - 0} />} />
            <Route path="/forecast" render={() => <Forecast />} />
          </Switch>
        </MainLayout>
      </Route>
      <Route path="/admin">
        <MainLayout>
          <Switch>
            <Route render={() => <Admin />} />
          </Switch>
        </MainLayout>
      </Route>
      <Route path="/maintenance">
        <MinimalPublicLayout>
          <Switch>
            <Route path="/maintenance" render={() => <Maintenance />} />
          </Switch>
        </MinimalPublicLayout>
      </Route>
      <Route >
        <MainPublicLayout>
          <Switch>
            <Route path="/uppdateringar/:articleId" render={({ match }) => <NewsArticle articleId={match.params.articleId} />} />
            <Route path="/uppdateringar" render={() => <News />} />
            <Route path="/byggvolymer" render={() => <Volumes />} />
            <Route path="/om_prognoserna" render={() => <About />} />
            <Route path="/prognosmetod" render={() => <Method />} />
            <Route path="/kontakt" render={() => <Contact />} />
            <Route path="/prognoser/:locationId/:categoryId" render={({ match }) => <Report categoryId={match.params.categoryId} locationId={match.params.locationId} />} />
            <Route path="/prognoser" render={() => <Report locationId="hela-sverige" categoryId="alla" />} />
            <Route render={() => <Start />} />
          </Switch>
        </MainPublicLayout>
      </Route>
    </Switch>


    {/* <Route path="/" render={() => <Start />} />
          <Route path="/prognoser" render={() => <Report locationId="hela-sverige" categoryId="alla" />} />
          <Route path="/prognoser/:locationId/:categoryId" render={({match}) => <Report categoryId={match.params.categoryId} locationId={match.params.locationId}/>} /> */}

  </BrowserRouter>
}
