import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

// import logo from 'assets/icons/logo.svg';
import { ReactComponent as LogoSVG } from 'assets/icons/logo.svg';

import "components/logo/_logo.scss"

const CLASS_NS = "logo";

// TODO: edit svg to enable dynamic sizing.
// TODO: allow non-link element as wrapper

/**
 * A logo component which offers color and size variants by its css mixin.
 * @returns {*} - React.Component
 */
const Logo = ({
  classNs=CLASS_NS,
  url="/",
  logoMark=<LogoSVG className={`${classNs}__image`}/>,
  logoType="Byggföretagen"
}) => {
  return (
    <Link
      to={url}
      className={`${classNs}`}
    >
      {logoMark}
      {typeof logoType === "string" ? <h3 className={`${classNs}__name`}>{logoType}</h3> : logoType}
    </Link>
  )
}

Logo.propTypes = {
  classNs: PropTypes.string,
  url: PropTypes.string,
}
export default Logo
