
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from "react-redux";

import ModalContentContainer from "components/modal/modal-content-container"
import FormDatePicker from "components/date-picker/date-picker"
import SelectInput from "components/select-input/select-input"
import Page from "components/page/page";
import InputNumber from "components/input-number/input-number";
import InputText from "components/input-text/input-text";

import { useConstant } from 'store/constants'
import { useForecastList } from "store/forecast_list"
import { addForecast } from "store/forecast"

import formErrors from "utils/form-error-messages"
import { formatIsoDate } from 'utils/date-helpers'

// import "routes/create-forecast/_create-forecast.scss"
import { LOADING } from 'store/_types';

// const fieldNames = Object.keys(initialStates.forecast)

const CreateForecast = ({ onClose }) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [forecastList] = useForecastList()
  const [constant, constantNetworkState] = useConstant();

  const [formIsValid, setFormIsValid] = useState(false);
  const [errorMessages, setErrorMessages] = useState(new Map());

  const [name, setName] = useState('');
  const [method, setMethod] = useState('');
  const [forecastStartYear, setForecastStartYear] = useState((new Date()).getFullYear());
  const [forecastEndYear, setForecastEndYear] = useState(constant.maxYear);
  const [projectStartYear, setProjectStartYear] = useState(constant.minYear);
  const [deadlineDate, setDeadlineDate] = useState('');
  const [referenceForecastId, setReferenceForecastId] = useState('');
  /**
  * Itterates through all the fields and checks their native validity state
  */
  const checkFormValidity = useCallback(() => {
    if (!formRef || !formRef.current) {
      return;
    }
    // formRef.current.checkValidity();
    let isValid = Array.from(formRef.current.elements).every((element) => element.validity.valid);
    // TODO: Check if not
    setFormIsValid(isValid); // This state lags 1 render cycle behind
  });
  useEffect(() => {
    checkFormValidity();
  }, [checkFormValidity]);

  useEffect(() => {
  }, [errorMessages])

  const checkValidity = (e) => {
    const { name: _name, validity } = e.target;

    if (validity.valid && errorMessages.has(_name)) {
      // Ok
      // https://medium.com/swlh/using-es6-map-with-react-state-hooks-800b91eedd5f
      const newErrorState = errorMessages;
      newErrorState.delete(_name); // this returns true (if correctly deleted), not the new Map
      setErrorMessages(new Map(newErrorState));

    } else if (!validity.valid && !errorMessages.has(_name)) {
      // Error
      let errorKey = Object.keys(formErrors).filter(key => validity[key] === true)[0];
      const message = (errorKey && formErrors[errorKey]) ? formErrors[errorKey] : "Fel";

      setErrorMessages(new Map(errorMessages.set(_name, message)));
    }
  };

  const handleCreateForecast = () => {
    const forecast = {
      name,
      method,
      forecastStartYear,
      forecastEndYear,
      projectStartYear,
      deadlineDate
    }
    if (referenceForecastId) {
      forecast.referenceForecastId = referenceForecastId - 0
    }
    dispatch(addForecast(forecast))
  }

  const forecastMethods = Object.keys(constant.forecastMethods).map((key, index) => {
    return { id: index, value: key, label: constant.forecastMethods[key] }
  })

  const forecastTemplates = forecastList.map(forecast => {
    return { id: forecast.id, value: forecast.id, label: forecast.name }
  })

  if (constantNetworkState === LOADING || forecastList.length === 0) {
    return null
  }
  // Setting the Form method to dialog creates a special condition where
  // See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog
  return (
    <ModalContentContainer
      title="Skapa ny prognos"
    >
      <form
        method="dialog"
        ref={formRef}
      >
        <Page.Grid columns={4}>
          <Page.Block span={4}>
            <label htmlFor="name">Prognosnamn</label>
            <InputText 
            type="text" 
            name="name" 
            required 
            value={name} 
            onChange={e => setName(e.target.value)}
            />
            <p className="input-error">{errorMessages.has("name") ? errorMessages.get("name") : null}</p>
          </Page.Block>
          <Page.Block span={2}>
            <label htmlFor="method">Prognostyp</label>
            <SelectInput
              placeholder="Välj prognostyp"
              onSelect={setMethod}
              value={method}
              name="method"
              options={forecastMethods}
            />
            <p className="input-error" data-error={errorMessages.has("method")}>{errorMessages.has("method") ? errorMessages.get("method") : null}</p>
          </Page.Block>
          <Page.Block span={2}>
            <label htmlFor="referenceForecastId">Prognosmall</label>
            <SelectInput
              placeholder="Välj prognosmall"
              onSelect={setReferenceForecastId}
              value={referenceForecastId}
              name="referenceForecastId"
              options={forecastTemplates}
            />
            <p className="input-error" data-error={errorMessages.has("status")}>{errorMessages.has("status") ? errorMessages.get("status") : null}</p>
          </Page.Block>
          <Page.Block span={4}>
            <h5>Prognosintervall (årtal)</h5>
          </Page.Block>
          <Page.Block span={1}>
            {/* <label htmlFor="projectStartYear">Start projekthämtning</label>
              <SelectInput
                placeholder="Välj år"
                onSelect={setProjectStartYear}
                value={projectStartYear}

                name="projectStartYear"
                options={yearInterval()}
              /> */}
            <InputNumber
              label="Start projekthämtning"
              autoComplete="off"
              placeholder="Välj år"
              name="projectStartYear"
              value={projectStartYear}
              onValueChanged={setProjectStartYear}
              unit="år"
            />
            <p className="input-error" data-error={errorMessages.has("projectStartYear")}>{errorMessages.has("projectStartYear") ? errorMessages.get("projectStartYear") : null}</p>
          </Page.Block>
          <Page.Block span={1}>
            {/* <label htmlFor="forecastStartYear">Start bedömning</label>
              <SelectInput
                placeholder="Välj år"
                onSelect={setForecastStartYear}
                value={forecastStartYear}
                name="forecastStartYear"
                options={yearInterval()}
              /> */}
            <InputNumber
              label="Start bedömning"
              autoComplete="off"
              placeholder="Välj år"
              name="forecastStartYear"
              value={forecastStartYear}
              onValueChanged={setForecastStartYear}
              unit="år"
            />
            <p className="input-error" data-error={errorMessages.has("forecastStartYear")}>{errorMessages.has("forecastStartYear") ? errorMessages.get("forecastStartYear") : null}</p>
          </Page.Block>
          <Page.Block span={1}>
            {/* <label htmlFor="forecastEndYear">Prognosslut</label>
              <SelectInput
                placeholder="Välj år"
                onSelect={setForecastEndYear}
                value={forecastEndYear}
                name="forecastEndYear"
                options={yearInterval()}
                /> */}
            <InputNumber
              label="Prognosslut"
              autoComplete="off"
              placeholder="Välj år"
              name="forecastEndYear"
              value={forecastEndYear}
              onValueChanged={setForecastEndYear}
              unit="år"
            />
            <p className="input-error" data-error={errorMessages.has("forecastEndYear")}>{errorMessages.has("forecastEndYear") ? errorMessages.get("forecastEndYear") : null}</p>
          </Page.Block>
          <Page.Block span={4}>
            <h5>Prognosarbete</h5>
          </Page.Block>
          <Page.Block span={1}>
            <label htmlFor="publishedDate">Startdatum</label>
            <FormDatePicker
              autoComplete="off"
              name="publishedDate"
              onChange={() => { }}
            />
          </Page.Block>
          <Page.Block span={1}>
            <label htmlFor="deadlineDate">Slutdatum</label>
            <FormDatePicker
              autoComplete="off"
              name="deadlineDate"
              onChange={value => setDeadlineDate(formatIsoDate(value))}
            />
          </Page.Block>
          <Page.Block span={1} start={1}></Page.Block>
          {onClose &&
            <Page.Block span={1} start={3}>
              <button className="button-2" onClick={onClose}>Avbryt</button>
            </Page.Block>
          }
          <Page.Block span={1} start={4}>
            <button
              className="button"
              value="default"
              onClick={handleCreateForecast}
              disabled={formIsValid ? false : false}
            >Skapa prognos</button>
          </Page.Block>
        </Page.Grid>

      </form>
    </ModalContentContainer>
  )
}

CreateForecast.propTypes = {
}
export default CreateForecast
