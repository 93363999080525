import React, { useEffect, useState } from 'react'
import DataTable from "components/data-table/data-table";
import DataList from "components/data-list/data-list";
import InputInline from "components/input-inline/input-inline";

import { useForecastRatings } from "store/forecast_ratings"
import { useForecastWeightValueDistribution, updateForecastWeightValueDistribution } from "store/forecast_weight_valuedistribution"
import { useDispatch } from 'react-redux';
import InputArray from 'components/input-array/input-array';

const ValueDistributionTable = ({forecastId}) => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [forecastWeightValueDistribution] = useForecastWeightValueDistribution(forecastId);

    useEffect(() => {
        const d = forecastWeightValueDistribution.map( (d, index) => ({...d, index}))
        setData(d)
    }, [forecastWeightValueDistribution])

    const onValueChanged = (value) => {
        
        var _data = value.map( (v, i) => ({segment: i + 1, weight: v}))
        setData(_data)
    }
    const onSave = () => {
        dispatch(updateForecastWeightValueDistribution({forecastId, data}))
    }

    const bodyData = Object.values(data).map( d => d.weight)

    return (
        <>

            <DataList 
                header="Värdefördelning över byggperioddelar" 
                bodyData={[{values: bodyData}]} 
                footer={<button className="button-2" onClick={onSave}>Spara</button>}
            >
                <InputArray type="text" value={bodyData} label={(v, i) => ` Period ${i + 1} `} onChange={onValueChanged} />
            </DataList>
        </>

    )
}

export default ValueDistributionTable;