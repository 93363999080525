import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'

const GET_CONSTANT_LOADING = 'GET_CONSTANT_LOADING'
const GET_CONSTANT_SUCCESS = 'GET_CONSTANT_SUCCESS'
const GET_CONSTANT_ERROR = 'GET_CONSTANT_ERROR'

/* ACTIONS */
const getConstant = () => (dispatch) => {
  dispatch({
    type: GET_CONSTANT_LOADING
  });

  httpClient(`constants`)
    .then(result => {
      dispatch({
        type: GET_CONSTANT_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: GET_CONSTANT_ERROR,
        error,
        payload: error
      });
    })
}

/* HOOKS */

export const useConstant = () => {
  const dispatch = useDispatch();
  const { networkState, ...constant } = useSelector(state => state.constants);

  useEffect(() => {
    if (networkState === WAITING) {
      dispatch(getConstant())
    }
  }, [dispatch, networkState]);

  return [constant, networkState];
};


/* REDUCER */
const initialState = {
  forecastMethods: {},
  forecastStatuses: {},
  projectMethods: {},
  progresses:[],
  networkState: WAITING,
  error: null
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONSTANT_LOADING:
      return {
        ...state,
        networkState: LOADING
      }
    case GET_CONSTANT_ERROR:
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    case GET_CONSTANT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        networkState: SUCCESS,
      }

    default:
      return state
  }
}
