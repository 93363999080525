import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import AppContainer from "containers/app-container"
import { store } from "store"
import Routes from './router'
import './styles/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <AppContainer
      store={store}
    >
      <Routes />
    </AppContainer>
  </React.StrictMode>,
  document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
