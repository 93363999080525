import React from 'react'
import { formatKr } from 'utils/data-helpers';
import './_currency_value.scss'

const CurrencyValue = ({value}) => {
  const [amount,  unit] = formatKr(value)
  return <span className="current-value">{amount} <sub>{unit}</sub></span>
}

export default CurrencyValue
