import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { loadUser} from 'redux-oidc';
import userManager from 'utils/userManager';
import createRootReducer from "./_reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (preloadedState) => {
  const reduxStore = createStore(
    createRootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        thunk,
      )
    )
  );

  return reduxStore;
};

// This is an anti-pattern of requireing the store from here. Use react-redux 'connect' or the newer useDispatch 2nd argument to get the store state:
// (in an action creator:) export const getSomethingFromAPi = (dispatch, getState)=>{}
const store = configureStore(); // Todo remove this when all calls to store and refactored away.
loadUser(store, userManager);

export { store };
