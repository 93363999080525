import React, { useContext, useEffect, useState } from 'react'
import DataTable from "components/data-table/data-table";
import InputInline from "components/input-inline/input-inline";
import SelectInput from "components/select-input/select-input";
import Button from "components/button/button";

import { deleteForecastWeightRatingMap, postForecastWeightRatingMap, useForecastWeightRatingMap } from "store/forecast_weight_ratingmap"

import RatingmapModal from './ratingmap_modal'
import Modal from "components/modal/modal"
import { CrossIcon } from "components/icon"
import { DataContext } from "./weighting"
import { useDispatch } from 'react-redux';


const RatingmapFuture = ({forecastId, present = true}) => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [forecastWeightRatingMap] = useForecastWeightRatingMap(forecastId);    
    const [addModalOpen, setAddModalOpen] = useState(false)
    const {ratingsMap, sizesMap, progressesMap, progressesOptions, ratingsOptions, sizeOptions} = useContext(DataContext)
    useEffect(() => {
        const _data = forecastWeightRatingMap.filter(m => m.present === present)
        setData(_data)
    }, [present, forecastWeightRatingMap])

    const onSave = (data) => {
        dispatch(postForecastWeightRatingMap({forecastId, id: data.id, data}))
    }

    const onDeleteClick = (id) => {
        dispatch(deleteForecastWeightRatingMap({forecastId, id}))
    }

return <>
        <DataTable 
            bodyData={data} 
            footer={<>
                <Button onClick={() => setAddModalOpen(true)}>Lägg till</Button>
            </>}
        >
            <DataTable.Column hiderepeatedvalue name="fcSizeId" label="Företagsstorlek" formatter={ r => <>{sizesMap[r.fcSizeId]}</>} />
            <DataTable.Column hiderepeatedvalue name="progressId" label="Stadie" formatter={ r => <>{r.progressId} : {progressesMap[r.progressId]}</>} />
            { !present ? <DataTable.Column name="monthDiffFrom" label="Månadsintervall" formatter={(row) => <div style={{whiteSpace: 'nowrap'}}>
                    <InputInline  autoComplete="off"  value={row.monthDiffFrom}  onValueChanged={(value) => onSave({...row, monthDiffFrom: value})}  /> 
                    <span> ↦ </span>
                    <InputInline  placeholder={'∞'} autoComplete="off"  value={row.monthDiffTo}  onValueChanged={(value) => onSave({...row, monthDiffTo: value})}  /> 
                </div>
                }/> 
                : null
            }
            <DataTable.Column name="rating" label="Poäng" formatter={ row => <>
                <SelectInput name="rating" value={row.ratingId} options={ratingsOptions} onSelect={(value) => onSave({...row, ratingId: value})}  /> 
            </>} />
            <DataTable.Column name="delete" cellContent={<CrossIcon/>} cellFunc={onDeleteClick} />

        </DataTable>   
        {
        addModalOpen && 
          <Modal isOpen={!!addModalOpen} onClose={() => setAddModalOpen(false)} closeButton={true}>
            <RatingmapModal present={present} forecastId={forecastId}/>
          </Modal>
        }
    </>
}

export default RatingmapFuture;