export const tabChoices=[
  {
    value: "alla",
    label: "Alla"
  },
  {
    value: "bostader",
    label: "Bostäder"
  },
  {
    value: "lokaler",
    label: "Lokaler"
  },
  {
    value: "anlaggningar",
    label: "Anläggningar"
  }
]