import { createUserManager } from 'redux-oidc';
import env from 'environments'

// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// TODO this is NOT COOL but we couldn't find a proper way of getting the cross-communication
// with iframes in oidc-client working in safari
// const shouldMonitorSession = !isSafari;

// TODO Ivar: I disabled monitor until we solve same site iframe.
const shouldMonitorSession = false;

const userManagerConfig = {
  client_id: 'prognoswebben_code_flow',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login/callback`,
  response_type: 'code',
  scope: 'openid profile user_information prognoswebben_api azure_ad_groups',
  authority: env.SSO_AUTHORITY,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: shouldMonitorSession,
  monitorSession: shouldMonitorSession
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
