import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'

const GET_FORECAST_DISTRICTS_LOADING = 'GET_FORECAST_DISTRICTS_LOADING'
const GET_FORECAST_DISTRICTS_SUCCESS = 'GET_FORECAST_DISTRICTS_SUCCESS'
const GET_FORECAST_DISTRICTS_ERROR = 'GET_FORECAST_DISTRICTS_ERROR'

const ASSIGN_FORECAST_DISTRICT_LOADING = 'ASSIGN_FORECAST_DISTRICT_LOADING'
const ASSIGN_FORECAST_DISTRICT_SUCCESS = 'ASSIGN_FORECAST_DISTRICT_SUCCESS'
const ASSIGN_FORECAST_DISTRICT_ERROR = 'ASSIGN_FORECAST_DISTRICT_ERROR'


/* ACTIONS */
const getForecastDistricts = ({forecastId}) => (dispatch) => {
  dispatch({
    type: GET_FORECAST_DISTRICTS_LOADING
  });

  httpClient(`forecasts/${forecastId}/districts`)
    .then(result => {
      dispatch({
        type: GET_FORECAST_DISTRICTS_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: GET_FORECAST_DISTRICTS_ERROR,
        error,
        payload: error
      });
    })
}

export const assignForecastDistrict = ({forecastId, fnDistrictId, ...data}) => (dispatch) => {
  dispatch({
    type: ASSIGN_FORECAST_DISTRICT_LOADING
  });

  httpClient(`forecasts/${forecastId}/districts/${fnDistrictId}/assign`, { data , method: 'POST'})
    .then(result => {
      dispatch({
        type: ASSIGN_FORECAST_DISTRICT_SUCCESS,
        payload: result
      });
      dispatch(getForecastDistricts({forecastId}))
    })
    .catch(error => {
      dispatch({
        type: ASSIGN_FORECAST_DISTRICT_ERROR,
        error,
        payload: error
      });
    })
}
/* HOOKS */

export const useForecastDistricts = (forecastId) => {

  const dispatch = useDispatch();
  const { rows, networkState } = useSelector(state => state.forecast_districts);

  useEffect(() => {
    if ( forecastId ) {
      dispatch(getForecastDistricts({forecastId}));
    }
  }, [forecastId, dispatch]);
  return [rows, networkState];
};
/* REDUCER */
const initialState = {
  rows: [],
  networkState: WAITING,
  error: null,
}
export default (state = initialState, action) => {
  switch (action.type) {

    case GET_FORECAST_DISTRICTS_LOADING:
      return {
        ...state,
        networkState: LOADING
      }
    case GET_FORECAST_DISTRICTS_ERROR:
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    case GET_FORECAST_DISTRICTS_SUCCESS:
      return {
        ...state,
        rows: action.payload,
        networkState: SUCCESS,
      }

    default:
      return state
  }
}
