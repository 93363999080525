import React from 'react'
// import {ai} from 'store'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    // ai.trackException({ error: error })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="root">
          <header className="header">
            <div className="header_container">

            </div>
          </header>
          <main className="main-login">
            <article className="logout-page">
              <h4 className="logout-page_subheader">Prognoswebben</h4>
              <h2 className="logout-page_header">Bedömningsverktyget</h2>
              <h4 className="logout-page_subheader">Aj! Något gick fel.</h4>
              <p className="logout-page_intro">Vi har loggat felet och försöker lösa det inom kort. Tillsvidare, prova att logga in ingen.</p>
              <a className="button" href="/login">Logga in</a>
            </article>
          </main>
        </div>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary