import React from 'react';
import PropTypes from 'prop-types'


import './_header.scss'

const CLASS_NS = "header"

const Header = ({
  classNs=CLASS_NS,
  children
}) => {

  return (
    <header className={`${classNs}`}>
      <div className={`${classNs}__container`}>
        {children}
      </div>
    </header>
  )
}

Header.propTypes = {
  classNs: PropTypes.string,
  isActive: PropTypes.bool,
}

export default Header
