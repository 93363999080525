import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { httpClient, WAITING, LOADING, ERROR, SUCCESS } from 'utils/httpClient'

const LIST_PROJECT_NOTE_LOADING = 'LIST_PROJECT_NOTE_LOADING'
const LIST_PROJECT_NOTE_SUCCESS = 'LIST_PROJECT_NOTE_SUCCESS'
const LIST_PROJECT_NOTE_ERROR = 'LIST_PROJECT_NOTE_ERROR'

const ADD_PROJECT_NOTE_LOADING = 'ADD_PROJECT_NOTE_LOADING'
const ADD_PROJECT_NOTE_SUCCESS = 'ADD_PROJECT_NOTE_SUCCESS'
const ADD_PROJECT_NOTE_ERROR = 'ADD_PROJECT_NOTE_ERROR'


/* ACTIONS */
const getProjectNotes = ({projectId}) => (dispatch) => {
  dispatch({
    type: LIST_PROJECT_NOTE_LOADING
  });

  httpClient(`projects/${projectId}/notes`)
    .then(result => {
      dispatch({
        type: LIST_PROJECT_NOTE_SUCCESS,
        payload: result
      });
    })
    .catch(error => {
      dispatch({
        type: LIST_PROJECT_NOTE_ERROR,
        error,
        payload: error
      });
    })
}

export const addProjectNote = ({text, projectId}) => (dispatch) => {
  dispatch({
    type: ADD_PROJECT_NOTE_LOADING
  });

  httpClient(`projects/${projectId}/notes`, { data: {text} , method: 'POST'})
    .then(result => {
      dispatch({
        type: ADD_PROJECT_NOTE_SUCCESS,
        payload: result
      });
      dispatch(getProjectNotes({projectId}))
    })
    .catch(error => {
      dispatch({
        type: ADD_PROJECT_NOTE_ERROR,
        error,
        payload: error
      });
    })
}

/* HOOKS */

export const useProjectNotes = (projectId) => {
  const dispatch = useDispatch();
  const { networkState, rows } = useSelector(state => state.project_notes);

  useEffect(() => {
    dispatch(getProjectNotes({projectId}))
  }, [projectId, dispatch]);

  return [rows, networkState];
};

/* REDUCER */
const initialState = {
  error: null,
  networkState: WAITING,
  rows: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_PROJECT_NOTE_LOADING:{
      return {
        ...state,
        networkState: LOADING
      }
    }
    case LIST_PROJECT_NOTE_ERROR:{
      return {
        ...state,
        error: action.payload,
        networkState: ERROR,
      }
    }
    case LIST_PROJECT_NOTE_SUCCESS:{
      return {
        ...state,
        rows: action.payload,
        networkState: SUCCESS,
      }
    }

    default:
      return state
  }
}
