import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import Modal from "components/modal/modal"

import { useForecastRatings, updateForecastRatings } from "store/forecast_ratings"

import DataTable from "components/data-table/data-table";
import Page from "components/page/page";
import { EditPenIcon } from "components/icon"
import ModalContentContainer from "components/modal/modal-content-container"
import InputNumber from "components/input-number/input-number";

const ForecastRatings = ({ forecastId }) => {

  const [forecastRatings] = useForecastRatings(forecastId);
  const [selected, setSelected] = useState(null)
  const forecastRatingsList = forecastRatings.filter(r => r.active)

  const handleEditClick = (_id, _selected) => {
    if (_selected) {
      setSelected(_selected)
    }
  }

  const onCloseEdit = () => {
    setSelected(null)
  }

  return (
    <>
      <DataTable bodyData={forecastRatingsList} >
        <DataTable.Column name="rank" label="Värde" formatter={(row) => <span>{row.rank}</span>} />
        <DataTable.Column name="name" label="Alternativbeskrivning" />
        <DataTable.Column name="shortName" label="Internt namn" />
        <DataTable.Column name="edit" cellContent={<EditPenIcon/>} cellFunc={handleEditClick} />
      </DataTable>
      {
        selected && 
        <Modal isOpen={!!selected} onClose={onCloseEdit} closeButton={true}>
          <EditModal forecastId={forecastId} selected={selected}/>
        </Modal>
      }
    </>
  )
}

const EditModal = ({ forecastId, selected }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(selected.name)
  const [shortName, setShortName] = useState(selected.shortName || '')
  const [rank, setRank] = useState(selected.rank)
  const onSaveRating = () => {
    dispatch(updateForecastRatings({ forecastId, name, rank, shortName, ratingId: selected.ratingId }))
  }

  return (
    <ModalContentContainer title="Redigera">
      <form
        method="dialog"
      >
        <Page.Grid columns={4}>
          <Page.Block span={4}>
            <label htmlFor="name">Namn</label>
            <input
              type="text"
              placeholder="Namn på bedömningsalternativet"
              name="name"
              required
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Page.Block>
          <Page.Block span={4}>
            <label htmlFor="shortName">Kort namn</label>
            <input
              type="text"
              placeholder="Ett kort internt namn på bedömningsalternativet"
              name="shortName"
              value={shortName}
              onChange={e => setShortName(e.target.value)}
            />
          </Page.Block>
          <Page.Block span={2}>
            <label htmlFor="rank">Rank</label>
            <InputNumber
              placeholder="Ange rank"
              name="rank"
              value={rank}
              onValueChanged={setRank}
            />
          </Page.Block>
          <Page.Block span={4}>
          </Page.Block>
          <Page.Block span={1}>
            <button
              onClick={onSaveRating}
              className="button"
            >Spara</button>
          </Page.Block>
          {/* <Page.Block span={1}>
            <button
                onClick={onDeleteRating}
                className="button-2"
            >Radera</button>
          </Page.Block> */}

        </Page.Grid>
      </form>
    </ModalContentContainer>
  )
}

export default ForecastRatings
