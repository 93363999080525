import React from 'react';
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

const NavSection = ({
  navItems = [],
  location, onClick = () => { },
  classNs = "header__nav" }) => {

  return (
    <ul className={`${classNs}-items`}>
      {
        navItems ? navItems.filter(item => !item.disabled).map((item, index) => (
          <li key={index} className={`${classNs}-item`} data-active={location.pathname.includes(`${item.link}`) ? true : false}>
            <Link to={item.link} className={`${classNs}-link`} onClick={onClick}>{item.label}</Link>
          </li>
        )) : null
      }
    </ul>
  )
}

NavSection.propTypes = {
  classNs: PropTypes.string,
  navItems: PropTypes.array,
  location: PropTypes.object,
  onClick: PropTypes.func
}
export default NavSection