import React, { useEffect } from 'react'
import { Provider } from 'react-redux'

import userManager from 'utils/userManager';
import { OidcProvider, /*loadUser*/ } from "redux-oidc";

import ErrorBoundry from 'containers/error-boundry'


const AppContainer = ({ store, children }) => {


  useEffect(() => {
    const _isLoggedIn = async () => {
      const user = await userManager.getUser();
      if (user) {
        if (user.expired) {
          userManager.removeUser().then(() => {
            console.info("Expired");
          });
        }
        let getRes = null;
        try {
          getRes = user //await isLoggedIn(user);
        } catch (e) { }
        if (!getRes) {
          userManager.removeUser().then(() => {
            console.info("Api says user is not logged in, clearing local user");
          });
        }
      }
    };
    if (true || !userManager.settings.monitorSession) {
      _isLoggedIn();
    }

  }, []);


  return (
    <ErrorBoundry>
      <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
          {children}
        </OidcProvider>
      </Provider>
    </ErrorBoundry>
  )
}

export default AppContainer;
