import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { uiStopChecked } from 'store/ui_checked'
import './_checked.scss'

const Checked = () => {
  const dispatch = useDispatch()
  const {show} = useSelector(state => state.ui_checked)
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        dispatch(uiStopChecked())
      }, 3000)
    }
  }, [show, dispatch])
  if (!show) {
    return null;
  }

  return (
    <div className="checked">
      <svg className="checked__checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="checked__checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
        <path className="checked__checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
    </div>
  )
}

export default Checked
