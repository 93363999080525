import React, { useEffect } from 'react'
import userManager from "utils/userManager";
import {Link} from "react-router-dom";
import "routes/logout/_logout.scss"

const Logout = () => {

  useEffect(() => {
    userManager.removeUser();
  }, [])

  return (
    <article className="logout-page">
      <h4 className="logout-page__subheader">Prognoswebben</h4>
      <h2 className="logout-page__header">Bedömningsverktyget</h2>
      <p className="logout-page__intro">Du har nu loggats ut från Bedömningsverktyget.</p>
      <Link
        to={'/login'}
        className="button"
      >
        Logga in
      </Link>
    </article>
  )
}

export default Logout
